import React from 'react';
import PropTypes from 'prop-types';
import Link from '../Link';


const TextLayout = ({ header, text, buttonText, buttonLink, isWhite, isRow }) => (
	<div className={isWhite ? 'textLayoutWrapper isWhiteText' : 'textLayoutWrapper'}>
		<div className={isRow ? 'isRowDisplay textLayoutContainer' : 'textLayoutContainer'}>
			<div className='textLayoutHeader'>
				<h2>{header}</h2>
			</div>
			<div className='textLayoutTextButton'>
				<p>{text}</p>
				<Link to={buttonLink}>{buttonText}</Link>
			</div>
		</div>
	</div>
);


TextLayout.contextTypes = {
	header: PropTypes.any,
	text: PropTypes.string,
	buttonText: PropTypes.string,
	buttonLink: PropTypes.string,
	isWhite: PropTypes.bool,
	isColumn: PropTypes.bool
};

export default TextLayout;
