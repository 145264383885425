export const blueDetailsFr = [

    {
        icon: '/img/infoBoxIcons/blueIcons/receipt-edit.svg',
        header: 'Transparence des prix',
        text: `Worddy est le seul site a proposé grâce à son algorithme les prix des séjours linguistiques en temps réel en fonction de dizaine de paramètres. Vous pouvez alors façonner votre package en fonction de la durée, de la date de départ, du type de cours et de logement. Vous pouvez aussi inclure des options comme le transfert aéroport et une assurance santé.
        Il existe d'autres organismes de séjour linguistique mais nous sommes les seuls à afficher les prix sans devoir demander de devis. Contrairement aux agences nous ne facturons pas de frais en plus puisque la relation et le paiement se fait directement avec l'école de langue.`
    },

    {
        icon: '/img/infoBoxIcons/blueIcons/book.svg',
        header: 'Préparation au TOEFL OU IELTS',
        text: `Si vous avez besoin de passer ces examens, un séjour linguistique est très bon moyen le préparer avec 20 ou 30 leçons par semaine.`
    },

    {
        icon: '/img/infoBoxIcons/blueIcons/buildings-2.svg',
        header: 'Large choix de cours de langue',
        text: `Worddy propose un large choix de allant de 10 cours par semaine à 40 cours par semaine. Vous pouvez choisir entre cours en groupe et cours particulier ou bien mixer les deux. Les cours en groupe permettent de pratiquer la langue étrangère avec des non-francophones. Ils sont donc indispensables pour revoir les bases de la langue étrangère.`
    },

    {
        icon: '/img/infoBoxIcons/blueIcons/slider.svg',
        header: 'Large choix de logement',
        text: `Worddy propose sur son site environ 60 types de logements différents pour tous les budgets. En fonction de vos envies et de vos besoins vous pouvez choisir un logement allant de l'appartement partagé, la résidence étudiante ou l'hébergement en familles d'accueil.`
    },

    {
        icon: '/img/infoBoxIcons/blueIcons/verify.svg',
        header: 'Des partenaires certifiés',
        text: `Worddy sélectionne en fonction des certifications et des avis des anciens étudiants les meilleures écoles de langue. À chaque fin de séjour, l'étudiant reçoit un questionnaire qui permet de vérifier la qualité des cours et des logements.`
    },

    {
        icon: '/img/infoBoxIcons/blueIcons/tripIcon.svg',
        header: `Prix d'un séjour linguistique`,
        text: `Le coût d'un séjour n'est pas négligeable, les prix varient de 300 euros à plus de 1000 euros la semaine. Il faut le voir comme un investissement sur votre avenir. Il faut compter le coût du voyage, de l'hébergement, des cours de langues, mais aussi des fraix annexes comme les transports sur place, les repas, les sorties... Le but est de passer un bon moment, il est donc important de se renseigner sur le coût de la vie dans la ville ou vous vous rendez afin d'être à l'aise par rapport à votre budget.`
    }

];

export default blueDetailsFr;
