import { defineMessages } from 'react-intl';

export const accountMenu = defineMessages({
  myAccount: {
    id: 'navigation.myAccount',
    defaultMessage: 'My account',
    description: 'My Account text if no user name',
  },
  welcomeBack: {
    id: 'navigation.welcomeBack',
    defaultMessage: 'Welcome back'
  },
  search: {
    id: 'navigation.search',
    defaultMessage: 'Destinations',
    description: 'Search link in header',
  },
  categories: {
    id: 'navigation.categories',
    defaultMessage: 'Language courses',
  },
  categoriesType: {
    id: 'navigation.categoriesType',
    defaultMessage: 'English courses',
  },
  countries: {
    id: 'navigation.country',
    defaultMessage: 'Countries for a language stay',
    description: 'Search link in sub navigation header',
  },
  magazine: {
    id: 'navigation.magazine',
    defaultMessage: 'Magazine',
    description: 'Magazine link in header',
  },
  magazineCategories: {
    id: 'navigation.magazine.categories',
    defaultMessage: 'Magazine Categories',
  },
  login: {
    id: 'navigation.login',
    defaultMessage: 'Sign in',
    description: 'login link in header',
  },
  register: {
    id: 'navigation.register',
    defaultMessage: 'Sign Up',
    description: 'register link in header',
  },
  dashboard: {
    id: 'navigation.dashboard',
    defaultMessage: 'Dashboard',
    description: 'dashboard link in header',
  },
  profile: {
    id: 'navigation.profile',
    defaultMessage: 'Edit Profile',
    description: 'profile link in header',
  },
  booking: {
    id: 'navigation.booking',
    defaultMessage: 'Bookings',
    description: 'booking link in header',
  },
  bookingDetails: {
    id: 'navigation.bookingDetails',
    defaultMessage: 'Bookings Details',
    description: 'booking details link',
  },
  wishlist: {
    id: 'navigation.wishlist',
    defaultMessage: 'Wishlist',
    description: 'wishlist link in header',
  },
  review: {
    id: 'navigation.review',
    defaultMessage: 'Review',
    description: 'review link in header',
  },
  settings: {
    id: 'navigation.settings',
    defaultMessage: 'Settings',
    description: 'settings link in header',
  },
  logout: {
    id: 'navigation.logout',
    defaultMessage: 'Logout',
    description: 'logout link in header',
  },
  whyBookWithUs: {
    id: 'navigation.whyBookingWithUs',
    defaultMessage: 'Why book with us ?',
    description: 'w link in footer',
  },
  privacyPolicy: {
    id: 'navigation.privacyPolicy',
    defaultMessage: 'Privacy Policy',
    description: 'link in footer',
  },
  termsOfUse: {
    id: 'navigation.termsOfUse',
    defaultMessage: 'Terms of Use',
    description: 'link in footer',
  },
  affiliates: {
    id: 'navigation.affiliation',
    defaultMessage: 'Affiliates',
    description: 'link in footer',
  },
  cookiePolicy: {
    id: 'navigation.cookiePolicy',
    defaultMessage: 'Cookie Policy',
    description: 'link in footer',
  },
  partner: {
    id: 'navigation.partner',
    defaultMessage: 'List your school',
    description: 'link school in footer',
  },
  contact: {
    id: 'navigation.contact',
    defaultMessage: 'Contact us',
    description: 'link school in footer',
  },
  letUsBook: {
    id: 'navigation.letUsBook',
    defaultMessage: 'Let us book your language trip',
    description: 'link letUsBook in footer',
  },
  callback: {
    id: 'navigation.callback',
    defaultMessage: 'Callback',
    description: 'link callback in footer',
  },
  faq: {
    id: 'navigation.faq',
    defaultMessage: 'Help / FAQ',
    description: 'link header in footer',
  },
  irregularVerbsEnglish: {
    id: 'navigation.irregularVerbsEnglish',
    defaultMessage: 'Irregular verbs in English',
    description: 'Irregular verbs in English',
  },
  deals: {
    id: 'navigation.deals',
    defaultMessage: 'Deals %',
  },
  covid: {
    id: 'navigation.covid',
    defaultMessage: 'Covid 19 - situation by destination',
  },
  home: {
    id: 'navigation.home',
    defaultMessage: 'Home',
  }
});

export const toasterMessages = defineMessages({
  wishlistItemAdded: {
    id: 'wishlist.item.added',
    defaultMessage: 'This offer of {schoolName} is added into your wishlist',
    description: '',
  },
});

export const bookingForm = defineMessages({
  course: {
    id: 'school.form.course',
    defaultMessage: 'Language course',
    description: '',
  },
  lessonType: {
    id: 'school.form.lessonType',
    defaultMessage: 'Lesson',
    description: '',
  },
  age: {
    id: 'school.form.age',
    defaultMessage: 'Your age',
    description: '',
  },
  startDate: {
    id: 'school.form.startDate',
    defaultMessage: 'Start date (course)',
    description: '',
  },
  courseStartDate: {
    id: 'school.form.courseStartDate',
    defaultMessage: 'Course start date',
    description: '',
  },
  duration: {
    id: 'school.form.duration',
    defaultMessage: 'Duration',
    description: '',
  },
  courseType: {
    id: 'school.form.course.type',
    defaultMessage: 'Course Type',
    description: '',
  },
  accommodation: {
    id: 'school.form.accommodation',
    defaultMessage: 'Accommodations',
    description: '',
  },
  accommodationOffer: {
    id: 'school.form.accommodationOffer',
    defaultMessage: 'Room and meal type',
    description: '',
  },
  checkin: {
    id: 'school.form.checkin',
    defaultMessage: 'Check-in',
    description: '',
  },
  checkout: {
    id: 'school.form.checkout',
    defaultMessage: 'Check-out',
    description: '',
  },
  total: {
    id: 'school.form.total',
    defaultMessage: 'Total',
    description: '',
  },
  withFees: {
    id: 'school.form.withFees',
    defaultMessage: '(With fees)',
    description: '',
  },
  buttonBook: {
    id: 'school.form.buttonBook',
    defaultMessage: 'Book now',
    description: '',
  },
  buttonWishlist: {
    id: 'school.form.buttonWishlist',
    defaultMessage: 'Save Trip in my wishlist',
    description: '',
  },
  perWeek: {
    id: 'school.form.perWeek',
    defaultMessage: 'per week',
    description: '',
  },
  select: {
    id: 'school.form.select.button',
    defaultMessage: 'Select',
    description: 'btn select in form',
  },
  selected: {
    id: 'school.form.selected.button',
    defaultMessage: 'Selected',
    description: 'btn selected in form',
  },
  viewDetails: {
    id: 'school.form.view.details',
    defaultMessage: 'View Details',
    description: '',
  },
  noAccommodation: {
    id: 'school.form.noAccommodation.label',
    defaultMessage: 'I do not need accommodation',
    description: 'label no accommodation in form',
  },
  noPackage: {
    id: 'school.form.noPackage.label',
    defaultMessage: 'Custom package',
  },
  or: {
    id: 'school.form.or.label',
    defaultMessage: 'or',
    description: 'label or',
  },
  noAvailableDate: {
    id: 'school.form.noAvailableDate',
    defaultMessage: 'This offer is not available for these dates.',
    description: 'no available',
  },
  noMoreAvailable: {
    id: 'school.form.noMoreAvailable',
    defaultMessage: 'Book (No more available)',
    description: 'no available',
  },
  errorChangeDate: {
    id: 'school.form.ErrorChangeDate',
    defaultMessage: 'Book (Change Start Date please)',
  },
  yearsOld: {
    id: 'formCourse.yearsOld',
    defaultMessage: 'years old',
    description: '',
  },
  calendarCourseInfo: {
    id: 'calendar.courseInfo',
    defaultMessage: 'Language courses start on monday. Price vary according to the season and the duration of the trip.',
  },
  calendarCourseOnlineInfo: {
    id: 'calendar.courseOnlineInfo',
    defaultMessage: 'Course online Start Every Monday',
  },
  calendarCheckInInfo: {
    id: 'calendar.checkinInfo',
    defaultMessage: 'Please select the start date of the course first. Check-in for accommodation is on Saturday or Sunday.',
  },
  calendarCheckOutInfo: {
    id: 'calendar.checkoutInfo',
    defaultMessage: 'Check-out is Friday or Saturday',
  },
  optionsAvailable: {
    id: 'school.OptionsAvailable',
    defaultMessage: 'The price does not include additional options. You can add options in the checkout',
    description: '',
  },
  priceRange: {
    id: 'filter.price.range',
    defaultMessage: 'Price Range',
  },
  allStudents: {
    id: 'booking.allStudents',
    defaultMessage: 'All students',
  }
});

export const modalAuth = defineMessages({
  authBookMessage: {
    id: 'modal.auth.bookMessage',
    defaultMessage: 'Sign up to book',
    description: '',
  },
  authWishlistMessage: {
    id: 'modal.auth.wishlistMessage',
    defaultMessage: 'Sign up to add this offer to your wishlist',
    description: '',
  },
  authBrochureMessage: {
    id: 'modal.auth.brochure',
    defaultMessage: 'Sign up to download the brochure',
    description: '',
  },
});

export const searchPage = defineMessages({
  viewMore: {
    id: 'search.item.buttonView',
    defaultMessage: 'View more',
    description: '',
  },
  fullDetails: {
    id: 'search.item.fullDetails',
    defaultMessage: 'Full Details',
    description: '',
  },
  save: {
    id: 'search.item.save',
    defaultMessage: 'Save to my wishlist',
    description: '',
  },
  week: {
    id: 'search.item.week',
    defaultMessage: 'week',
    description: '',
  },
  weeks: {
    id: 'search.item.weeks',
    defaultMessage: 'weeks',
    description: '',
  },
  from: {
    id: 'search.item.from',
    defaultMessage: 'from',
    description: '',
  },
  coursesPerWeek: {
    id: 'search.item.coursesPerWeek',
    defaultMessage: 'lessons per week',
    description: '',
  },
  noTransport: {
    id: 'search.item.noTransport',
    defaultMessage: '*transport is not included',
    description: '',
  },
  addWishlist: {
    id: 'search.item.add.wishlist',
    defaultMessage: 'Add to wishlist',
    description: '',
  },
});

export const formProfile = defineMessages({
  information: {
    id: 'profile.information',
    defaultMessage: 'Personal Information',
    description: '',
  },
  firstName: {
    id: 'profile.firstName',
    defaultMessage: 'First Name',
    description: '',
  },
  lastName: {
    id: 'profile.lastName',
    defaultMessage: 'Last Name',
    description: '',
  },
  password: {
    id: 'profile.password',
    defaultMessage: 'Password',
    description: '',
  },
  newPassword: {
    id: 'profile.newPassword',
    defaultMessage: 'New Password',
    description: '',
  },
  confirmNewPassword: {
    id: 'profile.confirmNewPassword',
    defaultMessage: 'Confirm new password',
    description: '',
  },
  gender: {
    id: 'profile.gender',
    defaultMessage: 'Gender',
    description: '',
  },
  email: {
    id: 'profile.email',
    defaultMessage: 'Email',
    description: '',
  },
  phone: {
    id: 'profile.phone',
    defaultMessage: 'Phone Number',
    description: '',
  },
  birthDate: {
    id: 'profile.birthDate',
    defaultMessage: 'Birth Date',
    description: '',
  },
  motherTongue: {
    id: 'profile.motherTongue',
    defaultMessage: 'Mother Tongue',
    description: '',
  },
  citizenship: {
    id: 'profile.citizenship',
    defaultMessage: 'Citizenship',
    description: '',
  },
  nationalId: {
    id: 'profile.nationalId',
    defaultMessage: 'National Id',
    description: '',
  },
  websiteLanguage: {
    id: 'profile.websiteLanguage',
    defaultMessage: 'Website language',
    description: '',
  },
  specialRequest: {
    id: 'profile.specialRequest',
    defaultMessage: 'Special request (particular allergies and/or medical requirements)',
    description: '',
  },
  specialRequestOnline: {
    id: 'profile.specialRequestOnline',
    defaultMessage: 'Hours desired for the online courses / Special request',
    description: '',
  },
  save: {
    id: 'profile.save',
    defaultMessage: 'Save changes',
    description: '',
  },
  female: {
    id: 'profile.female',
    defaultMessage: 'Female',
    description: '',
  },
  male: {
    id: 'profile.male',
    defaultMessage: 'Male',
    description: '',
  },
  other: {
    id: 'profile.other',
    defaultMessage: 'other',
    description: '',
  },
  newStudent: {
    id: 'student.new',
    defaultMessage: 'Create a new student',
    description: '',
  },
  optinNewsletter: {
    id: 'profile.optinNewsletter',
    defaultMessage: 'I want to receive Worddy\'s newsletter and special offers',
    description: '',
  },
  passwordHint: {
    id: 'password.hint',
    defaultMessage: 'Min 8 characters, including letters, numbers and special characters',
  },
  rememberMe: {
    id: 'remember.me.checkBox',
    defaultMessage: 'Remember Me'
  },
});

export const optionsForm = defineMessages({
  transferFree: {
    id: 'options.transferFree',
    defaultMessage: 'Arrival and departure transfers are free you book this accommodation',
    description: '',
  },
  optionsSubTitle: {
    id: 'options.subTitle',
    defaultMessage: 'You can add these options during the booking process.',
    description: '',
  },
  or: {
    id: 'options.or',
    defaultMessage: 'or',
    description: '',
  },
  insuranceTitle: {
    id: 'options.insurance.title',
    defaultMessage: 'Insurance',
    description: '',
  },
  socialSecurityNumber: {
    id: 'options.securityNumber',
    defaultMessage: 'Social Security No.',
    description: '',
  },
});

export const checkoutForm = defineMessages({
  title: {
    id: 'checkout.title',
    defaultMessage: 'My Booking Summary',
    description: '',
  },
  step0Title: {
    id: 'checkout.step0.title',
    defaultMessage: 'Choose your package',
    description: '',
  },
  step1Title: {
    id: 'checkout.step1.title',
    defaultMessage: 'Student information',
    description: '',
  },
  step1SubTitle: {
    id: 'checkout.step1.subtitle',
    defaultMessage: 'Select a student or create a new one',
    description: '',
  },
  step1ButtonCreate: {
    id: 'checkout.step1.step1ButtonCreate',
    defaultMessage: 'Create student and go to the next step',
    description: '',
  },
  step1ButtonUpdate: {
    id: 'checkout.step1.step1ButtonUpdate',
    defaultMessage: 'Choose student and go to the next step',
    description: '',
  },
  step2Title: {
    id: 'checkout.step2.title',
    defaultMessage: 'Additional Options',
    description: '',
  },
  step2Button: {
    id: 'checkout.step2.button',
    defaultMessage: 'Go to the next step',
    description: '',
  },
  step2Date: {
    id: 'checkout.step2.date',
    defaultMessage: 'On the {date}',
    description: '',
  },
  noOptions: {
    id: 'checkout.step2.noOptions',
    defaultMessage: 'No options available.',
    description: '',
  },
  optionAirportTransfer: {
    id: 'checkout.step2.optionAirportTransfer',
    defaultMessage: 'Airport Transfert',
    description: '',
  },
  optionExperience: {
    id: 'checkout.step2.optionExperience',
    defaultMessage: 'Experiences - Activities',
    description: '',
  },
  optionMaterials: {
    id: 'checkout.step2.optionMaterials',
    defaultMessage: 'Materials',
    description: '',
  },
  step3Title: {
    id: 'checkout.step3.title',
    defaultMessage: 'Billing address',
    description: '',
  },
  step3SubTitle: {
    id: 'checkout.step3.subtitle',
    defaultMessage: 'Enter your billing address',
    description: '',
  },
  step3Button: {
    id: 'checkout.step3.button',
    defaultMessage: 'Save address and go to the next step',
    description: '',
  },
  step4Title: {
    id: 'checkout.step4.title',
    defaultMessage: 'Payment',
    description: '',
  },
  step4Button: {
    id: 'checkout.step4.button',
    defaultMessage: 'Pay and book now',
    description: '',
  },
  cardNumber: {
    id: 'checkout.cardNumber',
    defaultMessage: 'Card number',
    description: '',
  },
  cardHolderName: {
    id: 'checkout.cardHolderName',
    defaultMessage: 'Cardholder Name',
    description: '',
  },
  expireDate: {
    id: 'checkout.expireDate',
    defaultMessage: 'Expiration Date',
    description: '',
  },
  cvv: {
    id: 'checkout.cvv',
    defaultMessage: 'CVV (3 digits)',
    description: '',
  },
  visa: {
    id: 'checkout.visa',
    defaultMessage: 'Visa',
    description: '',
  },
  schoolInsurance: {
    id: 'checkout.schoolInsurance',
    defaultMessage: 'School Insurance',
    description: '',
  },
  stepDescritionMobil: {
    id: 'checkout.stepDescritionMobil',
    defaultMessage: "Step {activeStep} of {stepCount}",
    description: '',
  },
});

export const checkoutSummary = defineMessages({
  dateTitle: {
    id: 'checkout.date.title',
    defaultMessage: 'Date summary',
    description: '',
  },
  courseTitle: {
    id: 'checkout.course.title',
    defaultMessage: 'Course',
    description: '',
  },
  courseStartDate: {
    id: 'checkout.course.start',
    defaultMessage: 'Start Date',
    description: '',
  },
  courseEndDate: {
    id: 'checkout.course.end',
    defaultMessage: 'End Date',
    description: '',
  },
  accommodationTitle: {
    id: 'checkout.accommodation.title',
    defaultMessage: 'Accommodation',
    description: '',
  },
  accommodationCheckIn: {
    id: 'checkout.accommodation.start',
    defaultMessage: 'Check-in',
    description: '',
  },
  accommodationCheckOut: {
    id: 'checkout.accommodation.end',
    defaultMessage: 'Check-out',
    description: '',
  },
  durationWeek: {
    id: 'checkout.durationWeek',
    defaultMessage: 'Duration: {duration} week',
    description: '',
  },
  durationWeeks: {
    id: 'checkout.duration',
    defaultMessage: 'Duration: {duration} weeks',
    description: '',
  },
  charges: {
    id: 'checkout.charges',
    defaultMessage: 'Charges',
    description: '',
  },
  nights: {
    id: 'checkout.nights',
    defaultMessage: 'x{numberOfNights} nights',
    description: '',
  },
  durationInWeek: {
    id: 'checkout.durationInWeek',
    defaultMessage: 'x{duration} week',
    description: '',
  },
  durationInWeeks: {
    id: 'checkout.durationInWeeks',
    defaultMessage: 'x{duration} weeks',
    description: '',
  },
  featureTitle1: {
    id: 'checkout.featureTitle1',
    defaultMessage: 'Customer support available 7/7 worldwide!',
    description: '',
  },
  featureText1: {
    id: 'checkout.featureText1',
    defaultMessage: 'Website and customer support in English, French, Spanish.',
    description: '',
  },
  featureTitle2: {
    id: 'checkout.featureTitle2',
    defaultMessage: 'We are transparent!',
    description: '',
  },
  featureText2: {
    id: 'checkout.featureText2',
    defaultMessage: 'Worddy is transparent and do not over charges you BUT gives you the best possible price.',
    description: '',
  },
  checkoutTerms1: {
    id: 'checkout.terms1',
    defaultMessage: 'By clicking book now button you agree with our terms and conditionals and money back gurantee. Thank you for trusting our service.',
    description: '',
  },
  checkoutTermsSchool1: {
    id: 'checkout.TermsSchool1',
    defaultMessage: "By clicking Pay and Book now button you agree with {name}'s <a href='{link}' target='_blank'> terms and condition <i class='fa fa-external-link' aria-hidden='true'></i></a>, and money back gurantee. Thank you for trusting our service.",
    description: '',
  },
  checkoutTermsTotalSummaryWeeks: {
    id: 'checkout.checkoutTermsTotalSummaryWeeks',
    defaultMessage: 'Total for {duration} weeks of courses ',
    description: '',
  },
  checkoutTermsTotalSummaryWeek: {
    id: 'checkout.checkoutTermsTotalSummaryWeek',
    defaultMessage: 'Total for {duration} week of courses ',
    description: '',
  },
  checkoutTermsTotalSummartNight: {
    id: 'checkout.checkoutTermsTotalSummartNight',
    defaultMessage: 'with {numberOfNights} nights',
    description: '',
  },
  totalReduction: {
    id: 'checkout.totalReduction',
    defaultMessage: 'Total reduction',
    description: '',
  },
  total: {
    id: 'checkout.total',
    defaultMessage: 'Total',
    description: '',
  },
  totalInEuro: {
    id: 'checkout.totalInEuro',
    defaultMessage: 'Total in euro',
    description: '',
  },
  deposit: {
    id: 'checkout.deposit',
    defaultMessage: 'Desposit to pay now',
    description: '',
  },
  transportTitle: {
    id: 'checkout.transportTitle',
    defaultMessage: 'Transport',
    description: '',
  },
  transportDesc: {
    id: 'checkout.transportDesc',
    defaultMessage: 'Transport is not included. We advise you to book your plane or train tickets after the validation of your reservation by the establishment',
    description: '',
  },
  cancellationTitle: {
    id: 'checkout.cancellationTitle',
    defaultMessage: 'Cancellation',
    description: '',
  },
  cancellationDesc: {
    id: 'checkout.cancellationDesc',
    defaultMessage: "We follow the school's cancellation conditions, you can consult them <a href='{link}' target='_blank'> here</a>. Contact us on the chat for any question",
    description: '',
  },
});

export const bookingConfirmation = defineMessages({
  success: {
    id: 'booking.success',
    defaultMessage: 'Payment Successful',
    description: '',
  },
  processing: {
    id: 'booking.processing',
    defaultMessage: 'Payment Processing',
    description: '',
  },
  successInfo: {
    id: 'booking.successInfo',
    defaultMessage: 'You will receive a email within 48-72 hours with the final confirmation of your booking.',
    description: '',
  },
  successInfoEmail: {
    id: 'booking.successInfoEmail',
    defaultMessage: 'We have emailed you this receipt:',
    description: '',
  },
  bookingId: {
    id: 'booking.bookingId',
    defaultMessage: 'Booking Reference',
    description: '',
  },
  paymentDate: {
    id: 'booking.paymentDate',
    defaultMessage: 'Payment date',
    description: '',
  },
  student: {
    id: 'booking.student',
    defaultMessage: 'Student',
    description: '',
  },
  paymentMethod: {
    id: 'booking.paymentMethod',
    defaultMessage: 'Payment method',
    description: '',
  },
  location: {
    id: 'booking.location',
    defaultMessage: 'Location',
    description: '',
  },
  school: {
    id: 'booking.school',
    defaultMessage: 'School Partner',
    description: '',
  },
  courseStart: {
    id: 'booking.courseStart',
    defaultMessage: 'Courses date',
    description: '',
  },
  amountPaid: {
    id: 'booking.amountPaid',
    defaultMessage: 'Total amount paid',
    description: '',
  },
  depositPaid: {
    id: 'booking.depositPaid',
    defaultMessage: 'Total deposit paid',
    description: '',
  },
  checkDetails: {
    id: 'booking.checkDetails',
    defaultMessage: 'You can check your booking details in your',
    description: '',
  },
  account: {
    id: 'booking.account',
    defaultMessage: 'account',
    description: '',
  },
});

export const formAddress = defineMessages({
  title: {
    id: 'address.title',
    defaultMessage: 'Billing address',
    description: '',
  },
  street1: {
    id: 'address.street1',
    defaultMessage: 'Enter street (line 1)',
    description: '',
  },
  street2: {
    id: 'address.street2',
    defaultMessage: 'Enter street (line 2)',
    description: '',
  },
  postalCode: {
    id: 'address.postalCode',
    defaultMessage: 'Enter Postal Code',
    description: '',
  },
  city: {
    id: 'address.city',
    defaultMessage: 'Enter City',
    description: '',
  },
  company: {
    id: 'address.company',
    defaultMessage: 'Enter Company',
    description: '',
  },
  country: {
    id: 'address.country',
    defaultMessage: 'Select Country',
    description: '',
  },
});

export const magazine = defineMessages({
  title: {
    id: 'magazine.title',
    defaultMessage: 'Worddy Magazine - Tips and Tools for a language study trip',
    description: 'title in magazine page',
  },
  subTitle: {
    id: 'magazine.subTitle',
    defaultMessage: 'Read on our online magazine news from the travel language industry, Students testimonials and tips to learn language.',
    description: 'subTitle in magazine page',
  },
  category: {
    id: 'magazine.category',
    defaultMessage: 'Category',
    description: 'text in block',
  },
  updated: {
    id: 'magazine.updated',
    defaultMessage: 'Updated',
    description: '',
  },
  placeholderSearch: {
    id: 'magazine.placeholderSearch',
    defaultMessage: 'Search category or article',
    description: '',
  },
  readMore: {
    id: 'magazine.readMore',
    defaultMessage: 'Read More',
    description: '',
  },
  seeLess: {
    id: 'magazine.seeLess',
    defaultMessage: 'See Less',
    description: '',
  },
  seePlus: {
    id: 'magazine.seePlus',
    defaultMessage: 'See Plus',
    description: '',
  },
  popularPosts: {
    id: 'magazine.popularPosts',
    defaultMessage: 'Popular posts',
    description: '',
  },
  recentPost: {
    id: 'magazine.recentPost',
    defaultMessage: 'Recent Post',
    description: '',
  },
  readArticle: {
    id: 'magazine.readArticle',
    defaultMessage: 'Read Article',
    description: '',
  },
  loadMoreArticles: {
    id: 'magazine.loadMoreArticles',
    defaultMessage: 'Load More Articles',
    description: '',
  },
  articles: {
    id: 'magazine.articles',
    defaultMessage: 'articles',
  },
  article: {
    id: 'magazine.article',
    defaultMessage: 'article',
  }
});

export const breadcrumbs = defineMessages({
  home: {
    id: 'breadcrums.home',
    defaultMessage: 'Language trip',
    description: '',
  },
});

export const searchForm = defineMessages({
  where: {
    id: 'search.where',
    defaultMessage: 'Where',
    description: 'where label in search',
  },
  languageToLearn: {
    id: 'search.languageToLearn',
    defaultMessage: 'Language to Learn',
    description: 'languageToLearn label in search',
  },
  accommodation: {
    id: 'home.accommodation',
    defaultMessage: 'Accommodation',
    description: 'accommodation label in search',
  },
  searchButton: {
    id: 'home.search.button',
    defaultMessage: 'Search',
    description: 'button in search form',
  },
  anywhere: {
    id: 'home.search.anywhere',
    defaultMessage: 'Anywhere',
    description: 'Anywhere in search form',
  },
});

export const errorsForm = defineMessages({
  socialAccount: {
    id: 'error.socialAccount',
    defaultMessage: 'Your account has been created via facebook, Twitter or Google. Please reuse one of these means of connection.',
  },
  wrongPassword: {
    id: 'error.wrongPassword',
    defaultMessage: 'The password or the email are not correct.',
  },
  userNotFound: {
    id: 'error.userNotFound',
    defaultMessage: 'You are not yet registered on our site, please first create an account.',
  },
  userAlreadyExist: {
    id: 'error.userAlreadyExist',
    defaultMessage: 'You are already registered on our site with this email address.',
  },
  passwordNotMatching: {
    id: 'error.passwordNotMatching',
    defaultMessage: 'Passwords are not the same',
  },
  errorRegistration: {
    id: 'error.errorRegistration',
    defaultMessage: 'There was an error while submitting the form, please fill in the required fields and start again.',
  },
  tokenNotFound: {
    id: 'error.tokenNotFound',
    defaultMessage: 'Password reset token is invalid or has expired.',
  },
  subscriberAlreadyExist: {
    id: 'error.subscriberAlreadyExist',
    defaultMessage: 'You are already subscribed to our newsletter.',
  },
  serverError: {
    id: 'error.server',
    defaultMessage: 'An error occurred, please try again later.',
  },
});

export const successForm = defineMessages({
  subscriberSuccess: {
    id: 'subscriber.success',
    defaultMessage: 'You have subscribed to our newsletter!',
  },
});

export const exitModal = defineMessages({
  compare: {
    id: 'exitModal.compare',
    defaultMessage: 'Search and book',
  },
  signUp: {
    id: 'exitModal.signUp',
    defaultMessage: 'Sign Up',
  },
  title: {
    id: 'exitModal.title',
    defaultMessage: 'Want to learn another language?',
  },
  subTitle: {
    id: 'exitModal.subTitle',
    defaultMessage: 'Or create a Worddy account to stay informed of the latest offers.',
  },
});

export const dealsList = defineMessages({
  noDeals: {
    id: 'deals.empty',
    defaultMessage: 'There are no promotions at the moment {linkingWord} {country}, don\'t forget to register to be notified.',
  },
  register: {
    id: 'deals.register',
    defaultMessage: 'Register in 2 clicks',
  },
  schoolTitle: {
    id: 'deals.schoolTitle',
    defaultMessage: 'Current deals',
  },
  schoolSubTitle: {
    id: 'deals.schoolSubTitle',
    defaultMessage: 'Here are the current promotions. Some of the offers are exclusive. The reservation form will be updated with the discounted prices, you don\'t need a promo code.',
  },
  expiredIn: {
    id: 'deals.expiredIn',
    defaultMessage: 'Expired in {days} days',
  },
  start: {
    id: 'deals.start',
    defaultMessage: 'Start date of the offer',
  },
  end: {
    id: 'deals.end',
    defaultMessage: 'End date of the offer',
  },
  seeOffer: {
    id: 'deals.seeOffer',
    defaultMessage: 'See the reductions',
  },
  courseDiscount: {
    id: 'course.discount',
    defaultMessage: 'Course Discount',
  },
  accommodationDiscount: {
    id: 'accommodation.discount',
    defaultMessage: 'Accommodation Discount',
  },
});

export const destinationPage = defineMessages({
  title: {
    id: 'destination.title',
    defaultMessage: 'Destination',
  },
  viewListing: {
    id: 'destination.viewListing',
    defaultMessage: 'View Listing Page',
  },
  viewSchool: {
    id: 'destination.viewSchool',
    defaultMessage: 'View School',
  },
  topSchools: {
    id: 'destination.topSchools',
    defaultMessage: 'Top Schools',
  },
  topCourses: {
    id: 'destination.topCourses',
    defaultMessage: 'Top Courses',
  },
  seeAll: {
    id: 'destinations.seeAll',
    defaultMessage: 'See all destinations',
  },
  description: {
    id: 'destinations.description',
    defaultMessage: 'Discover the world, meet new people and develop your language skills',
  }
});

export const schoolPage = defineMessages({
  reviewsTitle: {
    id: 'school.page.reviews.title',
    defaultMessage: 'Reviews',
  },
  averageTitle: {
    id: 'school.page.average.title',
    defaultMessage: 'Average Rating',
  },

});

export const whyBookWithUsPage = defineMessages({
  whyTextSideTitle: {
    id: 'why.text.side.title',
    defaultMessage: 'Why book with us your language trip?',
  },

  whyTextSideSubTitleFirst: {
    id: 'why.text.side.subtitleFirst',
    defaultMessage: 'Most of the people are drowned in the information given by language schools. You will find different type of courses, different names of courses for the same exact course, different prices, different locations… How to choose the right place for you? How to choose the right course? Where should you go or even which city is better than another one ?',
  },

  whyTextSideSubTitleSecond: {
    id: 'why.text.side.subtitleSecond',
    defaultMessage: 'We are very transparent and to every schools that we offer, we include their price list (on the right side of the page) so you can see that we do not over charge you BUT we give you a discount.',
  },
  whyToSumUpTitle: {
    id: 'why.to.sum.up.title',
    defaultMessage: 'To sum up:',
  },

  whyFuzeText: {
    id: 'why.fuze.text',
    defaultMessage: 'Worddy makes the search and booking process much easier and faster for you.',
  },

  whyParaText: {
    id: 'why.para.text',
    defaultMessage: 'Worddy is transparent and do not over charges you BUT gives you the best possible price.',
  },

  whyTelefonText: {
    id: 'why.telefon.text',
    defaultMessage: 'Worddy offers you a customer support available in 3 languages and this 7/7.',
  },

  whyBilgisayarText: {
    id: 'why.bilgisayar.text',
    defaultMessage: 'Worddy offers you a friendly website easy to use with accurate information.',
  },
  whyKartText: {
    id: 'why.kart.text',
    defaultMessage: 'Worddy eases the payment part between you and the school and explains you all the steps.',
  },
  whyBalonText: {
    id: 'why.balon.text',
    defaultMessage: 'Worddy remains at your disposal for any query, any doubts or any questions.',
  },

});

export const privacyPolicyPage = defineMessages({
  priTextBannerTitle: {
    id: 'privacy.text.banner.title',
    defaultMessage: 'Privacy Policy',
  },

});

export const registerPage = defineMessages({
  back: {
    id: 'back.to.main',
    defaultMessage: 'Back',
  },

});

export const registerPageSliderSideTexts = defineMessages({
  firstPageFirstTitle: {
    id: 'first.page.firstTitle',
    defaultMessage: 'Start your best journey with Worddy',
  },

  secondPageFirstTitle: {
    id: 'second.page.firstTitle',
    defaultMessage: 'Develop your language skills easily',
  },

  thirdPageFirstTitle: {
    id: 'third.page.firstTitle',
    defaultMessage: 'Your chance to discover best places',
  },

  firstPageSecondTitle: {
    id: 'first.page.secondTitle',
    defaultMessage: 'Get full access to all features and discover over 2,500 language courses worldwide',
  },

  secondPageSecondTitle: {
    id: 'second.page.secondTitle',
    defaultMessage: 'Discover new cultures and meet new people around the world with Worddy',
  },

  thirdPageSecondTitle: {
    id: 'third.page.secondTitle',
    defaultMessage: 'Easy booking with our powerful search algorithm and accomodation for all budgets',
  },
});

export const hottestDeals = defineMessages({
  prime: {
    id: 'hottest.deal.prime',
    defaultMessage: 'Prime',
  },
  hottestdeals: {
    id: 'hottest.deal.hottestdeal',
    defaultMessage: 'Hottest Deals',
  },
  seeAllPromotions: {
    id: 'hottest.deal.seeallpromotions',
    defaultMessage: 'See all promotions',
  },
  startingAt: {
    id: 'hottest.deal.startingAt',
    defaultMessage: 'Starting at',
  },
  viewOffers: {
    id: 'promotions.viewOffers',
    defaultMessage: 'View Offers',
  },
});

export const searchbar = defineMessages({
  anyLocation: {
    id: 'searchbar.any.location',
    defaultMessage: 'Any Location',
  },
});

export const headerPromo = defineMessages({
  day: {
    id: 'header.promo.day',
    defaultMessage: 'Day',
  },
  selectCourseButton: {
    id: 'header.promo.select.course.button',
    defaultMessage: 'Select Course',
  },
});

export const layout = defineMessages({
  learnMore: {
    id: 'course.learnMore',
    defaultMessage: 'Learn More',
  },
  readMore: {
    id: 'layout.readMore',
    defaultMessage: 'Read More',
  },
  orderByUpdated: {
    id: 'layout.orderByUpdated',
    defaultMessage: 'Updated',
  },
  magazineSearch: {
    id: 'magazine.search',
    defaultMessage: 'Search category or article',
  },
  magazinePopular: {
    id: 'magazine.popular',
    defaultMessage: 'Popular posts',
  },
  magazineRecent: {
    id: 'magazine.recent',
    defaultMessage: 'Recent Post',
  },
  magazineReadArticle: {
    id: 'magazine.readArticle',
    defaultMessage: 'Read Article',
  },
  magazineLoadMore: {
    id: 'magazine.loadMore',
    defaultMessage: 'Load more articles',
  },
  magazineLoadMore: {
    id: 'layout.explore',
    defaultMessage: 'Explore now',
  },
  requestCallback: {
    id: 'contact.requestCallback',
    defaultMessage: 'Request a callback',
  }
});
export const headerButtons = defineMessages({
  callback: {
    id: 'header.callback',
    defaultMessage: 'Callback',
  },
  signInUp: {
    id: 'header.sign.in.up',
    defaultMessage: 'Sign In/Up',
  }
});
export const searchSort = defineMessages({
  showOffers: {
    id: 'searchSort.showOffers',
    defaultMessage: 'Show offers:',
  },
});
export const wishlist = defineMessages({
  deleteWish: {
    id: 'wishlist.deleteWish',
    defaultMessage: 'Do you want to remove this item from your wishlist?',
  },
  apply: {
    id: 'wishlist.apply',
    defaultMessage: 'Apply',
  },
  cancel: {
    id: 'wishlist.cancel',
    defaultMessage: 'Cancel',
  },
  offer: {
    id: 'wishlist.offer',
    defaultMessage: 'offer',
  },
  offers: {
    id: 'wishlist.offers',
    defaultMessage: 'offers',
  },
  noWish: {
    id: 'wishlist.noWish',
    defaultMessage: 'No wishlist items',
  }
});

export const settings = defineMessages({
  login: {
    id: 'settings.login',
    defaultMessage: 'Login and security'
  },
  delete: {
    id: 'settings.delete',
    defaultMessage: 'Delete Account'
  },
  deleteMessage: {
    id: 'settings.deleteMessage',
    defaultMessage: 'This account will no longer be available, and your saved data will be permanently deleted.'
  },
  change: {
    id: 'settings.change',
    defaultMessage: 'Change'
  },
});

export const review = defineMessages({
  reviewInfo: {
    id: 'review.reviewInfo',
    defaultMessage: 'You can leave a review at the end of your language trip.'
  },
});
export const dashboard = defineMessages({
  hi: {
    id: 'dashboard.hi',
    defaultMessage: 'Hi'
  },
  niceMessage: {
    id: 'dashboard.niceMessage',
    defaultMessage: `it's nice to see you!`
  },
  completeProfile: {
    id: 'dashboard.completeProfile',
    defaultMessage: 'Complete my profile'
  },
  completeProfileInfo: {
    id: 'dashboard.completeProfileInfo',
    defaultMessage: 'Our schools partners need some personal information to validate your booking'
  },
  findDeals: {
    id: 'dashboard.findDeals',
    defaultMessage: 'Find the best deals'
  },
  findDealsInfo: {
    id: 'dashboard.findDealsInfo',
    defaultMessage: 'Use our search engine with filters to find the offer that suits you best according to your budget and your departure date'
  },
  saveDeals: {
    id: 'dashboard.saveDeals',
    defaultMessage: 'Save deals in my wishlist'
  },
  saveDealsInfo: {
    id: 'dashboard.saveDealsInfo',
    defaultMessage: 'Save your favorite package and share your public wishlist with your friends'
  },
  bookFlight: {
    id: 'dashboard.bookFlight',
    defaultMessage: 'Book my flight'
  },
  bookFlightInfo: {
    id: 'dashboard.bookFlightInfo',
    defaultMessage: 'Once your booking is validated, you can book your plane. We advise you to use the Jetradar comparator.'
  },
  bookFlightButton: {
    id: 'dashboard.bookFlightButton',
    defaultMessage: 'Book flight with Jetradar'
  },
  booking: {
    id: 'dashboard.booking',
    defaultMessage: 'Booking'
  },
  bookingInfo: {
    id: 'dashboard.bookingInfo',
    defaultMessage: 'Find all the information related to your booking and the documents/informations of the partner school'
  },
  prepareLuggage: {
    id: 'dashboard.prepareLuggage',
    defaultMessage: 'Prepare my luggage'
  },
  prepareLuggageInfo: {
    id: 'dashboard.prepareLuggageInfo',
    defaultMessage: 'Read our magazine to know all the tips before going abroad'
  },
  payMyBooking: {
    id: 'dashboard.payMyBooking',
    defaultMessage: 'Pay my booking online'
  },
  payMyBookingInfo: {
    id: 'dashboard.payMyBookingInfo',
    defaultMessage: 'You can pay serenely with our payment partner Stripe or with Paypal'
  },
  leaveReview: {
    id: 'dashboard.leaveReview',
    defaultMessage: 'Leave a review'
  },
  leaveReviewInfo: {
    id: 'dashboard.leaveReviewInfo',
    defaultMessage: 'After your trip, leave a review and refer your friends'
  },
});
