export const detailsRedText = {

    header: "What's\r\n a ",
    text: "A language stay consists in taking a language course abroad in a dedicated learning environment, usually a language school. The goal is to quickly improve your skills in the chosen foreign language while getting to know other cultures and interacting with people in the chosen language.",
    colorHeader: "language stay?",
    headerRest: ""
};
export const detailsRedTextFr = {

    header: "En quoi consiste ",
    text: `Un séjour linguistique consiste à suivre un cours de langue à l'étranger dans un univers prospère à l'apprentissage. Le but est d'améliorer rapidement vos compétences dans la langue étrangère choisie tout en faisant connaissance avec d'autres cultures. C'est une expérience unique à l'étranger dont vous allez vous souvenir toute votre vie.`,
    colorHeader: "un séjour linguistique?",
    headerRest: ""
};
export const detailsRedTextEs = {

    header: "¿Qué es ",
    text: `Una estancia lingüística consiste en realizar un curso de idiomas en el extranjero en un mundo en el que el aprendizaje florece. El objetivo es mejorar rápidamente el dominio de la lengua extranjera elegida y conocer otras culturas. Es una experiencia única en el extranjero que recordará el resto de su vida.`,
    colorHeader: "una estancia lingüística?",
    headerRest: ""
};



export const detailsBlueText = {

    header: "The",
    text: "Worddy is a comparator that connects independent language schools in 12 countries around the world with students between the ages of 12 and 99 who want to learn a language, improve their level, take exams or seek a professional career abroad.",
    colorHeader: " advantages ",
    headerRest: "with Worddy"
};
export const detailsBlueTextFr = {

    header: "Les avantages\n",
    text: `Worddy est un comparateur qui met en relation des écoles de langues indépendantes présentes dans 12 pays à travers le monde avec des étudiants de 12 à 99 ans qui veulent apprendre une langue.`,
    colorHeader: " du séjour linguistique ",
    headerRest: "avec Worddy"
};
export const detailsBlueTextEs = {

    header: "Las ventajas\r\n",
    text: `Worddy es un servicio de comparación que pone en contacto a escuelas de idiomas independientes de 12 países de todo el mundo con estudiantes de 12 a 99 años que quieren aprender un idioma.`,
    colorHeader: " de viajar por idiomas ",
    headerRest: "con Worddy"
};