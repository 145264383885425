export const blueDetails = [

    {
        icon: '/img/infoBoxIcons/blueIcons/receipt-edit.svg',
        header: 'Price transparency',
        text: "Worddy is the only website to use its own algorithm to offer language travel prices in real time according to ten parameters. You can then customize your package according to the desired: duration, departure date, type of course and accommodation. You can also include options such as airport transfer and health insurance."
    },

    {
        icon: '/img/infoBoxIcons/blueIcons/book.svg',
        header: 'TOEFL OR IELTS exam preparation',
        text: "If you need to take these exams, a language course is a good way to prepare for it with 20 or 30 lessons per week and guarantee that you will successfully pass."
    },

    {
        icon: '/img/infoBoxIcons/blueIcons/buildings-2.svg',
        header: 'Wide choice of language courses',
        text: "Worddy offers a wide choice of language courses ranging from 10 lessons per week to 40 lessons per week. You can choose between group and private lessons or even mix the two options. Group lessons allow you to practice the foreign language with foreign speakers. They are therefore essential to review the basics of the foreign language."
    },

    {
        icon: '/img/infoBoxIcons/blueIcons/slider.svg',
        header: 'Wide choice of accommodation',
        text: "Worddy offers on its website around 60 different types of accommodation for all budgets. Depending on your preferences and needs, you can choose an accommodation from a shared apartment, a student residence or a host family accommodation."
    },

    {
        icon: '/img/infoBoxIcons/blueIcons/verify.svg',
        header: 'Certified partners',
        text: "Worddy selects the best language schools based on the certifications, reviews of former students but also the location of the schools and the school's facilities. At the end of their stay, the students receive a questionnaire which allows them to give feedback on the quality of the courses and accommodation they received. This allows Worddy to update the list of our partners and always make sure to guarantee a high level of quality."
    },

    {
        icon: '/img/infoBoxIcons/blueIcons/tripIcon.svg',
        header: 'Price of a language trip',
        text: "The cost of a language stay is not negligible, prices vary from 300 euros to more than 1000 euros per week. It should be seen as an investment in your future. You have to take into account the cost of travel, accommodation, language courses, but also additional costs such as transportation, meals, outings... The goal is to have a good time, so it is important to find out about the cost of living in the city you are going to in order to be comfortable with your budget."
    }

];

export default blueDetails;
