import * as Cookies from 'js-cookie';

export const getFaq = async (fetch, locale) => {
	try {
		const url = `/faq/all?locale=${locale}`;
		const response = await fetch(url, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		});
		return await response.json();
	} catch (error) {
		return error;
	}
};

export const uploadImage = async (data) => {
	const options = {
		method: 'POST',
		body: data
	}
	try {
		const url = `/api/image-upload`;
		const response = await fetch(`${url}`, options)
		return response.json();

	} catch (error) {
		return error;
	}
}

export const authFtp = async (username, password) => {
	const options = {
		method: 'GET'
	}
	try {
		const url = `/api/auth-ftp?username=${username}&password=${password}`;
		const response = await fetch(`${url}`, options)
		return response.json();

	} catch (error) {
		return error;
	}
}
export const updatePassword = async (fetch, data) => {
	try {
		const url = `/account/${data.userId}/update-password`;
		const token = Cookies.get('token');
		const response = await fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify(data),
		});
		const res = await response.json();
		return await res;
	} catch (error) {
		return error;
	}
};


export const updateEmail = async (fetch, userID, email) => {
	try {
		const url = `/account/${userID}/update-email`;
		const token = Cookies.get('token');
		const response = await fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify(email),
		});
		return await response.json();
	} catch (error) {
		console.log(error);
		return error;
	}

};

export const sortMagazine = async (fetch, searchParam, locale) => {
	try {
		const url = `/magazine/categories?sort=${searchParam}&locale=${locale}`;
		const response = await fetch(url, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			}
		});
		return await response.json();
	} catch (error) {
		console.log(error);
		return error;
	}
};

export const searchMagazine = async (fetch, searchParam) => {
	try {
		const url = `/magazine/categories-articles?search=${searchParam}`;
		const response = await fetch(url, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			}
		});
		return await response.json();
	} catch (error) {
		console.log(error);
		return error;
	}
};

export const getCountryDetails = async (fetch, countryID) => {
	try {
		const url = `/countries/${countryID}`;
		const response = await fetch(url, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			}
		});
		return await response.json();
	} catch (error) {
		console.log(error);
		return error;
	}
}


export const getLanguageCourse = async (fetch, slug, locale) => {
	try {
		const url = `/content-seo/slug/${slug}?locale=${locale}`;
		const response = await fetch(url, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		});
		return await response.json();
	} catch (error) {
		console.log(error);
		return error;
	}
}

export const getPromotionTimerInfo = async (fetch) => {

	try {
		const url = `/deals/closest`;
		const response = await fetch(url, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		});
		return await response.json();
	} catch (error) {
		console.log(error);
		return error;
	}
}

export const getLatestSeason = async (fetch) => {

	try {
		const url = `/seasons/latest`;
		const response = await fetch(url, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		});
		return await response.json();
	} catch (error) {
		console.log(error);
		return error;
	}
}