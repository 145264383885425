import React from 'react';
import PropTypes from 'prop-types';
import TextLayout from '../TextLayout/TextLayout';
import { defineMessages, FormattedMessage } from 'react-intl';
import Link from '../Link';
import { LazyLoadImage } from "react-lazy-load-image-component";

const messages = defineMessages({
	title: {
		id: 'SelectedSchool.title',
		defaultMessage: 'Selected Schools',
		description: 'title in SelectedSchool',
	},
	subTitle: {
		id: 'SelectedSchool.subTitle',

		defaultMessage: `We carefully select the schools we work with based on various criteria to ensure that our students have the best possible experience. Some of the criteria we use to evaluate schools include:\n
		• Quality of instruction.
		• Accreditation.
		• Location and facilities.
		• Student support.\n
		By working with only the best schools, we can ensure that our students have a truly exceptional experience when they study English abroad with us.`,


		description: 'subTitle in SelectedSchool',
	},
	list: {
		id: 'SelectedSchool.list',
		li: ``,
		description: 'list in SelectedSchool',
	},
	button: {
		id: 'SelectedSchool.btnMore',
		defaultMessage: 'Get Started',
		description: 'button in SelectedSchool',
	},
});


const EasyBookingSection = ({ locale }) => (


	<div className='easyBookingSecWrapper'>
		<div className='container'>
			<div className='easyBookingSecContainer'>

				<div className='selectedTextWrapper'>
					<h2><FormattedMessage {...messages.title} /></h2>
					<div>
						{(locale === 'en') &&
							<div className='selectedSchoolMiddle'>
								<p>
									We carefully select the schools we work with based on various criteria to ensure that our students have the best possible experience. Some of the criteria we use to evaluate schools include:
								</p>
								<ul>
									<li>Quality of instruction.</li>
									<li>Accreditation.</li>
									<li>Location and facilities.</li>
									<li>Student support.</li>
								</ul>
								<p>
									By working with only the best schools, we can ensure that our students have a truly exceptional experience when they study English abroad with us.
								</p>
							</div>
						}
						{(locale === 'fr') &&
							<div className='selectedSchoolMiddle'>
								<p>
									Notre processus de sélection des écoles avec lesquelles nous collaborons est rigoureux. Nous nous basons sur une série de critères pertinents pour garantir à nos étudiants une expérience d'apprentissage de qualité supérieure.
									Voici quelques exemples de critères que nous prenons en compte dans notre évaluation des écoles:								</p>
								<ul>
									<li>La qualité de l'enseignement.</li>
									<li>L'accréditation.</li>
									<li>Emplacement et installations.</li>
									<li>Le soutien aux étudiants.</li>
								</ul>
								<p>
									En sélectionnant rigoureusement les écoles avec lesquelles nous collaborons, nous nous assurons que nos étudiants bénéficient d'une expérience d'apprentissage de qualité supérieure.
								</p>
							</div>
						}
						{(locale === 'es') &&
							<div className='selectedSchoolMiddle'>
								<p>
									Seleccionamos cuidadosamente las escuelas con las que trabajamos basándonos en varios criterios para garantizar que nuestros estudiantes tengan la mejor experiencia posible. Algunos de los criterios que utilizamos para evaluar las escuelas incluyen:								</p>
								<ul>
									<li>Calidad de la enseñanza.</li>
									<li>Acreditación.</li>
									<li>Ubicación e instalaciones.</li>
									<li>Apoyo a los estudiantes.</li>
								</ul>
								<p>
									Al trabajar sólo con las mejores escuelas, podemos garantizar que nuestros estudiantes tengan una experiencia verdaderamente excepcional cuando estudian inglés en el extranjero con nosotros.								</p>
							</div>
						}

					</div>
					<div className='textLayoutTextButton'>

						<Link to='#'><FormattedMessage {...messages.button} /></Link>
					</div>
				</div>


				 <LazyLoadImage src='/img/home/cover_bookingOpt.png' alt='easy booking' />
			</div>
		</div>
	</div>
);


EasyBookingSection.propTypes = {

};

export default EasyBookingSection;
