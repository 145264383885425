import React from 'react';
import PropTypes from 'prop-types';


const DisplayPrice = ({
  total,
  totalWithReduction = 0,
  reductionPercent = null,
  // reductionAmount = null,
  schoolCurrency,
  displayReduction = true,
}, context) => {
  const {
    displayPriceWithCurrency,
    currency,
    currencies,
    locale,
  } = context;
  const fomatedTotal = total > 0 ? displayPriceWithCurrency(total, schoolCurrency, currency, currencies, locale) : '--';
  const fomateTotalWithReduction = totalWithReduction > 0 ? displayPriceWithCurrency(totalWithReduction, schoolCurrency, currency, currencies, locale) : '--';
  let formatedReduction = null;
  if (totalWithReduction > 0 && total !== totalWithReduction) {
    if (reductionPercent) {
      formatedReduction = `${reductionPercent}%`;
    } else {
      const reduction = total - totalWithReduction;
      formatedReduction = displayPriceWithCurrency(
        reduction, schoolCurrency, currency, currencies, locale,
      );
    }
    return (
      <>
      <span className="old-price">{fomatedTotal}</span>
      {'  '}<span className="red-price">{fomateTotalWithReduction}</span>
      {formatedReduction && displayReduction && <span className="red-price"> (-{formatedReduction})</span>}
      </>
    );
  }
  return (fomatedTotal);
};

DisplayPrice.propTypes = {
  total: PropTypes.number.isRequired,
  totalWithReduction: PropTypes.number,
  schoolCurrency: PropTypes.string.isRequired,
};

DisplayPrice.contextTypes = {
  displayPriceWithCurrency: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  currencies: PropTypes.array.isRequired,
  currency: PropTypes.string.isRequired,
};

DisplayPrice.defaultProps = {
  totalWithReduction: null,
};


export default DisplayPrice;
