import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import Link from '../Link';

const messages = defineMessages({
  title: {
    id: 'BannerHome.title',
    defaultMessage: 'Total Language Immersion: All-Inclusive Course Packages.',
    description: 'title in BannerHome',
  },
  subTitle: {
    id: 'BannerHome.subTitle',
    defaultMessage: `Get our lowest prices on courses and accommodations,
      when you bundle it all in a single booking. Use our trip builder to spend less
      time planning and more time traveling and learning.`,
    description: 'subTitle in BannerHome',
  },
  button: {
    id: 'BannerHome.btnMore',
    defaultMessage: 'Find my language trip',
    description: 'button in BannerHome',
  },
});
// eslint-disable-next-line no-empty-pattern
const BannerHome = ({}, { locale, getLocaleLink }) => (
  <div className="theme-hero-area">
    <div className="theme-hero-area-bg-wrap">
      <div
        className="theme-hero-area-bg"
        style={{ backgroundImage: 'url(\'https://res.cloudinary.com/worddy-co/image/upload/v1557212518/plane-flight.jpg\')' }}
        data-parallax="true"
      />

      <div className="theme-hero-area-mask" />
    </div>
    <div className="theme-hero-area-body">
      <div className="theme-page-section theme-page-section-xxl">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-md-offset-3">
              <div className="theme-hero-text theme-hero-text-white theme-hero-text-center">
                <div className="theme-hero-text-header">
                  <div className="theme-hero-text-header">
                    <h2 className="theme-hero-text-title"><FormattedMessage {...messages.title} /></h2>
                  </div>
                </div>
                <>
                  <p className="theme-hero-text-subtitle"><FormattedMessage {...messages.subTitle} /></p>
                  {/*
                  <Link
                    className="btn _tt-uc _mt-20 btn-white btn-ghost btn-lg"
                    to={`/${getLocaleLink('search', locale)}`}
                    hideLink
                  >
                    <FormattedMessage {...messages.button} />
                  </Link>
                  */}
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);


BannerHome.contextTypes = {
  locale: PropTypes.string,
  getLocaleLink: PropTypes.func.isRequired,
};


export default BannerHome;
