import React, { memo } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import logoBlack from '../Header/logo-worddy-2.png';
import { LazyLoadImage } from "react-lazy-load-image-component";

const messages = defineMessages({
  title: {
    id: 'BannerAbout.welcome',
    defaultMessage: 'Welcome to',
    description: 'title in BannerAbout',
  },
  aboutText: {
    id: 'BannerAbout.text',
    defaultMessage: 'Nowadays, the only barrier in a world with no borders is language ! \n We believe that everybody should have the chance to travel and learn a new language whether it is for professional or personal purposes. \n Yes, we know it can be complicated and long seeing the many offers, reviews, websites you will come across online.\n We decided to create Worddy to offer you the easiest and hassle free experience so you can focus on what matter the most : packing !\n Booking your Language school and accommodation has never been so easy and transparent. \n With Worddy you cannot go wrong We made it for you !',
    description: '',
  },
});

const BannerAbout = memo(() => (
  <div className="theme-page-section theme-page-section-xxl">
    <div className="container">
      <div className="row">
        <div className="col-md-8 col-md-offset-2">
          <div className="theme-hero-text theme-hero-text-center">
            <div className="theme-hero-text-header">
              <p className="theme-hero-text-title">
                <FormattedMessage {...messages.title} />
                 <LazyLoadImage
                  src={logoBlack}
                  className="logo-about"
                  alt="Worddy"
                  title="Worddy.co"
                  width="170"
                  height="46"
                />
              </p>
              <p className="theme-hero-text-subtitle">
                <FormattedMessage {...messages.aboutText} />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
));

export default BannerAbout;
