import React from 'react';
import PropTypes from 'prop-types';
// import OwlCarousel from 'react-owl-carousel2';
import { defineMessages, FormattedMessage } from 'react-intl';
import FormSearchBlock from '../../components/FormSearchBlock';
import Icon from '../../components/Icon/Icon';
import SearchBar from '../../components/SearchBar/SearchBar';
const messages = defineMessages({
    title: {
        id: 'home.title',
        defaultMessage: 'Book language courses abroad with ',
        description: 'title in home',
    },
    subTitle: {
        id: 'home.subTitle',
        defaultMessage: 'Thousands of deals made every day, save more on your next language trip.',
        description: 'subTitle in home',
    },
    legend: {
        id: 'home.legend',
        defaultMessage: ` Language courses start on monday.
      Price vary according to the season and the duration of the trip.`,
        description: 'legend in home',
    },
    review1: {
        id: 'home.review1',
        defaultMessage: 'I had a great experience of studying in SprachCaffe last autumn. Many thanks to Worddy for organising my trip, everything went very smoothly and I did not have to worry about anything. I would highly recommend the service.',
    },
    review2: {
        id: 'home.review2',
        defaultMessage: 'Awesome! I took a phone appointment with the worddy team to get information about Malta before booking my trip. Thanks Worddy, such a great job.',
    },
    review3: {
        id: 'home.review3',
        defaultMessage: 'Thanks for the opportunity to study abroad!',
    },
});
// eslint-disable-next-line no-empty-pattern
const HomeSearch = ({}, { isMobile }) => {
  const coverHome = [
    {
      mask: 'theme-hero-area-grad-mask-light', url: '/img/home/banner2.png',
    },
    {
      mask: 'theme-hero-area-grad-mask-light', url: '/img/home/banner1.png',
    },
  ];
    return (
        <div className="_pos-r">
            <div className='container'>
                <div className='homepage-header-parent'>
                    <div className='homepage-searchbar'>
                        <SearchBar />
                    </div>
                    <div className='homepage-header-left-side'>
                        <h1 className='homepage-header-left-title'>
                            <FormattedMessage {...messages.title} children={msg => <>{msg}</>} /> <span style={{ color: "#FA4359" }}>Worddy</span>
                        </h1>
                        <p className='homepage-header-left-sub'>
                            <FormattedMessage {...messages.subTitle} children={msg => <>{msg}</>} />
                        </p>
                    </div>
                    <div className='homepage-header-right-side m-none'>
                        <div className='homepage-header-left-picture' style={{ backgroundImage: `url(${coverHome[0].url})` }} />
                        <div className='homepage-header-right-picture' style={{ backgroundImage: `url(${coverHome[1].url})` }} />
                        <div className='homepage-header-locations header-locations-first'>
                            <Icon imgHeight={24} imgWidth={24} src='/img/homepage-small-location.svg' />
                            <span>Malta</span>
                        </div>
                        <div className='homepage-header-locations header-locations-second'>
                            <Icon imgHeight={24} imgWidth={24} src='/img/homepage-small-location.svg' />
                            <span>United Arab Emirates</span>
                        </div>
                        <div className='homepage-header-locations header-locations-third'>
                            <Icon imgHeight={40} imgWidth={40} src='/img/plane-icon.svg' />
                        </div>
                    </div>
                </div>
                {/* <p className='homepage-header-description'>
                    <span style={{ color: "#FA4359" }}>*</span><FormattedMessage {...messages.legend} />
                </p> */}
            </div>
        </div>
    );
};
HomeSearch.contextTypes = {
    isMobile: PropTypes.bool.isRequired,
};
export default HomeSearch;