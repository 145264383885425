import React from 'react';
import PropTypes from 'prop-types';
import TextLayout from '../TextLayout/TextLayout';



const RegisterSection = ({ }) => (

    <div className="registerSecWrapper">

        <div className="registerSecContainer">

            <div className="registerSecBg" style={{ backgroundImage: `url('/img/home/register.png')` }}>

                <div className="container registerSecTextWrapper">

                    <div style={{ width: "100%" }}></div>
                    <TextLayout
                        header="Register to unlock our secret deals"
                        text="By registering, you can also chat with our partner schools, have a custom quote, save our best trips in your wishlist, book and pay online."
                        buttonText="Register"
                        buttonLink="/en/register"
                        isWhite
                        isRow={false}
                    />

                </div>
            </div>
        </div>
    </div>
);


RegisterSection.contextTypes = {

};

export default RegisterSection;
