import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import { hottestDeals } from '../shareTranslation';
import Link from '../Link/Link';
import DisplayPrice from '../generic/DisplayPrice/DisplayPrice';
import moment from 'moment';
import { LazyLoadImage } from "react-lazy-load-image-component";


const HottestDealsElement = ({item, locale,
	getUrlByLocale}) => {
	const {
		isHottestDeal,
		imgLink,
		country,
		startEndDate,
		discountPercent,
		discountPrice,
		formerPrice,
		startDateBookingReduction,
		endDateBookingReduction
	} = item;

	//for fire image on item when hover
	const [isHovering, setIsHovering] = useState(false);

	const handleMouseOver = () => {
		setIsHovering(true);
	};

	const handleMouseOut = () => {
		setIsHovering(false);
	};

	return (
		<div className="hottest-deals-item">
			<div className="hottest-deals-item-wrapper"
				onMouseEnter={handleMouseOver}
				onMouseLeave={handleMouseOut} >
				{/*<div className="hottestBg" style={{ backgroundImage: `url(${imgLink})` }}></div> */}
				<div className='img-wrapper'>
					<div className="country">{item[`country${getUrlByLocale(locale)}`]}</div>
					 <LazyLoadImage src={imgLink} alt={country} />
				</div>
				{/*  <LazyLoadImage className="background-image" src={imgLink} /> */}
				<div className={"discount"}>{discountPercent}</div>
				{
					isHottestDeal &&
					<div className="icon-wrapper" >
						 <LazyLoadImage src="/img/hottestDeals/smallHotDeal.svg" alt="hot deal" />
					</div>
				}
				<div className="text-area">
					<div className="date">
						<div className={"calendar-deal"}>
							<svg width='22' height='24'>
								<g xmlns="http://www.w3.org/2000/svg" id="Group_3" data-name="Group 3" transform="translate(-4 -2.333)">
									<path id="Path_3" data-name="Path 3" d="M13.333,3.333V6.665" transform="translate(-2.781)" fill="none" stroke="#fa4359" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2"></path>
									<path id="Path_4" data-name="Path 4" d="M26.667,3.333V6.665" transform="translate(-7.23)" fill="none" stroke="#fa4359" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2"></path>
									<path id="Path_5" data-name="Path 5" d="M5.833,15.15H24.713" transform="translate(-0.278 -3.943)" fill="none" stroke="#fa4359" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2"></path>
									<path id="Path_6" data-name="Path 6" d="M24.99,11.386v9.44c0,3.332-1.666,5.553-5.553,5.553H10.553C6.666,26.379,5,24.158,5,20.826v-9.44c0-3.332,1.666-5.553,5.553-5.553h8.884C23.324,5.833,24.99,8.055,24.99,11.386Z" transform="translate(0 -0.834)" fill="none" stroke="#fa4359" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2"></path>
									<path id="Path_7" data-name="Path 7" d="M26.158,22.833h.01" transform="translate(-7.06 -6.506)" fill="none" stroke="#fa4359" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></path>
									<path id="Path_8" data-name="Path 8" d="M26.158,27.833h.01" transform="translate(-7.06 -8.175)" fill="none" stroke="#fa4359" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></path>
									<path id="Path_9" data-name="Path 9" d="M19.993,22.833H20" transform="translate(-5.003 -6.506)" fill="none" stroke="#fa4359" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></path>
									<path id="Path_10" data-name="Path 10" d="M19.993,27.833H20" transform="translate(-5.003 -8.175)" fill="none" stroke="#fa4359" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></path>
									<path id="Path_11" data-name="Path 11" d="M13.824,22.833h.01" transform="translate(-2.944 -6.506)" fill="none" stroke="#fa4359" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></path>
									<path id="Path_12" data-name="Path 12" d="M13.824,27.833h.01" transform="translate(-2.944 -8.175)" fill="none" stroke="#fa4359" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></path>
								</g>
							</svg>
							<p>{`${moment(startDateBookingReduction).format('DD MMM')}-${moment(endDateBookingReduction).format('DD MMM')}`}</p>
						</div>
						<p className={("starting-at")}>
						<FormattedMessage {...hottestDeals.startingAt} />
							</p>
					</div>
					<div className={("price-per-pass")}>
						<h4><DisplayPrice total={formerPrice} schoolCurrency={'EUR'} displayReduction={false} /></h4>
						<h2><DisplayPrice total={discountPrice} schoolCurrency={'EUR'} displayReduction={false} /></h2>
					</div>
					<div className={("per-passenger")}>
						<p>{item.school}</p>
					</div>
				</div>
			</div>
		</div>
	);
}

const HottestDeals = ({ items, loadMore, context }) => {
	const { locale, countries, getUrlByLocale, getLocaleLink } = context;

	return (
		<section className="hottest-deals">
			<div className="container">
				<div className="deals-header">
					<div className="deals-title">
						 <LazyLoadImage src="/img/hottestDeals/hottest_deals_icon.png" alt="hottest deals" />
						<h2><span>Worddy</span><br /> <p><FormattedMessage {...hottestDeals.hottestdeals} children={msg => <>{msg}</>} /></p></h2>
					</div>
				</div>
				<div className={("items") + (items.length % 3 == 0 ? ' three-cols' : '')}>
					{items.map((item, index) => {
						return (
							<div className='item' key={index}>
								<HottestDealsElement item={item} locale={locale} getUrlByLocale={getUrlByLocale} />
							</div>
						);
					})}
				</div>
				{
					loadMore &&
					<div className="load-more">
						<Link className="load-more-deals" to={`/${getLocaleLink('deals', locale)}`} >
							<FormattedMessage {...hottestDeals.seeAllPromotions} />
						</Link>
					</div>
				}
			</div>
		</section>
	);
}

HottestDeals.contextTypes = {
	locale: PropTypes.string.isRequired,
	getLocaleLink: PropTypes.func.isRequired,
	countries: PropTypes.array.isRequired,
	getUrlByLocale: PropTypes.func.isRequired,
};

HottestDeals.prototype = {
	items: PropTypes.object
}

HottestDealsElement.prototype = {
	item: PropTypes.object
};

export default HottestDeals;
