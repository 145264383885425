export const items = [
    {
        _id: '222001',
        type: 'school',
        title: '1 week in <b>Malta</b>',
        titleFr: '1 semaine à <b>Malte</b>',
        titleEs: '1 semana en <b>Malta</b>',
        description: '20 hrs of General English Accommodation included',
        descriptionFr: "20h d'anglais général hébergement inclus",
        descriptionEs: '20 hrs de inglés general alojamiento incluido',
        isHottestDeal: true,
        reductionAmountCourse: 15,
        startDateBookingReduction: "2001-01-01T00:00:00.000Z",
        endDateBookingReduction: "2023-10-30T22:59:59.000Z",
        "slug": "en/school/ec-malta?duration=1",
        "slugFr": "fr/ecole/ec-malte?duration=1",
        "slugEs": "es/escuela/ec-malta?duration=1",
        price: 255,
        
        school: {
            "currency": "EUR",
            "_id": "5cc009d1ddfd14115177d110",
            "slug": "ec-malta",
            "slugFr": "ec-malte",
            "slugEs": "ec-malta",
            "name": "EC Malta",
            "nameFr": "EC Malte",
            "nameEs": "EC Malta",
            "bookingTerms": "https://www.ecenglish.com/en/terms-and-conditions",
            "logo": "https://worddy.com/img/cloud/uploads/logo-ec-malta-english-school-malta.jpg",
            "country": { "code": "MT", "name": "Malta", "nameFr": "Malte", "nameEs": "Malta" },
            "city": { "code": "STJ", "name": "St Julian’s", "nameFr": "St Julian’s", "nameEs": "St Julian’s" },
            "rating": 4.2
        },
        image: '/img/home/malta-promotion.png'
    }
];

export default items;