import * as Cookies from 'js-cookie';
import jwt from 'jsonwebtoken';
import history from '../history';
import axios from 'axios';

export const registerUserWithEmail = async (fetch, newUser) => {
  try {
    const url = '/auth/register-auth-email';
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newUser),
    });
    const resToken = await response.json();
    return await resToken;
  } catch (error) {
    return error;
  }
};

export const loginUserWithEmail = async (fetch, user) => {
  try {
    const url = '/auth/login-auth-email';
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user),
    });
    const resToken = await response.json();
    return await resToken;
  } catch (error) {
    return error;
  }
};

export const resetUserPasswordWithEmail = async (fetch, user) => {
  try {
    const url = '/auth/reset-password';
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user),
    });
    const res = await response.json();
    return await res;
  } catch (error) {
    return error;
  }
};

export const setNewUserPassword = async (fetch, user) => {
  try {
    const url = '/auth/reset-new-password';
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user),
    });
    const resToken = await response.json();
    return await resToken;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const createBooking = async (fetch, newBooking) => {
  try {
    const url = '/bookings';
    const token = Cookies.get('token');
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(newBooking),
    });
    const booking = await response.json();
    return await booking;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const createTraveler = async (fetch, newTraveler) => {
  try {
    const url = '/travelers';
    const token = Cookies.get('token');
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(newTraveler),
    });
    const traveler = await response.json();
    return await traveler;
  } catch (error) {
    return error;
  }
};

export const createSubscriber = async (fetch, newSuscriber) => {
  try {
    const url = '/newsletter/subcribers';
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(newSuscriber),
    });
    const subscriber = await response.json();
    return await subscriber;
  } catch (error) {
    return error;
  }
};

/*
export const createBraintreeTransaction = async (fetch, transaction) => {
  try {
    const url = '/braintree/checkouts';
    const token = Cookies.get('token');
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(transaction),
    });
    const traveler = await response.json();
    return await traveler;
  } catch (error) {
    console.log(error);
    return error;
  }
};
*/

export const getStripeConfig = async (fetch) => {
  try {
    const url = '/stripe/config';
    const token = Cookies.get('token');
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    return await response.json();
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const createStripePaymentIntents = async (fetch, booking) => {
  try {
    const url = '/stripe/payment-intents';
    const token = Cookies.get('token');
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(booking),
    });
    return await response.json();
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const updateStripeBookingStatus = async (fetch, booking) => {
  try {
    const url = '/stripe/payment-validation';
    const token = Cookies.get('token');
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(booking),
    });
    return await response.json();
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getPaypalConfig = async (fetch) => {
  try {
    const url = '/paypal/init';
    const token = Cookies.get('token');
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    return await response.json();
  } catch (error) {
    return error;
  }
};

export const updatePaypalBookingStatus = async (fetch, booking) => {
  try {
    const url = '/paypal/payment-validation';
    const token = Cookies.get('token');
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(booking),
    });
    return await response.json();
  } catch (error) {
    return error;
  }
};

export const getSchoolReviews = async (fetch, googlePlaceId, locale) => {
  try {
    const url = `/reviews/google/${googlePlaceId}?locale=${locale}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return await response.json();
  } catch (error) {
    return error;
  }
};

export const postSupportMessage = async (fetch, info) => {
  try {
    const url = '/supports/message';
    const response = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        info,
      }),
    });
    const message = await response.json();
    return await message;
  } catch (error) {
    console.log(error);
    return error;
  }
};


export const getProfile = async (fetch, userId) => {
  try {
    const url = `/users/${userId}`;
    const token = Cookies.get('token');
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    const profile = await response.json();
    return await profile;
  } catch (error) {
    console.log(error);
    return {};
  }
};

/*
export const getBraintreeClientToken = async (fetch) => {
  try {
    const url = '/braintree/checkouts/client/token';
    const token = Cookies.get('token');
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    const resToken = await response.json();
    return await resToken;
  } catch (error) {
    console.log(error);
    return error;
  }
};
*/

export const updateProfileImage = async (fetch, userId, formData) => {
  try {
    const url = `${window.App.apiServerUrl}/users/${userId}/image`;
    const token = Cookies.get('token');
    const response = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getProfileImage = async (fetch, userId) => {
  try {
    const url = `/users/${userId}/image`;
    const token = Cookies.get('token');
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    return response.json();
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const updateProfile = async (fetch, userId, profile) => {
  try {
    const url = `/users/${userId}`;
    const token = Cookies.get('token');
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(profile),
    });
    return await response.json();
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const updateTraveler = async (fetch, travelerId, traveler) => {
  try {
    const url = `/travelers/${travelerId}`;
    const token = Cookies.get('token');
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(traveler),
    });
    const resTraveler = await response.json();
    return await resTraveler;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const updateToken = async (fetch, locale) => {
  try {
    const url = '/auth/update-token';
    const token = Cookies.get('token');
    const response = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        token,
      }),
    });
    const newAuth = await response.json();
    if (newAuth && newAuth.token) {
      Cookies.set('token', newAuth.token, { expires: 7 });
    } else if (newAuth.status === 401) {
      Cookies.remove('token');
      history.push(`/${locale}`);
      return window.location.reload(true);
    }
    return await jwt.decode(newAuth.token);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const generateTokenByCode = async (fetch, code, locale, pathname) => {
  try {
    const url = '/auth/generate-token-code';
    const response = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        code,
      }),
    });
    const newAuth = await response.json();
    if (newAuth && newAuth.token) {
      Cookies.set('token', newAuth.token, { expires: 7 });
    } else if (newAuth.status === 401) {
      Cookies.remove('token');
      history.push(`/${locale}`);
      return window.location.reload(true);
    }
    return await jwt.decode(newAuth.token);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const updateBooking = async (fetch, bookingId, data) => {
  try {
    const url = `/bookings/${bookingId}`;
    const token = await Cookies.get('token');
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    const profile = await response.json();
    return await profile;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getBooking = async (fetch, bookingId) => {
  try {
    const url = `/bookings/${bookingId}`;
    const token = await Cookies.get('token');
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    const booking = await response.json();
    return await booking;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getBookingsByUserId = async (fetch, userId) => {
  try {
    const url = `/bookings/users/${userId}`;
    const token = Cookies.get('token');
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    const booking = await response.json();
    return await booking;
  } catch (error) {
    return [];
  }
};

export const getWorddyOptions = async (fetch, bookingId) => {
  try {
    const url = `/insurances/booking/${bookingId}`;
    const token = await Cookies.get('token');
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    const worddyOptions = await response.json();
    return await worddyOptions;
  } catch (error) {
    return error;
  }
};

export const getFullCountriesList = async (fetch) => {
  try {
    const url = '/settings/countries';
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const countries = await response.json();
    return await countries;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const createWishlistItem = async (fetch, newWishlistItem) => {
  try {
    const url = '/wishlists';
    const token = Cookies.get('token');
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(newWishlistItem),
    });
    return response.json();
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const deleteWishlistItem = async (fetch, wishlistItemId) => {
  try {
    const url = `/wishlists/${wishlistItemId}`;
    const token = Cookies.get('token');
    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    const deletedItem = await response.json();
    return await deletedItem;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getWishlistsByUserId = async (fetch, userId) => {
  try {
    const url = `/wishlists/users/${userId}`;
    const token = Cookies.get('token');
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    return response.json();
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getCountryDetails = async (fetch, countryId) => {
  try {
    const url = `/countries/${countryId}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const countryDetail = response.json();
		return countryDetail;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getCityDetails = async (fetch, cityId) => {
  try {
    const url = `/cities/${cityId}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.json();
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getInfoFilter = async (fetch, category, slugs) => {
  try {
    const url = `/settings/info-filter/${category}?slugs=${slugs}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.json();
  } catch (error) {
    return error;
  }
};

export const getContentSeo = async (fetch, slug, locale) => {
  try {
    const url = `/content-seo/slug/${slug}?locale=${locale}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.json();
  } catch (error) {
    return error;
  }
};
