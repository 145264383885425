import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import Link from '../Link';
// import history from '../../history';

const messages = defineMessages({
	title: {
		id: 'HomeCountries.title',
		defaultMessage: 'Countries available',
		description: 'title in HomeCountries',
	},
	subTitle: {
		id: 'HomeCountries.subTitle',
		defaultMessage: 'Discover the world, meet new people and develop your language skills.',
		description: 'subTitle in HomeCountries',
	},
	link: {
		id: 'HomeCountries.link',
		defaultMessage: 'Language stay {linkingWord} {location}',
		description: 'link in HomeCountries',
	},
});

class HomeCountries extends React.PureComponent {
	renderCountry = (country, index) => {
		const { locale, getUrlByLocale, getLocaleLink } = this.context;
		let col = 'col-md-6';
		if (index === 0 || index === 5) {
			col = 'col-md-8';
		} else if (index === 1 || index === 4) {
			col = 'col-md-4';
		} else if (index === 6 || index === 7 || index === 8) {
			col = 'col-md-4';
		}
		return (
			<div className={col} key={`hc-${country._id}`}>
				<div className="banner _h-33vh _br-3 banner-animate banner-animate-mask-in banner-animate-very-slow banner-animate-zoom-in">
					<div className="banner-bg" style={{ backgroundImage: `url('/img/countries/${country.slug}.jpg')` }}></div>
					<div className="banner-mask"></div>
					<Link
						className="banner-link"
						to={`/${getLocaleLink('search', locale)}/${country[`slug${getUrlByLocale(locale)}`]}`}
						hideLink
					>
						<div className="banner-caption _pt-100 banner-caption-bottom banner-caption-grad">
							<h4 className="banner-title">
								<FormattedMessage {...messages.link}
									values={{
										location: country[`name${getUrlByLocale(locale)}`],
										linkingWord: country[`linkingWord${getUrlByLocale(locale)}`],
									}} />
							</h4>
						</div>
					</Link>
				</div>
			</div>
		);
	}

	render() {
		const { countries } = this.context;
		return (
			<div className="theme-page-section theme-page-section-xxl theme-page-section-dark">
				<div className="container">
					<div className="theme-page-section-header theme-page-section-header-white">
						<h3 className="theme-page-section-title"><FormattedMessage {...messages.title} /></h3>
						<p className="theme-page-section-subtitle"><FormattedMessage {...messages.subTitle} /></p>
					</div>
					<div className="row row-col-gap" data-gutter="20">
						{countries?.filter((country) => country.isHome).map(
							(country, index) => this.renderCountry(country, index),
						)}
					</div>
				</div>
			</div>
		);
	}
}

HomeCountries.contextTypes = {
	locale: PropTypes.string,
	countries: PropTypes.array.isRequired,
	getLocaleLink: PropTypes.func.isRequired,
	getUrlByLocale: PropTypes.func.isRequired,
};

export default HomeCountries;
