import React from 'react';
import PropTypes from 'prop-types';
import history from '../../history';

function isLeftClickEvent(event) {
  return event.button === 0;
}

function isModifiedEvent(event) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

class Link extends React.PureComponent {
  handleClick = (event) => {
    if (this.props.onClick) {
      this.props.onClick(event);
    }

    if (isModifiedEvent(event) || !isLeftClickEvent(event)) {
      return;
    }

    if (event.defaultPrevented === true) {
      return;
    }

    event.preventDefault();
    history.push(this.props.to);
  };

  render() {
    const {
      to,
      title,
      children,
      hideLink,
      ...props
    } = this.props;

    if (hideLink) {
      return (
        <a href="" {...props} onClick={this.handleClick} title={title}>
          {children}
        </a>
      );
    }
    return (
      <a href={to} {...props} onClick={this.handleClick} title={title}>
        {children}
      </a>
    );
  }
}

Link.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  title: PropTypes.string,
  hideLink: PropTypes.bool,
};

Link.defaultProps = {
  onClick: null,
  title: null,
  hideLink: false,
};

export default Link;
