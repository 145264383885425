import React from 'react';
import PropTypes from 'prop-types';
import InfoBox from '../InfoBox/InfoBox';
import DetailsHeaderSection from '../DetailsHeaderSection/DetailsHeaderSection';
import redDetails from '../../data/redDetails';
import redDetailsFr from '../../data/redDetailsFr';
import redDetailsEs from '../../data/redDetailsEs';
import blueDetails from '../../data/blueDetails';
import blueDetailsFr from '../../data/blueDetailsFr';
import blueDetailsEs from '../../data/blueDetailsEs';


const DetailsSection = ({ isBlue, locale }) => {

    let detailsData = [];

    if (isBlue) {
        if (locale === 'en') {
            detailsData = blueDetails;
        } else if (locale === 'fr') {
            detailsData = blueDetailsFr;
        } else if (locale === 'es') {
            detailsData = blueDetailsEs;
        }

    } else {
        if (locale === 'en') {
            detailsData = redDetails;
        } else if (locale === 'fr') {
            detailsData = redDetailsFr;
        } else if (locale === 'es') {
            detailsData = redDetailsEs;
        }
    }


    return (
        <div className='detailsWrapper'>

            <div className='container detailsContainer'>

                <div className='detailsHeaderSection'>

                    <DetailsHeaderSection locale={locale} isBlue={isBlue} />

                </div>

                <div className='detailsGrid'>

                    {detailsData.length && detailsData.map((item, key) => {
                        return (

                            <InfoBox key={key} icon={item.icon} header={item.header} fullText={item.text} />
                        );
                    })}

                </div>


            </div>
        </div>)
};


DetailsSection.propTypes = {


    detailsData: PropTypes.array
};

DetailsSection.defaultProps = {
    detailsData: [],
};

DetailsSection.contextTypes = {
    header: PropTypes.any,
    paragraph: PropTypes.string,
};

export default DetailsSection;
