export const redDetailsFr = [

    {
        icon: '/img/infoBoxIcons/redIcons/profile-2user.svg',
        header: 'Faire des rencontres',
        text: `Un séjour linguistique est un excellent moyen de se faire des amis étrangers, en effet vous serez en immersion totale avec d'autres étudiants qui ne parlent pas votre langue. Vous pourrez échanger avec eux pendant les cours d'anglais, pendant les activités proposées l'après-midi ou le week-end, mais aussi dans le logement si vous la partagez.`
    },

    {
        icon: '/img/infoBoxIcons/redIcons/clipboard.svg',
        header: 'Apprentissage modulable à vos besoins',
        text: ` À la différence d'un cursus universitaire  vous pouvez commencer les cours à la date que vous souhaitez et en fonction de vos événements de vie. Les cours sont du lundi au vendredi et commencent chaque semaine. Un test en ligne vous sera demandé avant votre arrivée pour connaître votre niveau dans la langue. Vous pouvez aussi faire des cours business en rapport avec une profession ou des cours de préparation à un examen.`
    },

    {
        icon: '/img/infoBoxIcons/redIcons/award.svg',
        header: 'Résultats rapides',
        text: `Ils existent de nombreux moyens d'apprendre une langue comme utiliser des applications mobiles ou faire un stage à l'étranger. Mais le séjour linguistique est de loin le moyen le plus rapide, c'est pour ça que cela existe depuis plus de 40 ans.`
    },

    {
        icon: '/img/infoBoxIcons/redIcons/empty-wallet-tick.svg',
        header: 'Trouver un séjours linguistiques pas cher',
        text: `Worddy est un comparateur qui permet de trouver un voyage accordé à votre budget. Si vous cherchez quelques choses de pas cher avec des bons services, il va falloir opter pour des destinations ou des périodes moins demandés. Plus votre voyage linguistique sera long et moins le prix à la semaine sera élevé.`
    },

    {
        icon: '/img/infoBoxIcons/redIcons/discount-shape.svg',
        header: 'Promotions sur les séjours linguistiques',
        text: `Les prix des cours de langues et logement sont définis à l'avance sur les brochures de nos partenaires. Les brochures sont disponibles sur chacune de nos pages écoles.
        Il se peut que les écoles fassent des promotions pour les personnes qui réservent longtemps à l'avance ou alors en dernière minute.
        Nous avons créé une page afin de rassembler les promotions sur les séjours linguistiques.`
    },

    {
        icon: '/img/infoBoxIcons/redIcons/message-question.svg',
        header: 'Une équipe pour vous conseiller',
        text: `L'équipe de Worddy est basée à Malte, qui est historiquement la première destination que nous proposions. Notre équipe internationale a donc développé une expertise au fur et à mesure des années.
        Nous publions aussi des conseils et témoignage sur notre magazine en ligne sur nos différentes destinations. Nos agents sont disponibles sur le chat du site en bas à droite ou par rendez-vous téléphonique.`
    },

];

export default redDetailsFr;
