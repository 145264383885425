import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
	Pagination
} from 'swiper/core';
import Link from '../Link/Link';
import { defineMessages, FormattedMessage } from 'react-intl';
import { layout } from '../shareTranslation';
import { LazyLoadImage } from "react-lazy-load-image-component";

const messages = defineMessages({
	title: {
		id: 'CourseLearnSlider.title',
		defaultMessage: 'Find Your Course: Wide Selection by Type',
		description: 'title in CourseLearnSlider',
	},
	subTitle: {
		id: 'CourseLearnSlider.subTitle',
		defaultMessage: `Unlock your full potential with our diverse range of courses for all ages and goals. Explore our categories and find the perfect fit for you today!`,
		description: 'subTitle in CourseLearnSlider',
	},

});

SwiperCore.use([Pagination]);


const CourseLearnSlider = (props, context) => {
	const { locale, getUrlByLocale, getLocaleLink } = context;
	const { items } = props;
	const ref = useRef(0);

	return (
		<div className="courseLearnSliderWrapper">
			<div className="container courseLearnSliderContainer">
				<div className="courseLearnSliderText">
					<h2><FormattedMessage {...messages.title} /></h2>
					<p><FormattedMessage {...messages.subTitle} /></p>
				</div>
				<div className="courseLearnSliderArrows">
					<div className="prev" onClick={() => ref.current.querySelector(".swiper-container").swiper.slidePrev(250)}>
						 <LazyLoadImage src="/img/destination/redArrow.svg" alt="prev arrow" />
					</div>
					<div className="courseElementsWrapper">
						<div className="courseElementsContainer" ref={ref}>
							{
								items &&
								<Swiper
									slidesPerView={'auto'}
									loop={true}
									pagination={{
										"clickable": true
									}}
									breakpoints={{
										0: {
											spaceBetween: 12,
											slidesPerView: 1
										},
										768: {
											spaceBetween: 24,
											slidesPerView: 2
										},
										1200: {
											spaceBetween: 24,
											slidesPerView: 3
										},

									}}>
									{
										items.map((item, index) => (
											item.isActive &&
											<SwiperSlide
												key={`swiper-item-${index}`}>
												<div className="courseElementWrapper">
													<div className="courseElementContainer">
														<div className='courseImgWrapper'>
															 <img src={item.seoImage} />
														</div>
														<div className="courseElementText">
															{
																item[`name${getUrlByLocale(locale)}`] &&
																<h4>{item[`name${getUrlByLocale(locale)}`]}</h4>
															}
															{
																item[`description${getUrlByLocale(locale)}`] &&
																<p>{item[`description${getUrlByLocale(locale)}`]}</p>
															}
															<Link to={`/${getLocaleLink('searchCourseCategory', locale)}/${item[`slug${getUrlByLocale(locale)}`]}`}>
																<FormattedMessage {...layout.learnMore} children={msg => <>{msg}</>} />
															</Link>
														</div>
													</div>
												</div>
											</SwiperSlide>
										))
									}
								</Swiper>
							}
						</div>
					</div>
					<div className="next" onClick={() => ref.current.querySelector(".swiper-container").swiper.slideNext(250)}>
						 <LazyLoadImage src="/img/destination/redArrow.svg" alt="next arrow" />
					</div>
				</div>

			</div>
		</div>
	)
};


CourseLearnSlider.propTypes = {
	items: PropTypes.array,

};

CourseLearnSlider.contextTypes = {
	locale: PropTypes.string.isRequired,
	getUrlByLocale: PropTypes.func.isRequired,
	getLocaleLink: PropTypes.func.isRequired,
};

CourseLearnSlider.defaultProps = {
	items: [],
};


export default CourseLearnSlider;
