import React from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from "react-lazy-load-image-component";

// eslint-disable-next-line no-empty-pattern
const InfoBox = ({ icon, header, fullText }) => (
    <div className='infoBoxWrapper'>
        <div className='infoBoxContainer'>
             <LazyLoadImage src={icon} alt='icon' />
            <h3>{header}</h3>
            <p>{fullText}</p>
        </div>
    </div>
);


InfoBox.contextTypes = {
    icon: PropTypes.string,
    header: PropTypes.string,
    fullText: PropTypes.string

};

export default InfoBox;
