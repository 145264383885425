import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, IntlProvider } from 'react-intl';

const messages = defineMessages({
  week: {
    id: 'week.selector.week',
    defaultMessage: 'week',
    description: 'week label',
  },
  weeks: {
    id: 'week.selector.weeks',
    defaultMessage: 'weeks',
    description: 'weeks label',
  },
});

const WeekSelector = ({ onIncreaseDuration, onDecreaseDuration, duration }, { intl }) => (
  <div className="theme-search-results-select-number">
    <span>{duration}{' '}
      {duration <= 1 ? intl.formatMessage(messages.week) : intl.formatMessage(messages.weeks)}
    </span>
    <div className='duration-inc-dec-buttons'>
      <button className="btn btn-decrease" type="button" onClick={onDecreaseDuration}>-</button>
      <button className="btn" type="button" onClick={onIncreaseDuration}>+</button>
    </div>
  </div>
);

WeekSelector.propTypes = {
  onIncreaseDuration: PropTypes.func.isRequired,
  onDecreaseDuration: PropTypes.func.isRequired,
  duration: PropTypes.number.isRequired,
};

WeekSelector.contextTypes = {
  intl: IntlProvider.childContextTypes.intl,
};

export default WeekSelector;
