export const redDetails = [

    {
        icon: '/img/infoBoxIcons/redIcons/profile-2user.svg',
        header: 'Meet new people',
        text: 'A language trip is an excellent way to make new friends from all over the world, as you will be in total immersion with other students who do not speak your language. You will be able to exchange, interact with them during the English classes, during the activities offered in the afternoons or on weekends, but also in the accommodation if you live in a shared flat or in a host family.'
    },

    {
        icon: '/img/infoBoxIcons/redIcons/clipboard.svg',
        header: 'Modular learning to your needs',
        text: 'Unlike a university course, you can decide when you want to start the courses. The lessons are scheduled from Monday to Friday and start every week. An online test will be required before your arrival to find out what your current level in the language you would like to learn or improve. You can also do business courses related to a profession or exam preparation courses. Language schools always offer different type of courses to fit your needs.'
    },

    {
        icon: '/img/infoBoxIcons/redIcons/award.svg',
        header: 'Get Fast Results',
        text: "There are many ways to learn a language such as using mobile applications or doing an internship abroad. However, a language stay is by far the quickest and most effective way to reach your goal, that's why it has existed for over 40 years."
    },

    {
        icon: '/img/infoBoxIcons/redIcons/empty-wallet-tick.svg',
        header: 'Find a cheap language course',
        text: "Worddy is a comparator that allows you to find a language trip that fits your budget. If you're looking for something cheap with good service, you'll have to go for less popular destinations or dates."
    },

    {
        icon: '/img/infoBoxIcons/redIcons/discount-shape.svg',
        header: 'Promotions on language stays',
        text: "The prices of the language courses and accommodation are defined in advance on the brochures of our partners. The brochures are available on each school page. It is possible that the schools make promotions for people who book long in advance or at the last minute. We have created a page to collect information about special offers on language stay."
    },

    {
        icon: '/img/infoBoxIcons/redIcons/message-question.svg',
        header: 'A team to advise you',
        text: "Worddy's team is based in Malta, which is historically the first destination we offered. Our international team has therefore developed  a high level of expertise over the years and is able to guide to make sure you make the right choice. We also publish articles, tips, advice and testimonials on our online magazine and this on the different destinations we offer."
    },

];

export default redDetails;
