import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import Link from '../Link';

const messages = defineMessages({
  title: {
    id: 'BannerRegister.title',
    defaultMessage: 'Get access to our secret deals',
    description: 'title in BannerRegister',
  },
  subTitle: {
    id: 'BannerRegister.subTitle',
    defaultMessage: `Subscribe now and unlock our secret deals.
      Save up to 20% by getting access to our special offers for language trip experiences.`,
    description: 'subTitle in BannerRegister',
  },
  button: {
    id: 'BannerRegister.btnMore',
    defaultMessage: 'Unlock now',
    description: 'button in BannerRegister',
  },
});
// eslint-disable-next-line no-empty-pattern
const BannerRegister = ({}, { locale, getLocaleLink, user }) => (
  <div className="theme-hero-area">
    <div className="theme-hero-area-bg-wrap">
      <div className="theme-hero-area-bg" style={{ backgroundImage: 'url(\'/img/home/canvas.jpeg\')' }}></div>
      <div className="theme-hero-area-mask theme-hero-area-mask-half"></div>
    </div>
    <div className="theme-hero-area-body">
      <div className="theme-page-section _pv-100">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <div className="theme-hero-text theme-hero-text-white theme-hero-text-center">
                <div className="theme-hero-text-header">
                  <h2 className="theme-hero-text-title"><FormattedMessage {...messages.title} /></h2>
                </div>
                {!user && (
                  <>
                    <p className="theme-hero-text-subtitle"><FormattedMessage {...messages.subTitle} /></p>
                    <Link className="btn _tt-uc _mt-20 btn-white btn-ghost btn-lg" to={`/${getLocaleLink('register', locale)}`} hideLink>
                      <FormattedMessage {...messages.button} />
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

BannerRegister.contextTypes = {
  locale: PropTypes.string,
  getLocaleLink: PropTypes.func.isRequired,
  user: PropTypes.object,
};

export default BannerRegister;
