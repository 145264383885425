export const redDetailsEs = [

    {
        icon: '/img/infoBoxIcons/redIcons/profile-2user.svg',
        header: 'Conocer gente nueva',
        text: ` Una estancia lingüística es una excelente manera de hacer amigos extranjeros, ya que estarás en total inmersión con otros estudiantes que no hablan tu idioma. Podrás hablar con ellos durante las clases de inglés, durante las actividades que se ofrecen por la tarde o el fin de semana, pero también en el alojamiento si lo compartes.`
    },

    {
        icon: '/img/infoBoxIcons/redIcons/clipboard.svg',
        header: 'Aprendizaje que se adapta a sus necesidades',
        text: `A diferencia de un curso universitario, puedes empezar el curso en el momento que desees y según tus acontecimientos vitales. Las clases son de lunes a viernes y comienzan cada semana. Antes de llegar, se le pedirá que realice una prueba en línea para conocer su nivel de idioma. También puedes hacer cursos de negocios relacionados con una profesión o cursos de preparación de exámenes.`
    },

    {
        icon: '/img/infoBoxIcons/redIcons/award.svg',
        header: 'Resultados rápidos',
        text: `Hay muchas formas de aprender un idioma, como el uso de aplicaciones móviles o la realización de prácticas en el extranjero. Pero la estancia lingüística es, con mucho, la forma más rápida, por eso existe desde hace más de 40 años.`
    },

    {
        icon: '/img/infoBoxIcons/redIcons/empty-wallet-tick.svg',
        header: 'Encuentre un curso de idiomas barato',
        text: `Worddy es una herramienta de comparación que le permite encontrar un viaje que se ajuste a su presupuesto. Si busca algo barato con buenos servicios, tendrá que optar por destinos o periodos menos populares. Cuanto más largo sea su viaje lingüístico, más barato será el precio por semana.`
    },

    {
        icon: '/img/infoBoxIcons/redIcons/discount-shape.svg',
        header: 'Promociones en cursos de idiomas',
        text: `Los precios de los cursos de idiomas y del alojamiento están definidos de antemano en los folletos de nuestros socios. Los folletos están disponibles en cada una de las páginas de nuestras escuelas. Las escuelas pueden tener ofertas especiales para las personas que reservan con mucha antelación o en el último momento. Hemos creado una página para recoger todas las promociones sobre estancias lingüísticas.`
    },

    {
        icon: '/img/infoBoxIcons/redIcons/message-question.svg',
        header: 'Un equipo para asesorarle',
        text: `El equipo de Worddy tiene su sede en Malta, que fue históricamente el primer destino que ofrecimos. Por ello, nuestro equipo internacional ha desarrollado una experiencia a lo largo de los años. También publicamos en nuestra revista online consejos y testimonios sobre nuestros diferentes destinos. Nuestros agentes están disponibles en la sala de chat de la parte inferior derecha del sitio web o mediante cita telefónica.`
    },

];

export default redDetailsEs;
