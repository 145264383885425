export const blueDetailsEs = [

    {
        icon: '/img/infoBoxIcons/blueIcons/receipt-edit.svg',
        header: 'Transparencia de precios',
        text: `Worddy es el único sitio web que ofrece precios en tiempo real para las escuelas de idiomas en función de diez parámetros diferentes. Así podrá adaptar su paquete en función de la duración, la fecha de salida, el tipo de curso y el alojamiento. También puede incluir opciones como el traslado al aeropuerto y el seguro médico. Hay otras agencias de viajes lingüísticos, pero somos la única que muestra los precios sin tener que pedir un presupuesto. A diferencia de las agencias, no cobramos tasas adicionales ya que la relación y el pago se realizan directamente con la escuela de idiomas.`
    },

    {
        icon: '/img/infoBoxIcons/blueIcons/book.svg',
        header: 'Preparación para el TOEFL o el IELTS',
        text: `Si tienes que presentarte a estos exámenes, una estancia lingüística es una buena forma de prepararlos con 20 o 30 clases semanales.`
    },

    {
        icon: '/img/infoBoxIcons/blueIcons/buildings-2.svg',
        header: 'Amplia oferta de cursos de idiomas',
        text: `Worddy ofrece una amplia gama de cursos de idiomas, desde 10 lecciones semanales hasta 40 lecciones semanales. Puede elegir entre clases en grupo o privadas o una combinación de ambas. Los cursos en grupo permiten practicar el idioma con personas que no hablan francés. Por ello, son imprescindibles para repasar los fundamentos de la lengua extranjera.`
    },

    {
        icon: '/img/infoBoxIcons/blueIcons/slider.svg',
        header: 'Amplia gama de alojamientos',
        text: `Worddy ofrece en su página web unos 60 tipos de alojamiento diferentes que se adaptan a todos los presupuestos. En función de sus necesidades, puede elegir entre apartamentos compartidos, residencias de estudiantes o familias de acogida.`
    },

    {
        icon: '/img/infoBoxIcons/blueIcons/verify.svg',
        header: 'Socios certificados',
        text: `Worddy selecciona las mejores escuelas de idiomas según las certificaciones y opiniones de antiguos alumnos. Al final de cada estancia, el estudiante recibe un cuestionario que le permite comprobar la calidad de los cursos y del alojamiento.`
    },

    {
        icon: '/img/infoBoxIcons/blueIcons/tripIcon.svg',
        header: 'Precio de una estancia lingüística',
        text: `El coste de la estancia no es despreciable, los precios varían desde 300 euros hasta más de 1000 euros por semana. Debe considerarse como una inversión en su futuro. Hay que tener en cuenta el coste del viaje, el alojamiento, los cursos de idiomas, pero también otros gastos como el transporte, las comidas, las salidas... El objetivo es pasarlo bien, por lo que es importante informarse sobre el coste de la vida en la ciudad a la que se va a ir para estar cómodo con el presupuesto.`
    }

];

export default blueDetailsEs;
