import React, { memo } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

const messages = defineMessages({
  col1Title: {
    id: 'home.col1Title',
    defaultMessage: 'Explore the World',
    description: 'title col 1',
  },
  col1Desc: {
    id: 'home.col1Desc',
    defaultMessage: 'Over 2,500 language courses worldwide',
    description: 'desc col 1',
  },
  col2Title: {
    id: 'home.col2Title',
    defaultMessage: 'Best selection',
    description: 'title col 2',
  },
  col2Desc: {
    id: 'home.col2Desc',
    defaultMessage: 'We select the best partner schools',
    description: 'desc col 2',
  },
  col3Title: {
    id: 'home.col3Title',
    defaultMessage: 'Online Payment',
    description: 'title col 3',
  },
  col3Desc: {
    id: 'home.col3Desc',
    defaultMessage: 'by credit card via our partner Stripe or with Paypal',
    description: 'desc col 3',
  },
  col4Title: {
    id: 'home.col4Title',
    defaultMessage: 'Support',
    description: 'title col 4',
  },
  col4Desc: {
    id: 'home.col4Desc',
    defaultMessage: 'by chat or phone 7/7 for our client',
    description: 'desc col 4',
  },
});

const HomeReinsurance = memo(() => (
  <div className="theme-page-section theme-page-section-xl">
    <div className="container">
      <div className="row row-col-mob-gap" data-gutter="60">
        <div className="col-md-3 col-sm-6">
          <div className="feature feature-white feature-center">
            <i className="feature-icon feature-icon-primary feature-icon-box feature-icon-round fa fa-globe"></i>
            <div className="feature-caption">
              <h3 className="feature-title"><FormattedMessage {...messages.col1Title} /></h3>
              <p className="feature-subtitle"><FormattedMessage {...messages.col1Desc} /></p>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6">
          <div className="feature feature-white feature-center">
            <i className="feature-icon feature-icon-primary feature-icon-box feature-icon-round fas fa-star"></i>
            <div className="feature-caption">
              <h3 className="feature-title"><FormattedMessage {...messages.col2Title} /></h3>
              <p className="feature-subtitle"><FormattedMessage {...messages.col2Desc} /></p>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6">
          <div className="feature feature-white feature-center">
            <i className="feature-icon feature-icon-primary feature-icon-box feature-icon-round fa fa-credit-card"></i>
            <div className="feature-caption">
              <h3 className="feature-title"><FormattedMessage {...messages.col3Title} /></h3>
              <p className="feature-subtitle"><FormattedMessage {...messages.col3Desc} /></p>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6">
          <div className="feature feature-white feature-center">
            <i className="feature-icon feature-icon-primary feature-icon-box feature-icon-round fa fa-phone"></i>
            <div className="feature-caption">
              <h3 className="feature-title"><FormattedMessage {...messages.col4Title} /></h3>
              <p className="feature-subtitle"><FormattedMessage {...messages.col4Desc} /></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
));

export default HomeReinsurance;
