import React from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from "react-lazy-load-image-component";

const Icon = ({imgHeight, imgWidth, src, customizedCss}) => {
  
  return (
     <LazyLoadImage src={src} height={imgHeight} width={imgWidth} className={`${customizedCss ? customizedCss : ''}`} />
  );
};

Icon.propTypes = {
  imgHeight: PropTypes.number,
  imgWidth: PropTypes.number,
  src: PropTypes.string,
  customizedCss: PropTypes.string
}

export default Icon;