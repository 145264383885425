import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, IntlProvider } from 'react-intl';

const messages = defineMessages({
  ages: {
    id: 'age.selector.ages',
    defaultMessage: 'y/o',
    description: 'ages label',
  },
});

const AgeSelector = ({ onIncreaseAge, onDecreaseAge, age }, { intl }) => (
  <div className="theme-search-results-select-number">
    <span>{age}{' '}
      {intl.formatMessage(messages.ages)}
    </span>
    <div className='right-side'>
      <button className="btn btn-decrease" type="button" onClick={onDecreaseAge}>-</button>
      <button className="btn" type="button" onClick={onIncreaseAge}>+</button>
    </div>
  </div>
);

AgeSelector.propTypes = {
  onIncreaseAge: PropTypes.func.isRequired,
  onDecreaseAge: PropTypes.func.isRequired,
  age: PropTypes.number.isRequired,
};

AgeSelector.contextTypes = {
  intl: IntlProvider.childContextTypes.intl,
};


export default AgeSelector;
