import React from 'react';
import PropTypes from 'prop-types';
import { ProgressBar } from 'react-fetch-progressbar';
import Header from '../Header';
import Footer from '../Footer';

const pogressBarStyle = {
	backgroundColor: '#FA4359',
	position: 'fixed',
};

class Layout extends React.PureComponent {
	render() {
		const { children: { props } } = this.props;
		return (
			<>
				<ProgressBar style={pogressBarStyle} />
				<Header settings={props} />
				{this.props.children}
				<Footer settings={props} />
			</>
		);
	}
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout;
