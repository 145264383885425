import React from 'react';
import PropTypes from 'prop-types';
import HomeReinsurance from '../../components/HomeReinsurance';
// import RecommandedSchools from '../../components/RecommandedSchools';
// import RecommandedCities from '../../components/RecommandedCities';
import HomeCountries from '../../components/HomeCountries';
import RecommandedMagazine from '../../components/RecommandedMagazine';
import BannerHome from '../../components/BannerHome';
import BannerContentSEO from '../../components/BannerContentSEO';
import BannerRegister from '../../components/BannerRegister';
import BannerAbout from '../../components/BannerAbout';
import HomeVideo from '../../components/HomeVideo';
import Destinations from '../../components/Destinations/Destinations';
// import { schoolArrayType } from '../../types/school';

const HomeContent = ({ magazineCategories }, { locale }) => (
  <div className="_pos-r">
    <HomeReinsurance />
    <BannerAbout />
    <hr/>
    <HomeVideo locale={locale} />
    {/* <RecommandedSchools schools={schools} /> */}
    <BannerHome />
    <HomeCountries />
    <RecommandedMagazine magazineCategories={magazineCategories} />
    <BannerRegister />
    <BannerContentSEO locale={locale} />
  </div>
);

HomeContent.propTypes = {
  magazineCategories: PropTypes.array,
};

HomeContent.contextTypes = {
  locale: PropTypes.string,
};

HomeContent.defaultProps = {
  magazineCategories: [],
};

export default HomeContent;
