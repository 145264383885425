import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { Form } from 'react-final-form';
import queryString from 'query-string';
import WeekSelector from '../WeekSelector';
import AgeSelector from '../AgeSelector';
import DatePickerSearch from '../DatePickerSearch';
import history from '../../history';
import { bookingForm, searchForm } from '../shareTranslation';

class FormSearchBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterSearch: {
        // courseStartDate: moment().add(2, 'week').format('YYYY-MM-DD'),
        accommodations: [],
        countries: [],
        duration: 2,
        age: 18,
        noAccommodation: false,
      },
    };
  }

  componentDidMount() {
    const { accommodationCategories, filterSearch } = this.context;
    if (accommodationCategories
      && accommodationCategories.length
      && !filterSearch.accommodations.length
      && !filterSearch.noAccommodation) {
      filterSearch.accommodations = accommodationCategories
        .map((accommodationCategorie) => accommodationCategorie.slug);
    }
    this.setState({ filterSearch });
  }

  onDecreaseAge = () => {
    const { filterSearch } = this.state;
    if (filterSearch.age > 3) {
      filterSearch.age = parseInt(filterSearch.age, 10) - 1;
    } else {
      filterSearch.age = 1;
    }
    this.setState({ filterSearch });
  }

  onIncreaseAge = () => {
    const { filterSearch } = this.state;
    if (filterSearch.age >= 52) {
      filterSearch.age = 52;
    } else {
      filterSearch.age = parseInt(filterSearch.age, 10) + 1;
    }
    this.setState({ filterSearch });
  }

  onDecreaseDuration = () => {
    const { filterSearch } = this.state;
    if (filterSearch.duration > 1) {
      filterSearch.duration = parseInt(filterSearch.duration, 10) - 1;
    } else {
      filterSearch.duration = 1;
    }
    this.setState({ filterSearch });
  }

  onIncreaseDuration = () => {
    const { filterSearch } = this.state;
    if (filterSearch.duration >= 52) {
      filterSearch.duration = 52;
    } else {
      filterSearch.duration = parseInt(filterSearch.duration, 10) + 1;
    }
    this.setState({ filterSearch });
  }

  toggleSearchCountries = (country) => {
    const { filterSearch } = this.state;
    const filterNameIndex = filterSearch.countries
      .findIndex((index) => index === country.slug);
    if (filterNameIndex !== -1) {
      // delete
      filterSearch.countries.splice(filterNameIndex, 1);
    } else {
      // add
      filterSearch.countries.push(country.slug);
    }
    this.setState({ filterSearch });
  }

  toggleSearchAccommodations = (accommodation) => {
    const { filterSearch } = this.state;
    const filterNameIndex = filterSearch.accommodations
      .findIndex((index) => index === accommodation.slug);
    if (filterNameIndex !== -1) {
      // delete
      filterSearch.accommodations.splice(filterNameIndex, 1);
    } else {
      // add
      filterSearch.accommodations.push(accommodation.slug);
    }
    if (!filterSearch.accommodations.length) {
      filterSearch.noAccommodation = true;
    } else {
      filterSearch.noAccommodation = false;
    }
    this.setState({ filterSearch });
  }

  toggleSearchNoAccommodations = () => {
    const { filterSearch } = this.state;
    filterSearch.noAccommodation = !filterSearch.noAccommodation;
    if (filterSearch.noAccommodation) {
      filterSearch.accommodations = [];
    }
    this.setState({ filterSearch });
  }

  onChangeDateCourse = (date) => {
    const { filterSearch } = this.state;
    filterSearch.courseStartDate = moment(date).format('YYYY-MM-DD');
    this.setState({ filterSearch });
  }

  onSubmit = (data) => {
    const { locale, getLocaleLink } = this.context;
    const { filterSearch } = this.state;
    const finalObject = { ...data };
    if (filterSearch.courseStartDate) {
      finalObject.startDate = moment(filterSearch.courseStartDate).format('YYYY-MM-DD');
    }
    if (filterSearch.age) {
      finalObject.age = filterSearch.age;
    }
    if (filterSearch.duration) {
      finalObject.duration = filterSearch.duration;
    }
    if (filterSearch.countries && filterSearch.countries.length) {
      finalObject.countries = filterSearch.countries.join('.');
    }
    if (filterSearch.accommodations && filterSearch.accommodations.length) {
      finalObject.accommodations = filterSearch.accommodations.join('.');
      // finalObject.meals = ['no-meal'];
    } else {
      finalObject.noAccommodation = true;
      finalObject.accommodations = 'no-accommodation';
    }
    finalObject.lessons = "20.15";
    const newQuery = queryString.stringify(finalObject);
    history.push(`/${getLocaleLink('search', locale)}?${newQuery}`);
  }

  render() {
    const {
      accommodationCategories, countries, locale, getUrlByLocale,
      getLocaleLink,
    } = this.context;
    const { filterSearch } = this.state;

    // const activeBookingLanguages = languages && languages.filter(language => language.isActive);

    return (
      <Form
        onSubmit={this.onSubmit}
        render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} method="get" action={`/${getLocaleLink('search', locale)}`}>
        <div className="theme-search-area-form">
          <div className="theme-search-area-section first theme-search-area-section-curved theme-search-area-section-border-light">
            <label className="theme-search-area-section-label"><FormattedMessage {...searchForm.where} /></label>
              <div className="theme-search-area-section-accommodation">
                {countries && countries.filter((country) => country.isActive).map((country) => (
                  <label className="container-checkbox" key={country.slug} htmlFor={country.slug}>
                    {country[`name${getUrlByLocale(locale)}`]}
                      <input
                        id={country.slug}
                        type="checkbox"
                        onChange={() => this.toggleSearchCountries(country)}
                        checked={filterSearch.countries.includes(country.slug)}
                      />
                    <span className="checkmark"></span>
                  </label>
                ))}
              </div>
          </div>
          <div className="row" data-gutter="20">
            <div className="col-md-6 col-sm-6">
              <div className="theme-search-area-section theme-search-area-section-curved theme-search-area-section-border-light">
                <label className="theme-search-area-section-label"><FormattedMessage {...bookingForm.courseStartDate} /></label>
                <div className="theme-search-area-section-inner">
                  <i className="theme-search-area-section-icon lin lin-calendar"></i>
                    <DatePickerSearch
                      id="startDate"
                      initDate={moment(filterSearch.courseStartDate)}
                      inputId="course-start-date"
                      onChangeDate={this.onChangeDateCourse}
                      displayFormat="LL"
                    />
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="theme-search-area-section theme-search-area-section-curved theme-search-area-section-border-light">
                <label className="theme-search-area-section-label"><FormattedMessage {...bookingForm.duration} /></label>
                <div className="theme-search-area-section-inner">
                  <WeekSelector
                    id="duration"
                    onDecreaseDuration={this.onDecreaseDuration}
                    onIncreaseDuration={this.onIncreaseDuration}
                    duration={filterSearch.duration}
                  />
                </div>
            </div>
          </div>
          </div>
          <div className="row" data-gutter="20">
            {/*
            <div className="col-md-6 col-sm-6">
              <div className="theme-search-area-section first theme-search-area-section-curved theme-search-area-section-border-light">
                <label className="theme-search-area-section-label" htmlFor="language"><FormattedMessage {...searchForm.languageToLearn} /></label>
                  <div className="theme-search-area-section-inner">
                    <i className="theme-search-area-section-icon lin lin-speech"></i>
                    <div className="theme-search-results-select-filter">
                      <Field name="languages" component="select" id="language" title="select language">
                        {activeBookingLanguages && activeBookingLanguages.map(language => (
                          <option value={language._id} key={`lang-${language._id}`}>{language[`name${getUrlByLocale(locale)}`]}
                          </option>
                        ))}
                      </Field>
                    </div>
                  </div>
                </div>
            </div>
            */}
            <div className="col-md-6 col-sm-6">
              <div className="theme-search-area-section theme-search-area-section-curved theme-search-area-section-border-light">
                <label className="theme-search-area-section-label"><FormattedMessage {...bookingForm.age} /></label>
                <div className="theme-search-area-section-inner">
                  <AgeSelector
                    id="age"
                    onDecreaseAge={this.onDecreaseAge}
                    onIncreaseAge={this.onIncreaseAge}
                    age={filterSearch.age}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row" data-gutter="20">
            <div className="col-md-12">
              <label className="theme-search-area-section-label"><FormattedMessage {...searchForm.accommodation} /></label>
              <div className="theme-search-area-section-accommodation">
                {accommodationCategories && accommodationCategories.map((accommodation) => (
                  <label className="container-checkbox" key={accommodation.slug} htmlFor={accommodation.slug}>
                    {accommodation[`name${getUrlByLocale(locale)}`]}
                      <input
                        id={accommodation.slug}
                        type="checkbox"
                        onChange={() => this.toggleSearchAccommodations(accommodation)}
                        checked={filterSearch.accommodations.includes(accommodation.slug)}
                      />
                    <span className="checkmark"></span>
                  </label>
                ))}
              </div>
            </div>
            <div className="col-md-12 _ta-c _mt-5 _mb-10 acc-separator">
              <FormattedMessage {...bookingForm.or} />
            </div>
            <div className="col-md-12">
              <div className="theme-search-area-section-accommodation">
                <label className="container-checkbox" htmlFor="noAccommodation">
                  <FormattedMessage {...bookingForm.noAccommodation} />
                    <input
                      id="noAccommodation"
                      type="checkbox"
                      checked={filterSearch.noAccommodation}
                      onChange={() => this.toggleSearchNoAccommodations()}
                    />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
          <div className="row" data-gutter="20">
            <div className="col-md-6 ">
              <button
                className="theme-search-area-submit _mt-10 theme-search-area-submit-curved"
                type="submit"
                disabled={submitting}
              >
                <FormattedMessage {...searchForm.searchButton} />
              </button>
            </div>
          </div>
        </div>
      </form>
        )}
    />
    );
  }
}

FormSearchBlock.contextTypes = {
  countries: PropTypes.array,
  // languages: PropTypes.array,
  accommodationCategories: PropTypes.array,
  filterSearch: PropTypes.object,
  locale: PropTypes.string,
  getUrlByLocale: PropTypes.func.isRequired,
  getLocaleLink: PropTypes.func.isRequired,
};

export default FormSearchBlock;
