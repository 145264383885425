import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { hottestDeals } from '../shareTranslation';
import { FormattedMessage } from 'react-intl';
import { items } from '../../data/promotions'
import Link from '../Link/Link';

// const items = [
// 	{
// 		_id: '111222',
// 		isCountry: false,
// 		title: '1 week in <b>Malta</b>',
// 		titleFr: '1 week in <b>Malta</b>',
// 		titleEs: '1 week in <b>Malta</b>',
// 		description: '20 hrs of General English Accommodation included',
// 		descriptionFr: '20 hrs of General English Accommodation included',
// 		descriptionEs: '20 hrs of General English Accommodation included',
// 		price: 600,
// 		isHottestDeal: true,
// 		reductionAmountCourse: 30,
// 		startDateBookingReduction: "2020-07-26T08:17:19.000Z",
// 		endDateBookingReduction: "2023-08-31T08:17:21.000Z",
// 		school: {
// 			"currency": "GBP",
// 			"_id": "5d6d5620db85e900178dbc2e",
// 			"bookingTerms": "https://www.bayswater.ac/terms",
// 			"slugFr": "bayswater-college-londres",
// 			"logo": "https://res.cloudinary.com/worddy-co/image/upload/v1567445966/bdhyyctnnyhjhdvesg28.png",
// 			"name": "Bayswater College London",
// 			"nameFr": "Bayswater College Londres",
// 			"slug": "bayswater-college-london",
// 			"country": { "name": "England", "code": "GB", "nameFr": "Angleterre", "nameEs": "Inglaterra" },
// 			"city": { "nameFr": "Londres", "name": "London", "code": "LON", "nameEs": "Londres" },
// 			"rating": 4.6,
// 			"nameEs": "Bayswater College Londres",
// 			"slugEs": "bayswater-college-londres"
// 		},
// 		image: '/img/home/malta-promotion.png'
// 	}, {
// 		_id: '222333',
// 		isCountry: true,
// 		title: 'Meet Dubai',
// 		titleFr: 'Meet Dubai',
// 		titleEs: 'Meet Dubai',
// 		subTitle: 'Summer 2023',
// 		subTitleFr: 'Summer 2023',
// 		subTitleEs: 'Summer 2023',
// 		description: 'Improve your english and live an experience.',
// 		descriptionFr: 'Improve your english and live an experience.',
// 		descriptionEs: 'Improve your english and live an experience.',
// 		price: 1200,
// 		isHottestDeal: true,
// 		reductionAmountCourse: 40,
// 		startDateBookingReduction: "2020-07-26T08:17:19.000Z",
// 		endDateBookingReduction: "2023-08-31T08:17:21.000Z",
// 		school: {

// 			"currency": "GBP",
// 			"_id": "5d6d5620db85e900178dbc2e",
// 			"bookingTerms": "https://www.bayswater.ac/terms",
// 			"slugFr": "bayswater-college-londres",
// 			"logo": "https://res.cloudinary.com/worddy-co/image/upload/v1567445966/bdhyyctnnyhjhdvesg28.png",
// 			"name": "Bayswater College London",
// 			"nameFr": "Bayswater College Londres",
// 			"slug": "bayswater-college-london",
// 			"country": { "name": "England", "code": "GB", "nameFr": "Angleterre", "nameEs": "Inglaterra" },
// 			"city": { "nameFr": "Londres", "name": "London", "code": "LON", "nameEs": "Londres" },
// 			"rating": 4.6,
// 			"nameEs": "Bayswater College Londres",
// 			"slugEs": "bayswater-college-londres"
// 		},
// 		image: '/img/home/dubai-promotion.png'
// 	}
// ]

const PromoImages = ({ context }) => {
	const {
		locale, getUrlByLocale,
		getLocaleLink,
		displayPriceWithCurrency,
		currency,
		currencies,
	} = context;

	return (
		<section className='promo-images' style={{ display: 'none' }}>
			<div className='container'>
				<div className='items'>
					{
						items.map((deal, index) =>
							(() => {
								if (deal.type == 'country') {
									return (
										<div className="item" key={'promoImagesIsCountry' + index}>
											<Link to={deal[`slug${getUrlByLocale(locale)}`]}
												key={`promo-item-${deal._id}`}
												className={'item-wrapper country'}>
												<div className="content">
													<div className="top">
														<div className="title"
															dangerouslySetInnerHTML={{ __html: deal[`title${getUrlByLocale(locale)}`] }} />
														<div className="subtitle"
															dangerouslySetInnerHTML={{ __html: deal[`subTitle${getUrlByLocale(locale)}`] }} />
														<div className="desc">
															{deal[`description${getUrlByLocale(locale)}`]}
														</div>
													</div>
													<div className="details">
														<div className="discount">
															<p>{`${deal.reductionAmountCourse}%`} <span>OFF</span></p>
														</div>
														{/* <div className="price">
														<p className="former">
															{displayPriceWithCurrency(deal.price, deal.school.currency, currency, currencies, locale)}
														</p>
														<p className="new">
															{displayPriceWithCurrency(((deal.price * (100 - deal.reductionAmountCourse)) / 100), deal.school.currency, currency, currencies, locale)}
														</p>
													</div> */}
													</div>
													<div className="view-offer">
														<FormattedMessage {...hottestDeals.viewOffers} /> <img src="/img/icons/offer-arrow.svg" alt="offer-arrow" />
													</div>
												</div>
												<img src={deal.image} className={'bg-image'} />
											</Link>
										</div>
									)
								}
								if (deal.type == 'school') {
									return (
										<div className={'item'} key={'promoImagesIsNotCountry' + index}>
											<Link to={deal[`slug${getUrlByLocale(locale)}`]}
												key={`promo-item-${deal._id}`}
												className='item-wrapper school'>
												<div className="content">
													<div className="top">
														<div className="title"
															dangerouslySetInnerHTML={{ __html: deal[`title${getUrlByLocale(locale)}`] }} />
														<div className="desc">
															{deal[`description${getUrlByLocale(locale)}`]}
														</div>
													</div>
													<div className="details">
														<div className="school-details">
															<div className="date">
																{`${moment(deal.startDateBookingReduction).format('DD MMM')}-${moment(deal.endDateBookingReduction).format('DD MMM')} ${moment(deal.endDateBookingReduction).format('YYYY')}`}
															</div>
															{
																deal.school &&
																<div className="about-school">
																	<p>
																		<i className='fas fa-location-dot'></i>
																		{deal.school[`name${getUrlByLocale(locale)}`]}
																	</p>
																	<p>
																		<i className="fas fa-star"></i>
																		{Math.round(deal.school.rating * 100) / 100}/5
																	</p>
																</div>
															}
														</div>
														{
															deal.school &&
															<div className="price">
																<p className="former">
																	{displayPriceWithCurrency(deal.price, deal.school.currency, currency, currencies, locale)}
																</p>
																<p className="new">
																	{displayPriceWithCurrency(Math.round(((deal.price * (100 - deal.reductionAmountCourse)) / 100)), deal.school.currency, currency, currencies, locale)}
																</p>
															</div>
														}

													</div>
												</div>
												<img src={deal.image} className={'bg-image'} />
											</Link>
										</div>
									)
								}
							})()
						)
					}
				</div>
			</div>
		</section>
	)
};


PromoImages.propTypes = {

};

PromoImages.contextTypes = {
	locale: PropTypes.string.isRequired,
	getLocaleLink: PropTypes.func.isRequired,
	getUrlByLocale: PropTypes.func.isRequired,
	countries: PropTypes.array.isRequired,
	displayPriceWithCurrency: PropTypes.func.isRequired,
	currencies: PropTypes.array.isRequired,
	currency: PropTypes.string.isRequired,
};

export default PromoImages;


