import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import Link from '../Link';
// import history from '../../history';

const messages = defineMessages({
  title: {
    id: 'RecommandedMag.title',
    defaultMessage: 'Discover Worddy Magazine',
    description: 'title in RecommandedMag',
  },
  subTitle: {
    id: 'RecommandedMag.subTitle',
    defaultMessage: 'Find our student testimonials, travel tips, and tips for learning new languages',
    description: 'subTitle in RecommandedMag',
  },
  btnMore: {
    id: 'RecommandedMag.btnMore',
    defaultMessage: '+ More Stories',
    description: 'subTitle in RecommandedMag',
  },
});

class RecommandedMagazine extends React.PureComponent {
  _renderMagazineCategory = (category) => {
    const { locale, getUrlByLocale, isMobile } = this.context;
    let description = '';
    if (category[`description${getUrlByLocale(locale)}`]) {
      description = category[`description${getUrlByLocale(locale)}`].replace(/(<([^>]+)>)/ig, '');
      description = description.slice(0, 100);
    }
    let bgImageUrl = '';
    if (category.largePictureUrl) {
      if (isMobile) {
        bgImageUrl = category.largePictureUrl.replace('/image/upload/', '/image/upload/c_thumb,w_400/');
      } else {
        bgImageUrl = category.largePictureUrl.replace('/image/upload/', '/image/upload/c_thumb,w_700/');
      }
    }
    return (
      <div className="col-md-3 col-sm-6" key={`mcat-${category._id}`}>
        <div className="theme-blog-item _br-4 theme-blog-item-full">
          <Link
            to={`/${locale}/magazine/category/${category[`slug${getUrlByLocale(locale)}`]}`}
            hideLink
          >
            <div className="banner _h-45vh">
              <div className="banner-bg" style={{ backgroundImage: `url(${bgImageUrl})` }}></div>
              <div className="banner-caption banner-caption-bottom banner-caption-grad">
                <h3 className="theme-blog-item-title">{category[`title${getUrlByLocale(locale)}`]}</h3>
                <p className="theme-blog-item-desc" dangerouslySetInnerHTML={{ __html: description }} />
              </div>
            </div>
          </Link>
        </div>
      </div>
    );
  }

  render() {
    const { locale, getLocaleLink } = this.context;
    const { magazineCategories } = this.props;

    return (
      <div className="theme-page-section _pb-60 theme-page-section-lg">
        <div className="container">
          <div className="theme-page-section-header _ta-l">
            <h2 className="theme-page-section-title"><FormattedMessage {...messages.title} /></h2>
            <p className="theme-page-section-subtitle"><FormattedMessage {...messages.subTitle} /></p>
            <Link className="theme-page-section-header-link theme-page-section-header-link-rb"
              to={`/${getLocaleLink('magazine', locale)}`}
              hideLink
            >
              <FormattedMessage {...messages.btnMore} />
          </Link>
          </div>
          <div className="row row-col-gap" data-gutter="10">
            {magazineCategories.length &&
              magazineCategories.map((article) => this._renderMagazineCategory(article))}
          </div>
        </div>
      </div>
    );
  }
}

RecommandedMagazine.propTypes = {
  magazineCategories: PropTypes.array,
};

RecommandedMagazine.contextTypes = {
  locale: PropTypes.string,
  getLocaleLink: PropTypes.func.isRequired,
  getUrlByLocale: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

RecommandedMagazine.defaultProps = {
  magazineCategories: [],
};

export default RecommandedMagazine;
