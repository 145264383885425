import React from 'react';
import PropTypes, { bool } from 'prop-types';
import { detailsBlueText, detailsBlueTextEs, detailsBlueTextFr, detailsRedText, detailsRedTextEs, detailsRedTextFr } from '../../data/detailsHeaders';



const DetailsHeaderSection = ({ locale, isBlue }) => {

    let allText = [
        {
            header: "",
            text: "",
            colorHeader: "",
            headerRest: ""
        }
    ];

    if (isBlue) {
        if (locale === 'en') {
            allText = detailsBlueText;
        } else if (locale === 'fr') {
            allText = detailsBlueTextFr;
        } else if (locale === 'es') {
            allText = detailsBlueTextEs;
        }

    } else {
        if (locale === 'en') {
            allText = detailsRedText;
        } else if (locale === 'fr') {
            allText = detailsRedTextFr;
        } else if (locale === 'es') {
            allText = detailsRedTextEs;
        }
    }
    
    return (

        <div className='detailsHeaderSecWrapper'>

            <div className='detailsHeaderSecContainer'>
                <h2 className='headerBlack'>
                    {allText.header}
                    {(isBlue && (locale === 'en')) ? null : <br />}
                    <span className={isBlue ? 'blueSpan' : 'redSpan'}>
                        {allText.colorHeader}
                    </span>
                    <br />
                    {allText.headerRest}
                </h2>
                <p className='parBlack'>{allText.text}</p>

            </div>
        </div>
    )
};


DetailsHeaderSection.propTypes = {

    allText: PropTypes.object,
    isBlue: PropTypes.bool
};

export default DetailsHeaderSection;
