import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';

const DisplayVideo = ({ url, controls = true }) => (
    <div className="player-wrapper">
      <ReactPlayer
        className="react-player"
        url={url}
        width="100%"
        height="100%"
        controls={controls}
      />
    </div>
);

DisplayVideo.defaultProps = {
  controls: true,
};

DisplayVideo.propTypes = {
  url: PropTypes.string.isRequired,
  controls: PropTypes.bool,
};

export default DisplayVideo;
