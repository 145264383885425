import React from 'react';
import Home from './Home';
import Layout from '../../components/Layout';

async function action({ params, pathname, fetch }) {
  let currentLocale = params.locale;
  const canonials = [];
  if (!currentLocale) {
    currentLocale = pathname.split('/') && pathname.split('/')[1];
  }
  const slug = !pathname.split('/')[1] ? '' : '/en';
  const slugFr = '/fr';
  const slugEs = '/es';
  let magazineCategories = [];
  let title = 'Worddy - Find and book your language trip abroad';
  let description = 'Find your language school abroad and book online, '
  + 'the best price guaranteed ✅on schools and accommodations for all budgets.';
  if (currentLocale === 'fr') {
    title = "Worddy: Comparateur de séjour linguistique à l'étranger";
    description = 'Comparez les offres de séjour linguistique pour apprendre une langue à l\'étranger et '
    + 'reservez en ligne. Meilleur prix ✅sur les cours et les logements';
    canonials.push({ lang: 'fr', url: '/fr' });
  } else if (currentLocale === 'es') {
    title = 'Worddy: Comparador Escuelas Extranjero - Estudiar en el extranjero';
    description = 'Compare las ofertas de viajes de estudios de idiomas para aprender un idioma en el '
      + 'extranjero y reserve en línea. Mejor precio en cursos y alojamiento';
    canonials.push({ lang: 'es', url: '/es' });
  } else {
    canonials.push({ lang: 'en', url: '/en' });
  }

  const respMagazine = await fetch('/magazine/categories?limit=4&isHome=true', { method: 'GET' });
  magazineCategories = await respMagazine.json();
  // const resp = await fetch('/schools?limit=5&featured=true', { method: 'GET' });
  // schools = await resp.json();

  return {
    chunks: ['homepage'],
    title,
    description,
    slug,
    slugFr,
    slugEs,
    canonials,
    component: (
      <Layout>
        <Home
          slug={slug}
          slugFr={slugFr}
          slugEs={slugEs}
          magazineCategories={magazineCategories}
          isHome
        />
      </Layout>
    ),
  };
}

export default action;
