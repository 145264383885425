import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { Form } from 'react-final-form';
import queryString from 'query-string';
import WeekSelector from '../WeekSelector';
// import AgeSelector from '../AgeSelector';
import DatePickerSearch from '../DatePickerSearch';
import history from '../../history';
import { bookingForm, destinationPage, searchForm } from '../shareTranslation';
import Icon from '../Icon/Icon';
import { defineMessages, IntlProvider } from 'react-intl';
import { searchbar } from '../shareTranslation';

const messages = defineMessages({
  ages: {
    id: 'age.selector.ages',
    defaultMessage: 'y/o',
    description: 'ages label',
  },
  week: {
    id: 'week.selector.week',
    defaultMessage: 'week',
    description: 'week label',
  },
  weeks: {
    id: 'week.selector.weeks',
    defaultMessage: 'weeks',
    description: 'weeks label',
  },
});

const SearchBar = (props, context) => {
  const [filterSearch, setFilterSearch] = useState({ accommodations: [], countries: [], duration: 2, age: 18, noAccommodation: false, courseStartDate: moment().add(2, 'week').format('YYYY-MM-DD') });
  const [locationToggler, setLocationToggler] = useState(false);
  const [weekToggler, setWeekToggler] = useState(false);
  const [ageToggler, setAgeToggler] = useState(false);
  const [dateOpner, setDateOpener] = useState(false);
  const boxRef = useRef();
  const ageRef = useRef();
  const dateRef = useRef();

  //for pre selected countries. the one below line added by Emircan
  const [countriesNormal, setCountriesNormal] = useState(['malta', 'england']);

  const {
    accommodationCategories, countries, locale, getUrlByLocale,
    getLocaleLink, intl,
  } = context;

  useEffect(() => {
    const {  filterSearch } = context;
    //for pre selected countries. the one below line added by Emircan
    filterSearch.countries = ['malta', 'england'];
    
  }, [])
  

  useEffect(() => {
    const { accommodationCategories, filterSearch } = context;

    if (accommodationCategories
      && accommodationCategories.length
      && !filterSearch.accommodations.length
      && !filterSearch.noAccommodation) {
      filterSearch.accommodations = accommodationCategories
        .map((accommodationCategorie) => accommodationCategorie.slug);
    }
    setFilterSearch(filterSearch);
  }, [filterSearch.courseStartDate, dateOpner]);

  useEffect(() => {
    // Add event listener to document
    document.addEventListener('click', handleClickOutside);

    // Remove event listener when component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  function handleClickOutside(event) {
    // Check if click occurred outside of the target element
    if (boxRef.current && !boxRef.current.contains(event.target)) {
      setLocationToggler(false);
    }
    if (ageRef.current && !ageRef.current.contains(event.target)) {
      setAgeToggler(false);
    }
    if (dateRef.current && !dateRef.current.contains(event.target)) {
      setDateOpener(false);
    }
  }

  const onDecreaseDuration = () => {
    if (filterSearch.duration > 52) {
      filterSearch.duration = 52;
    } else if (filterSearch.duration > 1) {
      filterSearch.duration = parseInt(filterSearch.duration, 10) - 1;
    } else {
      filterSearch.duration = 1;
    }
    setFilterSearch({ filterSearch });
  }

  const onIncreaseDuration = () => {
    if (filterSearch.duration < 1) {
      filterSearch.duration = 1;
    } else if (filterSearch.duration >= 52) {
      filterSearch.duration = 52;
    } else {
      filterSearch.duration = parseInt(filterSearch.duration, 10) + 1;
    }
    setFilterSearch({ filterSearch });
  }

  const selectWeek = (param) => {
    const filterSearchTemp = filterSearch;
    filterSearchTemp.duration = param;
    setFilterSearch(filterSearchTemp);
    setWeekToggler(false);
  }

  const selectAge = (param) => {
    const filterSearchTemp = filterSearch;
    filterSearchTemp.age = param;
    setFilterSearch(filterSearchTemp);
    setAgeToggler(false);
  }

  const toggleSearchCountries = (country) => {
    const filterSearchTemp = filterSearch;
    const filterNameIndex = filterSearchTemp.countries
      .findIndex((index) => index === country.slug);
    if (filterNameIndex !== -1) {
      // delete
      filterSearchTemp.countries.splice(filterNameIndex, 1);
      countriesNormal.splice(filterNameIndex, 1);
    } else {
      // add
      filterSearchTemp.countries.push(country.slug);

      if (!countriesNormal.includes(country.slug)) {
        countriesNormal.push(country.slug);
      }
    }
    setFilterSearch(filterSearchTemp);
  }

  const toggleSearchAccommodations = (accommodation) => {
    const filterSearchTemp = filterSearch;
    const filterNameIndex = filterSearchTemp.accommodations
      .findIndex((index) => index === accommodation.slug);
    if (filterNameIndex !== -1) {
      // delete
      filterSearchTemp.accommodations.splice(filterNameIndex, 1);
    } else {
      // add
      filterSearchTemp.accommodations.push(accommodation.slug);
    }
    if (!filterSearchTemp.accommodations.length) {
      filterSearchTemp.noAccommodation = true;
    } else {
      filterSearchTemp.noAccommodation = false;
    }
    setFilterSearch(filterSearchTemp);
  }

  const toggleSearchNoAccommodations = () => {
    const filterSearchTemp = filterSearch;
    filterSearchTemp.noAccommodation = !filterSearchTemp.noAccommodation;
    if (filterSearchTemp.noAccommodation) {
      filterSearchTemp.accommodations = [];
    }
    setFilterSearch(filterSearchTemp);
  }

  const onChangeDateCourse = (date) => {
    const filterSearchTemp = filterSearch;
    filterSearchTemp.courseStartDate = moment(date).format('YYYY-MM-DD');
    setFilterSearch({ ...filterSearch, courseStartDate: moment(date).format('YYYY-MM-DD') });
  }

  const onSubmit = (data) => {
    const { locale, getLocaleLink } = context;
    const filterSearchTemp = filterSearch;
    const finalObject = { ...data };
    if (filterSearchTemp.courseStartDate) {
      finalObject.startDate = moment(filterSearchTemp.courseStartDate).format('YYYY-MM-DD');
    }
    if (filterSearchTemp.age) {
      finalObject.age = filterSearchTemp.age;
    }
    if (filterSearchTemp.duration) {
      finalObject.duration = filterSearchTemp.duration;
    }
    if (filterSearchTemp.countries && filterSearchTemp.countries.length) {
      finalObject.countries = filterSearchTemp.countries.join('.');
    }
    if (filterSearchTemp.accommodations && filterSearchTemp.accommodations.length) {
      finalObject.accommodations = filterSearchTemp.accommodations.join('.');
      // finalObject.meals = ['no-meal'];
    } else {
      finalObject.noAccommodation = true;
      finalObject.accommodations = 'no-accommodation';
    }
    finalObject.lessons = "20.15";
    const newQuery = queryString.stringify(finalObject);
    history.push(`/${getLocaleLink('search', locale)}?${newQuery}`);
  }

  const LocationModal = () => {
    return (
      <div className="searchbar-accomondation-select-parent">
        {filterSearch.countries && countries && countries.filter((country) => country.isActive).map((country) => (
          <div className='searchbar-location-parent' key={country.slug} >
            <label className="container-checkbox" key={country.slug} htmlFor={country.slug}>
              <input
                id={country.slug}
                type="checkbox"
                onChange={() => toggleSearchCountries(country)}
                defaultChecked={filterSearch.countries.includes(country.slug)}
              />
              <span className="checkmark-dest"></span>
            </label>
            <span className='searchbar-location-text' htmlFor={country.slug}>{country[`name${getUrlByLocale(locale)}`]}</span>
          </div>
        ))}
      </div>
    )
  }

  const WeekSelectorModal = () => {
    return (
      <div className='search-weekselector-parent'>
        {
          Array(52).fill(1).map((item, index) =>
            <p key={index} className='search-weekselector-item' onClick={() => selectWeek(item + index)}>{item + index}</p>
          )
        }
      </div>
    )
  }

  const AgeSelectorModal = () => {
    return (
      <div className='search-weekselector-parent'>
        {
          Array(98).fill(3).map((item, index) =>
            <p key={index} className='search-weekselector-item' onClick={() => selectAge(item + index)}>{item + index}</p>
          )
        }
      </div>
    )
  }

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} method="get" action={`/${getLocaleLink('search', locale)}`}>
          <div className='searchbar-main-parent'>
            <div className='searchbar-item-accomodation'>
              <div className="searchbar-accomondation-select-parent">
                <label className="container-checkbox" htmlFor="noAccommodation">
                  <input
                    id="noAccommodation"
                    type="checkbox"
                    onChange={() => toggleSearchNoAccommodations()}
                  />
                  <span className="checkmark-dest"></span>
                </label>
                <span className='searchbar-accomondation-select-text'>
                  <FormattedMessage {...bookingForm.noAccommodation} />
                </span>
              </div>
            </div>
            <div className='searchbar-elements-main'>
              <div className='searchbar-element-parent'>
                <div className='searchbar-element-left'>
                  <Icon imgHeight={40} imgWidth={40} src='/img/searchbar-location-icon.svg' />
                </div>
                <div className='searchbar-element-right' onClick={() => { setDateOpener(false); setAgeToggler(false) }} ref={boxRef}>
                  <p className='searchbar-element-right-text' onClick={() => setLocationToggler(!locationToggler)}>
                    <span type='button' className='searchbar-element-right-text-child'><FormattedMessage {...destinationPage.title} /></span>
                    <Icon imgHeight={20} imgWidth={20} src='/img/dropdown-date-icon.svg' />
                  </p>
                  <p className='searchbar-element-right-location-countries'>

                    {
                      countriesNormal.length > 0 ?
                      countries.filter(x => countriesNormal.includes(x.slug)).map(x => x[`name${getUrlByLocale(locale)}`]).join(', '):
                        <FormattedMessage {...searchbar.anyLocation} />
                    }
                  </p>
                  {
                    locationToggler &&
                    <>
                      <Icon imgHeight={10} imgWidth={23} src='/img/upcorner.svg' customizedCss={'location-box-arrow'} />
                      <div className='searchbar-element-location-modal' >
                        <LocationModal />
                      </div>
                    </>
                  }
                </div>
              </div>
              <div className='searchbar-element-parent'>
                <div className='searchbar-element-left'>
                  <Icon imgHeight={40} imgWidth={40} src='/img/searchbar-calendar-icon.svg' />
                </div>
                <div className='searchbar-element-right' onClick={() => { setLocationToggler(false); setAgeToggler(false) }} ref={dateRef}>
                  <p className='searchbar-element-right-text' onClick={() => setDateOpener(!dateOpner)}>
                    <span type='button' className='searchbar-element-right-text-child'>
                      <FormattedMessage {...bookingForm.courseStartDate} />
                    </span>
                    <span className='sort-by-select-icon' >
                      {/* <div className='date-side-for-arrow'>
                        {
                          <DatePickerSearch
                            initDate={moment(filterSearch.courseStartDate)}
                            inputId="course-start-date"
                            onChangeDate={onChangeDateCourse}
                          />
                        }
                      </div> */}
                      <Icon imgHeight={20} imgWidth={20} src='/img/dropdown-date-icon.svg' />
                    </span>
                  </p>
                  <DatePickerSearch
                    initDate={moment(filterSearch.courseStartDate)}
                    inputId="course-start-date"
                    onChangeDate={onChangeDateCourse}
                    parentFocused={dateOpner}
                  />
                </div>
              </div>
              <div className='searchbar-element-parent'>
                <div className='searchbar-element-left'>
                  <Icon imgHeight={40} imgWidth={40} src='/img/searchbar-duration-icon.svg' />
                </div>
                <div className='searchbar-element-right'>
                  <p className='searchbar-element-right-text' onClick={() => setWeekToggler(!weekToggler)}>
                    <span type='button' className='searchbar-element-right-text-child'>
                      <FormattedMessage {...bookingForm.duration} />
                    </span>
                  </p>

                  {/* <p className='searchbar-element-right-location'>{filterSearch.duration} {''}
                    {filterSearch.duration <= 1 ? intl.formatMessage(messages.week) : intl.formatMessage(messages.weeks)}
                  </p>
                  {
                    weekToggler &&
                    <div className='searchbar-element-week-modal'>
                      <WeekSelectorModal />
                    </div>
                  } */}
                  <div className='main-page-search-week-selector'>
                    <WeekSelector
                      onDecreaseDuration={onDecreaseDuration}
                      onIncreaseDuration={onIncreaseDuration}
                      duration={filterSearch.duration}
                    />
                  </div>
                </div>
              </div>
              <div className='searchbar-element-parent'>
                <div className='searchbar-element-left'>
                  <Icon imgHeight={40} imgWidth={40} src='/img/search-age-icon.svg' />
                </div>
                <div className='searchbar-element-right' onClick={() => { setLocationToggler(false); setDateOpener(false); }} ref={ageRef}>
                  <p className='searchbar-element-right-text' onClick={() => setAgeToggler(!ageToggler)}>
                    <span type='button' className='searchbar-element-right-text-child'>
                      <FormattedMessage {...bookingForm.age} />
                    </span>
                    <Icon imgHeight={20} imgWidth={20} src='/img/dropdown-date-icon.svg' />
                  </p>
                  <p className='searchbar-element-right-location'>{filterSearch.age} {intl.formatMessage(messages.ages)}</p>
                  {
                    ageToggler &&
                    <>
                      <Icon imgHeight={10} imgWidth={23} src='/img/upcorner.svg' customizedCss={'location-box-arrow'} />
                      <div className='searchbar-element-week-modal'>
                        <AgeSelectorModal />
                      </div>
                    </>
                  }
                </div>
              </div>
              <div className='searchbar-element-parent'>
                <button
                  className="searchbar-submit-button"
                  type="submit"
                  disabled={submitting}
                >
                  <Icon imgHeight={24} imgWidth={24} src='/img/searchbar-search-icon.svg' />
                  <span className='searchbar-submit-button-text'><FormattedMessage {...searchForm.searchButton} /></span>
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    />
  );
};

SearchBar.propTypes = {
  onDecreaseDuration: PropTypes.func,
  onIncreaseDuration: PropTypes.func,
}

SearchBar.contextTypes = {
  countries: PropTypes.array,
  // languages: PropTypes.array,
  accommodationCategories: PropTypes.array,
  filterSearch: PropTypes.object,
  locale: PropTypes.string,
  getUrlByLocale: PropTypes.func.isRequired,
  getLocaleLink: PropTypes.func.isRequired,
  intl: IntlProvider.childContextTypes.intl,
};

export default SearchBar;