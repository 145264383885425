import React from 'react';
import PropTypes from 'prop-types';
import Link from '../Link/Link';
import { accountMenu } from '../shareTranslation';
import { FormattedMessage } from 'react-intl';
import { LazyLoadImage } from "react-lazy-load-image-component";

const Destinations = ({ isHome, context }) => {
	const { locale, getUrlByLocale, getLocaleLink } = context;

	let { countries } = context;

	if (isHome) {
		countries = countries && countries.slice(0, 4);
	}

	return (
		<section className="destination-list">
			<div className="container">
				<div className='row'>
					<div className='col-lg-12'>
						<div className='head'>
							{
								isHome ?
									<>
										<h2 className='heading'>
											<FormattedMessage {...accountMenu.search} />
										</h2>
										<p>
											<FormattedMessage id="destinations.description" defaultMessage="Discover the world, meet new people and develop your language skills" />
										</p>
										<Link to={`/${locale}/destination`} className='dest-link'>
											<p><FormattedMessage id="destinations.seeAll" defaultMessage="See all destinations" /></p>
											 <LazyLoadImage src='/img/destination/redArrow.svg' alt='arrow icon' />
										</Link>
									</> :
									<>
										<h1 className='heading'><FormattedMessage {...accountMenu.search} /></h1>
										<p>
											<FormattedMessage id="destinations.description" defaultMessage="Discover the world, meet new people and develop your language skills" />
										</p>
									</>
							}

						</div>
					</div>
				</div>
				{
					countries &&
					<div className='items'>
						{
							countries.map((country, index) => {
								return <Link className='destination-item'
									to={`/${getLocaleLink('destination', locale)}/${country[`slug${getUrlByLocale(locale)}`]}`} hideLink
									style={{ backgroundImage: `url('/img/countries/${country.slug}.jpg')` }}
									key={`hc-${country._id}`}>
									<div className='title'>{country[`name${getUrlByLocale(locale)}`]}</div>
								</Link>
							})
						}
					</div>
				}
			</div>
		</section>

	);
}


Destinations.contextTypes = {
	locale: PropTypes.string.isRequired,
	getLocaleLink: PropTypes.func.isRequired,
	countries: PropTypes.array.isRequired,
	getUrlByLocale: PropTypes.func.isRequired,
};


export default Destinations;
