import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import TextLayout from '../TextLayout/TextLayout';
import { LazyLoadImage } from "react-lazy-load-image-component";

const messages = defineMessages({
	title: {
		id: 'BannerHome.title',
		defaultMessage: 'Total Language Immersion: All-Inclusive Course Packages.',
		description: 'title in BannerHome',
	},
	subTitle: {
		id: 'BannerHome.subTitle',
		defaultMessage: `Get our lowest prices on courses and accommodations,
        when you bundle it all in a single booking. Use our trip builder to spend less
        time planning and more time traveling and learning.`,
		description: 'subTitle in BannerHome',
	},
	button: {
		id: 'BannerHome.btnMore',
		defaultMessage: 'Find my language trip',
		description: 'button in BannerHome',
	},
});

// eslint-disable-next-line no-empty-pattern
const DealSection = ({ }) => (
	<section className='dealSecWrapper'>
		<div className='container'>
			<div className='dealSecContainer'>
				 <LazyLoadImage src='/img/home/cover_languageOpt.png' alt='ultimate deal' />
				<TextLayout
					header={<FormattedMessage {...messages.title} children={msg => <>{msg}</>} />}
					text={<FormattedMessage {...messages.subTitle} children={msg => <>{msg}</>} />}
					buttonText={<FormattedMessage {...messages.button} />}
					buttonLink=""
					isWhite={false}
					isRow={false}
				/>
			</div>
		</div>
	</section>
);


DealSection.propTypes = {

};

export default DealSection;
