import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import { IntlProvider } from 'react-intl';
import { bookingForm } from '../shareTranslation';
import { getLatestSeason } from '../../utils/api';
// import 'react-dates/lib/css/_datepicker.css';

// const InfoPanel = ({ text }) => (<div className="date-piker-info" role="img">ℹ️ {text}</div>);
const InfoPanel = ({ text }) => (<div className="date-piker-info" role="img">{text}</div>);

InfoPanel.propTypes = {
  text: PropTypes.string.isRequired,
};

const DatePickerSearch = ({
  initDate,
  onChangeDate,
  inputId,
  displayFormat,
  openDirection,
  parentFocused
}, { intl, locale, fetch }) => {
  const minDelayDateWeek = 2;
  const [endDate, setEnddate] = useState();
  const [focusedStatus, setFocused] = useState(false);
  const currentDate = moment(initDate).locale(locale);
  if (locale) {
    moment.locale(locale);
  }

  useEffect(async () => {
    const season = await getLatestSeason(fetch);
    if (season && season.endDate) {
      const date = new Date(season.endDate);
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      let dt = date.getDate();

      if (dt < 10) {
        dt = '0' + dt;
      }
      if (month < 10) {
        month = '0' + month;
      }

      await setEnddate(year + '-' + month + '-' + dt)
    }

  }, [])

  const defineIfisOutsideRange = (day) => {
    if (inputId === 'course-online-start-date') {
      return day.isAfter(endDate ? endDate : '2025-01-30') || day.isBefore(moment().add(1 + minDelayDateWeek, 'weeks'));
    }
    return day.isAfter(endDate ? endDate : '2025-01-30') || day.isBefore(moment().add(1 + minDelayDateWeek, 'weeks'));
  };

  const findIfDayBlocked = (day) => {
    if (inputId === 'course-start-date' || inputId === 'course-online-start-date') {
      return day.day() !== 1;
    }
    if (inputId === 'accommodation-checkin') {
      const diff = day.diff(initDate, 'days');
      return ((diff > 3 || diff < -3) || (day.day() !== 0 && day.day() !== 1 && day.day() !== 6));
    }
    if (inputId === 'accommodation-checkout') {
      const diff = day.diff(initDate, 'days');
      return ((diff > 3 || diff < -3) || (day.day() !== 0 && day.day() !== 5 && day.day() !== 6));
    }
    return false;
  };

  return (
    <SingleDatePicker
      noBorder
      numberOfMonths={1}
      hideKeyboardShortcutsPanel
      isDayBlocked={(day) => findIfDayBlocked(day)}
      date={currentDate}
      onDateChange={onChangeDate} // PropTypes.func.isRequired
      focused={focusedStatus || parentFocused} // PropTypes.bool
      onFocusChange={({ focused }) => setFocused(focused)}
      id={inputId} // PropTypes.string.isRequired,
      displayFormat={displayFormat}
      openDirection={openDirection}
      isOutsideRange={(day) => defineIfisOutsideRange(day)}
      readOnly
      renderCalendarInfo={() => {
        if (inputId === 'course-start-date') {
          return (
            <InfoPanel text={intl.formatMessage(bookingForm.calendarCourseInfo)} />
          );
        }
        if (inputId === 'course-online-start-date') {
          return (
            <InfoPanel text={intl.formatMessage(bookingForm.calendarCourseOnlineInfo)} />
          );
        }
        if (inputId === 'accommodation-checkin') {
          return (
            <InfoPanel text={intl.formatMessage(bookingForm.calendarCheckInInfo)} />
          );
        }
        if (inputId === 'accommodation-checkout') {
          return (
            <InfoPanel text={intl.formatMessage(bookingForm.calendarCheckOutInfo)} />
          );
        }
        return null;
      }}
    />
  );
};

DatePickerSearch.propTypes = {
  initDate: PropTypes.instanceOf(moment),
  onChangeDate: PropTypes.func.isRequired,
  inputId: PropTypes.string.isRequired,
  displayFormat: PropTypes.string,
  openDirection: PropTypes.string,
  focusedForParent: PropTypes.func,
};

DatePickerSearch.contextTypes = {
  locale: PropTypes.string,
  intl: IntlProvider.childContextTypes.intl,
  fetch: PropTypes.func.isRequired,
};

DatePickerSearch.defaultProps = {
  initDate: moment(),
  displayFormat: 'dddd LL',
  openDirection: 'down',
};

export default DatePickerSearch;
