import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Link from '../Link';
import { accountMenu, layout } from '../shareTranslation';
import { FormattedMessage } from 'react-intl';
import { getFaq } from '../../utils/api';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import { categories } from '../../data/courses';
import { waitForFinalEvent } from '../../utils/helper';
import { updateProfile, updateToken } from '../../utils/apiCalls';
import { getPromotionTimerInfo } from '../../utils/api';
import Cookies from 'js-cookie';
import HeaderPromoTimer from '../HeaderPromoTimer/HeaderPromoTimer';
import { headerPromo, headerButtons } from '../shareTranslation';
import { LazyLoadImage } from "react-lazy-load-image-component";

SwiperCore.use([
	Navigation
]);

const Header = ({ settings }, context) => {
	const {
		navbar,
		isHidden,
		slug,
		slugFr,
		slugEs,
		isHome,
	} = settings;
	const {
		languages,
		currencies,
		pathname,
		query,
		locale,
		getUrlByLocale,
		faq,
		getLocaleLink,
		user,
		currency,
		countries,
		country,
		fetch
	} = context;
	let { magazineCategories } = context;
	magazineCategories = magazineCategories.filter(x => x[`numArticles${getUrlByLocale(locale)}`] > 0)
	const isPromotion = true;

	const hasUserCookie = Cookies.get('token') !== undefined;

	const [classNames, setClassNames] = useState();
	const [isMobileMenuActive, setIsMobileMenuActive] = useState(false);
	const [activeMenuIds, setActiveMenuIds] = useState([]);
	const [isMobile, setIsMobile] = useState(false);
	const [promotionActive, setPromotionActive] = useState(false);
	const activeLanguages = languages && languages.filter((language) => language.isWebsiteActive);
	const activeLanguage = activeLanguages && activeLanguages.find((language) => language.code == locale);
	const [currentCurrency, setCurrentCurrency] = useState();
	const [promoTimerData, setPromoTimerData] = useState({});
	const [profileImageUrl, setProfileImageUrl] = useState(() => {
		if (typeof localStorage !== 'undefined') {
			return localStorage.profileImageUrl || '';
		} else {
			return '';
		}
	});

	const headerMenuRef = useRef();
	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('scroll', handleScroll)
			window.removeEventListener('resize', handleResize)
		}
	});

	useEffect(() => {
		let vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);

		const fetchPromoTimerData = async () => {
			const promoTimerApiData = await getPromotionTimerInfo(fetch);
			setPromoTimerData(promoTimerApiData)
		}

		fetchPromoTimerData();
	}, []);

	useEffect(() => {

		if (Cookies.get('show_promotion') === 'false' || promoTimerData === null) {
			setPromotionActive(false);
		} else {
			setPromotionActive(true);
		}

	}, [promoTimerData])


	useEffect(async () => {
		//setPromotionActive(Cookies.get('show_promotion') === 'false' ? false : true)
		setIsMobile(window.innerWidth < 992);
		setCurrentCurrency(user && user.currency ? user.currency : Cookies.get('currency'));
	}, []);

	useEffect(() => {
		setIsMobileMenuActive(false);
		setActiveMenuIds([]);
	}, [pathname, query]);

	useEffect(async () => {
		if (!isMobile) {
			setActiveMenuIds([]);
			setIsMobileMenuActive(false)
		}
		else {
			if (headerMenuRef.current) {
				headerMenuRef.current.style.height = `calc(var(--vh, 1vh) * 100 - ${headerMenuRef.current.getBoundingClientRect().top}px)`;
			}
		}
	}, [isMobile]);

	useEffect(() => {
		if (!isMobile) {
			if (activeMenuIds.length > 0) {
				setClassNames((""))
			}
			else {
				setClassNames((navbar ? navbar : ""))
			}
		}
	}, [activeMenuIds]);

	useEffect(() => {
		const documentElement = document.documentElement;
		const scrollWidth = window.innerWidth - document.documentElement.clientWidth;
		if (isMobileMenuActive) {
			documentElement.style.marginLeft = `0`;
			documentElement.style.marginRight = `${scrollWidth}px`;
			documentElement.style.overflow = `hidden`;
			setClassNames((""))
		}
		else {
			documentElement.style.marginLeft = ``;
			documentElement.style.marginRight = ``;
			documentElement.style.overflow = ``;
			setClassNames((navbar ? navbar : ""))
		}
	}, [isMobileMenuActive]);

	useEffect(() => {
		setClassNames((navbar ? navbar : ""))
	}, [slug]);

	useEffect(() => {
		if (headerMenuRef.current) {
			if (window.innerWidth < 992) {
				headerMenuRef.current.style.height = `calc(var(--vh, 1vh) * 100 - ${headerMenuRef.current.getBoundingClientRect().top}px)`;
			}
			else {
				headerMenuRef.current.style.height = ``;
			}
		}
	}, [promotionActive])

	const handleResize = () => {
		waitForFinalEvent(function () {
			if (window) {
				setIsMobile(window.innerWidth < 992);
				let vh = window.innerHeight * 0.01;
				document.documentElement.style.setProperty('--vh', `${vh}px`);

				if (headerMenuRef.current) {
					if (window.innerWidth < 992) {
						headerMenuRef.current.style.height = `calc(var(--vh, 1vh) * 100 - ${headerMenuRef.current.getBoundingClientRect().top}px)`;
					}
					else {
						headerMenuRef.current.style.height = ``;
					}
				}
			}
		}, 250, "window resize");
	}

	const handleScroll = () => {
		waitForFinalEvent(function () {
			if (isMobile) {
				if (window) {
					if (headerMenuRef.current) {
						headerMenuRef.current.style.height = `calc(var(--vh, 1vh) * 100 - ${headerMenuRef.current.getBoundingClientRect().top}px)`;
					}
				}
			}
		}, 250, "window scroll");
	}

	const changeUserCurrency = async (e, newCurrency) => {
		if (newCurrency.code == currentCurrency) return true

		if (user && user.currency) {
			await updateProfile(fetch, user.id, { currency: newCurrency.code });
			await updateToken(fetch, locale);
			// setCurrentCurrency(newCurrency.code);
		} else {
			Cookies.set('currency', newCurrency.code);
			// setCurrentCurrency(newCurrency.code);
		}
		return window.location.reload(true);
	}

	const handleOpenMenu = async (menuId) => {
		if (isMobile) {
			if (!activeMenuIds.includes(menuId)) {
				await setActiveMenuIds(state => [...state, menuId]);
			}
			else {
				if (activeMenuIds.length > 0) {
					const newArray = activeMenuIds.filter(item => item !== menuId)
					await setActiveMenuIds([...newArray]);
				}
			}
		}
	}

	return (
		!isHidden ?
			<>
				{/* {
					(isHome && isPromotion && promotionActive && promoTimerData && promoTimerData.endDateBookingReduction !== '') &&
					<div className="header-promotion">
						<div className="container">
							<div className="row">
								<div className="col-lg-12">
									<div className="promotion">
										<div className="headline">
											<h4>{promoTimerData[`title${getUrlByLocale(locale)}`]}</h4>
										</div>
										<div className='details'>
											<div className="time-left">
												<div className="icon">
													<LazyLoadImage src='/img/header/sun-icon.png' alt='Days Left' />
												</div>
												<HeaderPromoTimer setPromotionActive={setPromotionActive} promoTimerData={promoTimerData} />
											</div>
											<div className="cta">
												<Link to={`/${getLocaleLink('school', locale)}/${promoTimerData[`schoolSlug${getUrlByLocale(locale)}`]}`}>
													<FormattedMessage {...headerPromo.selectCourseButton} />
												</Link>
											</div>
											<LazyLoadImage onClick={() => { Cookies.set('show_promotion', false), setPromotionActive(false) }} className='close' src='/img/header/close.svg' alt='Close' />
										</div>
										<LazyLoadImage className='close-button-for-mobile' onClick={() => { Cookies.set('show_promotion', false), setPromotionActive(false) }} src='/img/header/close.svg' alt='Close' />
									</div>
								</div>
							</div>
						</div>
					</div>
				} */}
				<header id='header' className={classNames}>
					<div className='container'>
						<div className='wrapper'>
							<div className={('toggler') + (isMobileMenuActive ? " active" : "")} onClick={() => setIsMobileMenuActive((state) => { return !state })}>
								<span></span>
								<span></span>
								<span></span>
							</div>
							<Link className='logo' to={`/${locale}`}>
								<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 101 28" alt={'Worddy.co logo'}>
									<g>
										<path d="M29.2495 13.9978C29.1977 15.2318 29.627 16.4386 30.4494 17.3717C31.3245 18.1458 32.4639 18.567 33.6405 18.5515C34.8172 18.536 35.9447 18.0849 36.7983 17.288C37.5684 16.4468 37.9132 15.35 37.9132 13.9141C37.9444 13.3432 37.8563 12.7721 37.6543 12.2361C37.4522 11.7 37.1406 11.2105 36.7386 10.7976C36.3365 10.3848 35.8527 10.0575 35.317 9.83592C34.7812 9.61436 34.205 9.50327 33.6239 9.50952C33.0348 9.49418 32.4489 9.59852 31.9027 9.81602C31.3565 10.0335 30.8618 10.3595 30.4494 10.7736C29.6508 11.6612 29.2223 12.8127 29.2495 13.9978ZM25.5646 13.9978C25.547 12.9831 25.7516 11.9766 26.1646 11.0467C26.5477 10.0681 27.1314 9.17752 27.8794 8.43039C28.5982 7.68742 29.4761 7.11118 30.4494 6.74344C31.4204 6.30945 32.4724 6.07847 33.5388 6.06513C34.5662 6.09077 35.5813 6.29198 36.5386 6.65975C37.4926 7.02827 38.3664 7.57241 39.1131 8.26302C39.9349 8.98254 40.5808 9.8754 41.0025 10.8749C41.4314 11.8339 41.6642 12.8666 41.6876 13.9141C41.6767 15.009 41.4738 16.0938 41.0876 17.1207C40.709 18.0781 40.1222 18.9425 39.3683 19.6533C38.6726 20.4403 37.7897 21.0456 36.7983 21.4151C35.7637 21.8272 34.6547 22.028 33.5388 22.0053C32.4779 22.0282 31.4245 21.827 30.4494 21.4151C29.4647 20.9858 28.5645 20.3895 27.7899 19.6533C27.1046 18.8725 26.5526 17.9874 26.1557 17.0326C25.7386 16.0747 25.537 15.0395 25.5646 13.9978Z" fill="#010B27" />
										<path d="M50.9871 9.35536C47.9872 9.61083 47.2127 10.8793 47.2127 13.6586V21.4372H43.6979V13.8304C43.6979 9.61083 46.1829 6.06513 50.9871 6.06513V9.35536Z" fill="#010B27" />
										<path d="M64.2624 -5.72205e-06V7.07818C62.927 6.22683 61.3761 5.75996 59.785 5.73037C58.204 5.71728 56.6547 6.16658 55.3337 7.02124C54.0128 7.8759 52.9797 9.09737 52.3656 10.5306C51.7516 11.9639 51.5842 13.5443 51.8848 15.0713C52.1854 16.5983 52.9404 18.0031 54.0539 19.1072C55.1675 20.2114 56.5894 20.9651 58.1392 21.2729C59.689 21.5807 61.2968 21.4285 62.7586 20.8357C64.2203 20.243 65.4701 19.2364 66.3493 17.9436C67.2284 16.6509 67.6974 15.1303 67.6965 13.575V-5.72205e-06H64.2624ZM59.7358 17.9663C59.1185 18.0173 58.4971 17.9416 57.911 17.7442C57.3249 17.5467 56.7868 17.2318 56.3308 16.8192C55.8748 16.4067 55.5108 15.9055 55.2617 15.3475C55.0127 14.7894 54.8842 14.1866 54.8842 13.5772C54.8842 12.9677 55.0127 12.3649 55.2617 11.8068C55.5108 11.2488 55.8748 10.7476 56.3308 10.3351C56.7868 9.92253 57.3249 9.60758 57.911 9.41014C58.4971 9.21269 59.1185 9.13704 59.7358 9.18798C60.8546 9.28031 61.8972 9.78272 62.6572 10.5957C63.4171 11.4086 63.8389 12.4727 63.8389 13.5772C63.8389 14.6816 63.4171 15.7457 62.6572 16.5587C61.8972 17.3716 60.8546 17.874 59.7358 17.9663Z" fill="#010B27" />
										<path d="M82.1226 -5.72205e-06V7.07818C80.7871 6.22682 79.2362 5.75996 77.6452 5.73037C76.0644 5.71816 74.5155 6.1682 73.1951 7.02339C71.8747 7.87858 70.8423 9.10038 70.2289 10.5337C69.6156 11.9671 69.4488 13.5474 69.7499 15.0741C70.0511 16.6008 70.8064 18.0051 71.9202 19.1088C73.0339 20.2125 74.4558 20.9659 76.0055 21.2732C77.5552 21.5806 79.1628 21.4282 80.6243 20.8353C82.0857 20.2424 83.3353 19.2358 84.2142 17.9432C85.0932 16.6505 85.562 15.1301 85.5612 13.575V-5.72205e-06H82.1226ZM77.6004 17.9663C76.9831 18.0173 76.3617 17.9416 75.7756 17.7442C75.1895 17.5467 74.6514 17.2318 74.1954 16.8192C73.7394 16.4067 73.3754 15.9055 73.1264 15.3475C72.8774 14.7894 72.7488 14.1866 72.7488 13.5772C72.7488 12.9677 72.8774 12.3649 73.1264 11.8068C73.3754 11.2488 73.7394 10.7476 74.1954 10.3351C74.6514 9.92253 75.1895 9.60758 75.7756 9.41013C76.3617 9.21269 76.9831 9.13704 77.6004 9.18798C78.7192 9.2803 79.7618 9.78272 80.5218 10.5957C81.2817 11.4086 81.7035 12.4727 81.7035 13.5772C81.7035 14.6816 81.2817 15.7457 80.5218 16.5587C79.7618 17.3716 78.7192 17.874 77.6004 17.9663Z" fill="#010B27" />
										<path d="M13.552 6.06513V14.8303C13.552 15.6807 13.8954 16.4963 14.5066 17.0976C15.1179 17.699 15.947 18.0368 16.8115 18.0368C17.6626 18.0195 18.4727 17.6735 19.0666 17.0735C19.6606 16.4735 19.9908 15.6677 19.9859 14.8303V6.06513H23.5678V14.7598C23.5666 16.5251 22.8554 18.2181 21.5895 19.4693C20.3236 20.7204 18.6059 21.4278 16.8115 21.4372C15.8776 21.4359 14.9539 21.2469 14.097 20.8818C13.2401 20.5168 12.468 19.9833 11.8282 19.3141C10.4935 20.6496 8.6768 21.4126 6.77325 21.4372C4.97806 21.4325 3.25776 20.7289 1.98837 19.4801C0.718977 18.2314 0.00375074 16.539 -0.000976562 14.773V6.06513H3.5809V14.8303C3.57483 15.6673 3.90405 16.4731 4.4973 17.0732C5.09055 17.6733 5.90013 18.0195 6.75086 18.0368C7.18133 18.0409 7.60834 17.9609 8.00718 17.8016C8.40602 17.6422 8.76876 17.4066 9.0744 17.1083C9.38005 16.8101 9.62252 16.4552 9.78779 16.0642C9.95306 15.6732 10.0378 15.2538 10.0372 14.8303V6.06513H13.5564H13.552Z" fill="#010B27" />
										<path d="M100.999 6.06513V21.4812C101.033 22.8284 100.628 24.1509 99.8439 25.2559C99.7266 25.4215 99.5979 25.5789 99.4588 25.7272C98.898 26.4017 98.1954 26.9486 97.3992 27.3305C96.3811 27.8022 95.2648 28.033 94.1397 28.0044C92.6361 28.0522 91.159 27.6076 89.94 26.7403C88.7412 25.7159 87.8767 24.3659 87.4551 22.8598H90.6295C90.8886 23.5327 91.3736 24.0983 92.004 24.4631C92.6429 24.8625 93.3874 25.0678 94.1442 25.0533C94.3753 25.059 94.6063 25.0413 94.8337 25.0005C97.0411 24.6525 97.3724 22.3577 97.4037 21.2389V20.6355C96.4245 21.1613 95.3269 21.4369 94.2114 21.4372C93.3196 21.4448 92.4352 21.2776 91.6098 20.9454C90.7844 20.6132 90.0346 20.1226 89.404 19.5023C88.7734 18.8819 88.2747 18.1442 87.937 17.3323C87.5993 16.5203 87.4294 15.6503 87.4371 14.773V6.06513H91.019V14.773C91.009 15.4148 91.1907 16.0454 91.5418 16.5864C91.8928 17.1275 92.3976 17.5553 92.9935 17.8166C93.57 18.053 94.2058 18.1121 94.8171 17.9864C95.4285 17.8606 95.9868 17.5557 96.4187 17.1118C97.0249 16.5076 97.3644 15.6925 97.3634 14.8435V6.06513H100.999Z" fill="#010B27" />
									</g>
								</svg>
							</Link>
							<Link className='m-profile' to={(user && user.email) ? `/${getLocaleLink('dashboard', locale)}` : `/${locale}/register`}>
								{(profileImageUrl && hasUserCookie) ? <LazyLoadImage src={profileImageUrl} /> :
									(user && user.pictureUrl) ? <LazyLoadImage src={user.pictureUrl} alt={`${user.firstName} ${user.lastName}`} />
										: <LazyLoadImage src={navbar == "dark-theme" && !isMobileMenuActive ? "/img/icons/avatar-dark.png" : '/img/icons/avatar.png'} />
								}
							</Link>
							<div className={('header-menu') + (isMobileMenuActive ? " active" : "")} ref={headerMenuRef}>
								<div className='header-nav'>
									<div
										className={("menu-item") + (activeMenuIds.includes(1) ? " active" : "")}
										onMouseEnter={() => !isMobile && setActiveMenuIds([1])}
										onMouseLeave={() => !isMobile && setActiveMenuIds([])}
									>
										{
											isMobile ?
												<span
													onClick={() => handleOpenMenu(1)}
												><FormattedMessage {...accountMenu.search} /></span>
												:
												<Link to={`/${getLocaleLink('destination', locale)}`}
												><FormattedMessage {...accountMenu.search} /></Link>
										}
										<div className={"submenu"}>
											<div className='dropdown-wrapper'>
												<div className='container'>
													{
														countries &&
														<>
															{
																isMobile ?
																	<>
																		<Link to={`/${getLocaleLink('faq', locale)}`} className='see-more'>
																			<FormattedMessage
																				id="nav.seeAll"
																				defaultMessage="See All"
																			/>
																		</Link>
																		<div className='submenu-items'>
																			{countries?.map((country, index) => (
																				country.isActive &&
																				<div className='submenu-item' key={`h-country-${country._id}`}>
																					<Link to={`/${getLocaleLink('destination', locale)}/${country[`slug${getUrlByLocale(locale)}`]}`}>
																						{country[`name${getUrlByLocale(locale)}`]}
																					</Link>
																				</div>
																			))}
																		</div>
																	</>
																	:
																	<Swiper
																		loop={false}
																		watchSlidesVisibility={true}
																		navigation
																		breakpoints={{
																			0: {
																				spaceBetween: 5,
																				slidesPerView: 3,
																			},
																			992: {
																				spaceBetween: 10,
																				slidesPerView: 4,
																			},
																			1200: {
																				spaceBetween: 24,
																				slidesPerView: 4,
																			}
																		}}>
																		{
																			countries?.map((country, index) => (
																				country.isActive &&
																				<SwiperSlide
																					key={`h-country-${country._id}`}>
																					<Link
																						to={`/${getLocaleLink('destination', locale)}/${country[`slug${getUrlByLocale(locale)}`]}`}
																						className="country-card">
																						<div className='img'>
																							<LazyLoadImage src={`/img/countries/${country.slug}.jpg`} />
																						</div>
																						<p className='country-name'>{country[`name${getUrlByLocale(locale)}`]}</p>
																						<div className='explore'>
																							<p><FormattedMessage {...layout.magazineLoadMore} children={msg => <>{msg}</>} /></p> <LazyLoadImage src='/img/icons/arrow-right.svg' aria-hidden={true} />
																						</div>
																					</Link>
																				</SwiperSlide>)
																			)}
																	</Swiper>
															}
														</>
													}
												</div>
											</div>
										</div>
									</div>
									<div className={("menu-item") + (activeMenuIds.includes(2) ? " active" : "")}
										onMouseEnter={() => !isMobile && setActiveMenuIds([2])}
										onMouseLeave={() => !isMobile && setActiveMenuIds([])}>
										<span onClick={() => handleOpenMenu(2)} >
											<FormattedMessage {...accountMenu.categories} />
										</span>
										<div className={"submenu"}>
											<div className='dropdown-wrapper'>
												<div className='container'>
													<div className='icon-items'>
														{categories && categories.filter((category) => category.isActive).map((category, index) =>
															<div className='icon-item' key={`c-${category._id}` + index}>
																<Link to={`/${getLocaleLink('searchCourseCategory', locale)}/${category[`slug${getUrlByLocale(locale)}`]}`}>
																	<div className='icon'>
																		<LazyLoadImage src={`/img/categories/${category.slug}.svg`} />
																	</div>
																	<div className='content'>
																		{
																			category[`name${getUrlByLocale(locale)}`] &&
																			<h4 className='title'>
																				{category[`name${getUrlByLocale(locale)}`]}
																			</h4>
																		}
																		{
																			category[`description${getUrlByLocale(locale)}`] &&
																			<p className='description'>
																				{category[`description${getUrlByLocale(locale)}`]}
																			</p>
																		}
																	</div>
																</Link>
															</div>
														)}
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className={("menu-item") + (activeMenuIds.includes(3) ? " active" : "")}
										onMouseEnter={() => !isMobile && setActiveMenuIds([3])}
										onMouseLeave={() => !isMobile && setActiveMenuIds([])}>
										{
											isMobile ?
												<span
													onClick={() => handleOpenMenu(3)}
												><FormattedMessage {...accountMenu.faq} /></span>
												:
												<Link to={`/${getLocaleLink('faq', locale)}`}
												><FormattedMessage {...accountMenu.faq} /></Link>
										}
										<div className={"submenu"}>
											<div className='dropdown-wrapper'>
												<div className='container'>
													<Link to={`/${getLocaleLink('faq', locale)}`} className='d-none see-more'>
														<FormattedMessage
															id="nav.seeAll"
															defaultMessage="See All"
														/>
													</Link>
													<div className='submenu-items four-rows'>
														{faq && faq.categories?.map((category, index) => (
															<div className='submenu-item' key={`f-${category._id}` + index}>
																<Link to={`/${getLocaleLink('faq', locale)}?c=${category[`_id`]}`}>
																	{category[`title${getUrlByLocale(locale)}`]}
																</Link>
															</div>
														))}
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className={("menu-item") + (activeMenuIds.includes(4) ? " active" : "")}
										onMouseEnter={() => !isMobile && setActiveMenuIds([4])}
										onMouseLeave={() => !isMobile && setActiveMenuIds([])}
									>
										{
											isMobile ?
												<span
													onClick={() => handleOpenMenu(4)}
												><FormattedMessage {...accountMenu.deals} /></span>
												:
												<Link to={`/${getLocaleLink('deals', locale)}`}
												><FormattedMessage {...accountMenu.deals} /></Link>
										}
										<div className={"submenu"}>
											<div className='dropdown-wrapper'>
												<div className='container'>
													<Link to={`/${getLocaleLink('deals', locale)}`} className='d-none see-more'>
														<FormattedMessage
															id="nav.seeAll"
															defaultMessage="See All"
														/>
													</Link>
													<div className='submenu-items four-rows'>
														{countries?.map((country, index) => (
															country.isActive &&
															<div className='submenu-item' key={`h-${country._id}` + index}>
																<Link to={`/${getLocaleLink('deals', locale)}/${country[`slug${getUrlByLocale(locale)}`]}`}>
																	{country[`name${getUrlByLocale(locale)}`]}
																</Link>
															</div>
														))}
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className={("menu-item") + (activeMenuIds.includes(5) ? " active" : "")}
										onMouseEnter={() => !isMobile && setActiveMenuIds([5])}
										onMouseLeave={() => !isMobile && setActiveMenuIds([])}>
										{
											isMobile ?
												<span
													onClick={() => handleOpenMenu(5)}
												><FormattedMessage {...accountMenu.magazine} /></span>
												:
												<Link to={`/${getLocaleLink('magazine', locale)}`}
												><FormattedMessage {...accountMenu.magazine} /></Link>
										}
										<div className={"submenu"}>
											<div className='dropdown-wrapper bg-grey'>
												<div className='container'>
													<Link to={`/${getLocaleLink('magazine', locale)}`} className='d-none see-more'>
														<FormattedMessage
															id="nav.seeAll"
															defaultMessage="See All"
														/>
													</Link>
													<div className='submenu-title m-none'>
														<FormattedMessage
															id="nav.categories"
															defaultMessage="Categories"
														/>
													</div>
													<div className={('submenu-items') + (magazineCategories?.length < 8 ? ' four-rows' : '')}>
														{magazineCategories?.map((category, index) =>
															<div key={`h-${category._id}` + index}>
																<div className='submenu-item' >
																	<Link to={`/${getLocaleLink('magazine', locale)}/category/${category[`slug${getUrlByLocale(locale)}`]}`}>
																		{category[`title${getUrlByLocale(locale)}`]}
																	</Link>
																</div>
															</div>
														)}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className={('currencies dd-parent') + (activeMenuIds.includes(6) ? " active" : "")}>
									<div className='selected' onClick={() => handleOpenMenu(6)}>
										{currentCurrency}
									</div>
									<div className='dropdown'>
										<div className='title'><FormattedMessage
											id="nav.currencies"
											defaultMessage="Currencies"
										/></div>
										<ul>
											{
												currencies?.map((currency, index) => (
													<li key={currency.code + 'currency' + index}
														className={(currency.code == currentCurrency ? "active" : "")}
														onClick={(event) => changeUserCurrency(event, currency)}>
														<span>{currency.code}</span>
													</li>
												))
											}
										</ul>
									</div>
								</div>
								<div className={('lang dd-parent') + (activeMenuIds.includes(7) ? " active" : "")}>
									{
										activeLanguage &&
										<div className='selected' onClick={() => handleOpenMenu(7)}>
											<span>
												<LazyLoadImage src={`/img/icons/flags/${activeLanguage.code}.png`}
													alt={`flag ${activeLanguage.code.toLowerCase()}`}
													title={`put website in ${activeLanguage.name}`}
												/></span>
											<div className='d-none'>
												{activeLanguage[`name${getUrlByLocale(locale)}`]}
											</div>
										</div>
									}
									<div className='dropdown'>
										<p className='title'>
											<FormattedMessage
												id="nav.languages"
												defaultMessage="Website languages" />
										</p>
										<ul>
											{activeLanguages && activeLanguages.map((language, index) => {
												let redirectUrl = `/${language.code.toLowerCase()}`;
												if (settings[`slug${getUrlByLocale(language.code)}`]) {
													redirectUrl = settings[`slug${getUrlByLocale(language.code)}`];
												}
												return (
													<li key={`nlang-${language._id}` + index}
														className={`${locale === language.code.toLowerCase() && 'active'}`}>
														<a href={redirectUrl}>
															<LazyLoadImage src={`/img/icons/flags/${language.code}.png`}
																alt={`flag ${language.code.toLowerCase()}`}
																title={`put website in ${language.name}`}
															/>{language[`name${getUrlByLocale(locale)}`]}
														</a>
													</li>
												)
											})}
										</ul>
									</div>
								</div>
								<a
									href="https://wa.me/message/BGIZNZWHDBKJG1"
									className="btn callback"
									target="_blank" rel="noreferrer">
									<span><FormattedMessage {...headerButtons.callback} /></span>
								</a>
								{(user && user.email) ?
									!isMobile &&
									<div className='dd-parent profile'>
										<div className='selected'>
											<div className='avatar'>
												{
													profileImageUrl ? <LazyLoadImage src={profileImageUrl} /> :
														user.pictureUrl ? <LazyLoadImage src={user.pictureUrl} alt={`${user.firstName} ${user.lastName}`} />
															: <LazyLoadImage src={navbar == "dark-theme" ? '/img/icons/avatar-dark.png' : '/img/icons/avatar.png'} alt={"Profile Picture"} />
												}
											</div>
										</div>
										<div className='dropdown profile'>
											<ul>
												<li key='dashboardLink'>
													<Link to={`/${getLocaleLink('dashboard', locale)}`}><FormattedMessage {...accountMenu.dashboard} /></Link>
												</li>
												<li key='profileLink'>
													<Link to={`/${getLocaleLink('profile', locale)}`}><FormattedMessage {...accountMenu.profile} /></Link>
												</li>
												<li key='bookingLink'>
													<Link to={`/${getLocaleLink('booking', locale)}`}><FormattedMessage {...accountMenu.booking} /></Link>
												</li>
												<li key='wishlistLink'>
													<Link to={`/${getLocaleLink('wishlist', locale)}`}><FormattedMessage {...accountMenu.wishlist} /></Link>
												</li>
												<li key='settingsLink'>
													<Link to={`/${getLocaleLink('settings', locale)}`}><FormattedMessage {...accountMenu.settings} /></Link>
												</li>
												<li key='reviewLink'>
													<Link to={`/${getLocaleLink('review', locale)}`}><FormattedMessage {...accountMenu.review} /></Link>
												</li>
												<li key='logoutLink'>
													<a href="/logout"><FormattedMessage {...accountMenu.logout} /></a>
												</li>
											</ul>
										</div>
									</div>
									:
									<Link className='btn register' to={`/${locale}/register`}>
										<span><FormattedMessage {...headerButtons.signInUp} /></span>
									</Link>
								}
							</div>
						</div>
					</div>
				</header>
			</>
			:
			<></>
	)
}

Header.propTypes = {
	settings: PropTypes.object.isRequired,
};

Header.contextTypes = {
	fetch: PropTypes.func.isRequired,
	languages: PropTypes.array,
	currencies: PropTypes.array,
	countries: PropTypes.array,
	magazineCategories: PropTypes.array,
	faq: PropTypes.object,
	pathname: PropTypes.string,
	query: PropTypes.object,
	locale: PropTypes.string.isRequired,
	getUrlByLocale: PropTypes.func.isRequired,
	currency: PropTypes.string.isRequired,
	getLocaleLink: PropTypes.func.isRequired,
	user: PropTypes.object,
	country: PropTypes.string.isRequired,
};


export default Header;
