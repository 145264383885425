export const categories = [
  {
    _id: '0',
    isActive: false,
    slug: 'english',
    slugFr: 'anglais',
    slugEs: 'ingles',
    contentSeoSlug: 'english-courses',
    title: 'English courses abroad',
    titleFr: "Cours d'anglais à l'étranger",
    titleEs: 'Cursos de ingles en el extranjero',
    name: '',
    nameFr: '',
    description: 'Take English courses abroad with native teachers for fast results. Compare offers with Worddy.',
    descriptionFr: "Prenez des cours d'anglais à l'étranger avec des professeurs natif pour avoir des résultats rapides. Comparer les offres avec Worddy.",
    descriptionEs: 'Toma lecciones de inglés en el extranjero con profesores nativos para obtener resultados rápidos. Compare ofertas con Worddy.',
    seoImage: 'https://worddy.com/img/cloud/uploads/nsts-malta-club-50.jpg',
    query: {
      courses: 'general-english',
      lessons: '20',
      age: 30,
      accommodations: 'no-accommodation',
    },
  },
  {
    _id: '4',
    isActive: true,
    slug: 'general-english-course',
    slugFr: 'cours-anglais-general',
    slugEs: 'cursos-de-ingles-general',
    contentSeoSlug: 'general-english-courses',
    title: '▷ General English Course',
    titleFr: "▷ Cours d'anglais général",
    titleEs: '▷ Curso de inglés general',
    name: 'General english course',
    nameFr: "Cours d'anglais général",
    nameEs: 'Curso de inglés general',
    description: 'General English lessons to improve your oral and written comprehension. The packages include lessons and accommodation.',
    descriptionFr: "Cours d'anglais général pour améliorer votre compréhension orale et écrite. Les packages comprennent cours et hébergement.",
    descriptionEs: 'Clases de inglés general para mejorar tu comprensión oral y escrita. Los paquetes incluyen clases y alojamiento.',
    seoImage: 'https://worddy.com/img/cloud/uploads/general-english-course.webp',
    query: {
      courses: 'general-english'
    },
  },
  {
    _id: '1',
    isActive: true,
    slug: 'business-english',
    slugFr: 'formation-anglais-professionnel',
    slugEs: 'formacion-profesional-ingles',
    contentSeoSlug: 'business-english',
    title: '▷ Business English courses abroad',
    titleFr: "▷ Cours d'anglais professionnel/business à l'étranger",
    titleEs: '▷ Cursos de inglés profesional / comercial en el extranjero',
    name: 'Business English courses',
    nameFr: "Cours d'anglais pour les professionnels",
    nameEs: 'Cursos de inglés para profesionales',
    description: 'Business English lessons to develop your professional vocabulary. Possibility of thematizing the courses according to the sector.',
    descriptionFr: "Cours d'anglais des affaires pour développer votre vocabulaire professionnel. Possibilité de thématiser les cours selon le secteur.",
    descriptionEs: 'Lecciones de inglés de negocios para desarrollar tu vocabulario profesional. Posibilidad de tematizar los cursos según el sector.',
    seoImage: 'https://worddy.com/img/cloud/uploads/business-english-course-malta.jpg',
    query: {
      courses: 'business-english',
      age: 30,
      accommodations: 'no-accommodation',
    },
  },
  {
    _id: '2',
    isActive: true,
    slug: 'intensive-english-courses',
    slugFr: 'cours-anglais-intensif',
    slugEs: 'curso-intensivo-de-ingles',
    contentSeoSlug: 'intensive-english-courses',
    title: '▷ Intensive English courses abroad for adults',
    titleFr: "▷ Cours d'anglais intensif à l'étranger - Séjour linguistique",
    titleEs: '▷ Curso intensivo de inglés en el extranjero - estancia lingüística',
    name: 'Intensive English courses',
    nameFr: "Cours d'anglais intensifs",
    nameEs: 'Cursos intensivos de ingles',
    description: 'Speak fluent English quickly with our intensive group or one-to-one course options.',
    descriptionFr: "Parlez rapidement un Anglais courant grâce à nos options de cours intensifs en groupe on individuels.",
    descriptionEs: 'Habla inglés con fluidez rápidamente con nuestro grupo intensivo o opciones de cursos individuales.',
    seoImage: 'https://worddy.com/img/cloud/uploads/intensive-english.webp',
    query: {
      lessons: '30',
      age: 30,
      accommodations: 'no-accommodation',
    },
  },
  {
    _id: '3',
    isActive: true,
    slug: 'english-courses-teenagers-children-abroad',
    slugFr: 'cours-anglais-adolescent-enfant',
    slugEs: 'curso-ingles-adolescente',
    contentSeoSlug: 'teenagers-english-courses',
    title: '▷ English courses for teenagers and children abroad',
    titleFr: "▷ Cours d'anglais adolescents et enfants à l'étranger",
    titleEs: '▷ Cursos de inglés para adolescentes y niños en el extranjero',
    name: 'English courses for teenagers',
    nameFr: "Cours d'anglais pour les adolescents",
    nameEs: 'Cursos de inglés para adolescentes',
    description: 'English courses for teenagers in total immersion abroad, with cultural and playful activities for a rich and fun learning experience.',
    descriptionFr: "Des cours d'anglais pour adolescents en immersion totale à l'étranger, avec des activités culturelles et ludiques pour une expérience d'apprentissage riche et amusante.",
    descriptionEs: 'Cursos de inglés para adolescentes en inmersión total en el extranjero, con actividades culturales y lúdicas para una rica y divertida experiencia de aprendizaje.',
    seoImage: 'https://worddy.com/img/cloud/uploads/salini-classroom-resort-malta-ese.jpg',
    query: {
      age: 15,
    },
  },
  {
    _id: '5',
    isActive: true,
    slug: 'english-courses-senior',
    slugFr: 'cours-anglais-senior',
    slugEs: 'cursos-de-ingles-adultos-mayores',
    contentSeoSlug: 'english-courses-senior',
    title: '▷ English courses for seniors (over 50 years) abroad',
    titleFr: "▷ Cours d'anglais séniors (50+), retraités à l'étranger",
    titleEs: '▷ Cursos de inglés para adultos mayores  (50+), jubilados en el extranjero',
    name: 'English courses for seniors (50 years old and over)',
    nameFr: "Cours d'anglais pour les séniors 50+",
    nameEs: "Cursos de inglés para personas mayores (50 años en adelante)",
    description: 'Take group language courses for seniors abroad to learn functional language for travel and everyday situations.',
    descriptionFr: 'Suivez des cours de langue en groupe pour apprendre un langage fonctionnel pour les voyages et les situations de la vie quotidienne.',
    descriptionEs: 'Tome lecciones grupales de idiomas para aprender un lenguaje funcional para viajes y situaciones cotidianas.',
    seoImage: 'https://worddy.com/img/cloud/uploads/nsts-malta-club-50.jpg',
    query: {
      courses: 'general-english',
      lessons: '20',
      age: 50,
      accommodations: 'hotel.residence.homestay',
      rooms: 'single-bedroom',
    },
  },
];
export default categories;
