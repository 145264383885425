import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import TextLayout from '../TextLayout/TextLayout';

const messages = defineMessages({
  title: {
    id: 'BannerRegister.title',
    defaultMessage: 'Payment in several instalments with PayPal',
    description: 'title in BannerRegister',
  },
  subTitle: {
    id: 'BannerRegister.subTitle',
    defaultMessage: `Pay in instalments with PayPal! Afford big purchases with flexibility, convenience, and no interest. Easy and secure payments.`,
    description: 'subTitle in BannerRegister',
  },
  button: {
    id: 'BannerRegister.btnMore',
    defaultMessage: 'Get Started',
    description: 'button in BannerRegister',
  },
});
// eslint-disable-next-line no-empty-pattern

const PromoSection = ({ }, { locale, getLocaleLink, user }) => (

	<div className='promoSecWrapper'>
		<div className='promoSecContainer'>
			<div className='promoSecBg' style={{ backgroundImage: `url('/img/home/promo_get_access_Opt.png')` }}>
				<div className='container'>
					<TextLayout
						header={<FormattedMessage {...messages.title} children={msg => <>{msg}</>} />}
						text={<FormattedMessage {...messages.subTitle} children={msg => <>{msg}</>} />}
						buttonText={<FormattedMessage {...messages.button}children={msg => <>{msg}</>} />}
						buttonLink={`/${getLocaleLink('register', locale)}`}
						isWhite
						isRow
					/>
				</div>
				<div className='promoSecTag'>%</div>
			</div>
		</div>
	</div>
);


PromoSection.contextTypes = {
	locale: PropTypes.string,
	getLocaleLink: PropTypes.func.isRequired,
	user: PropTypes.object,
};

export default PromoSection;
