import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import * as Cookies from 'js-cookie';
import { FormattedMessage, defineMessages, IntlProvider } from 'react-intl';
import { successForm, errorsForm } from '../shareTranslation';
import { createSubscriber } from '../../utils/apiCalls';

const messages = defineMessages({
  subscribe: {
    id: 'newsletter.subscribe',
    defaultMessage: 'I subscribe',
  },
  email: {
    id: 'newsletter.email',
    defaultMessage: 'Your Email',
  },
});


const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

class FormNewsletter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorSubscriber: null,
      successSubscriber: null,
    };
  }

  submitEmail = async (subscriber) => {
    this.setState({
      errorSubscriber: null,
      successSubscriber: null,
    });
    await sleep(300);
    const {
      fetch,
      locale,
      currency,
      country,
      intl,
    } = this.context;

    const newSuscriber = {
      email: subscriber.email,
      language: locale,
      currency,
      countryCode: country,
    };
    const newSubsriber = await createSubscriber(fetch, newSuscriber);
    // already exist
    if (newSubsriber && newSubsriber._id) {
      this.setState({
        successSubscriber: intl.formatMessage(successForm.subscriberSuccess),
        errorSubscriber: null,
      });
      await sleep(300);
      Cookies.set('hideNewsletterBox', true, { expires: 90 });
      this.forceUpdate();
    } else if (newSubsriber && newSubsriber.status === 409) {
      this.setState({
        errorSubscriber: intl.formatMessage(errorsForm.subscriberAlreadyExist),
        successSubscriber: null,
      });
    } else {
      this.setState({
        errorSubscriber: intl.formatMessage(errorsForm.serverError),
        successSubscriber: null,
      });
    }
  }

  render() {
    const { intl } = this.context;
    const { errorSubscriber, successSubscriber } = this.state;
    const emailPlaceHolder = intl.formatMessage(messages.email);

    return (
      <>
        <Form
          onSubmit={this.submitEmail}
          render={({ handleSubmit, invalid }) => (
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <Field
                    className=" form-control theme-footer-subscribe-form-control"
                    name="email"
                    component="input"
                    type="email"
                    placeholder={emailPlaceHolder}
                    required
                  />
                </div>
                {successSubscriber &&
                  <div className="message-success">
                    <p>{successSubscriber}</p>
                  </div>}
                {errorSubscriber &&
                  <div className="message-error">
                    <p>{errorSubscriber}</p>
                  </div>}
                <button
                  className="btn btn-primary-invert text-upcase theme-footer-subscribe-btn"
                  type="submit"
                  disabled={invalid}
                >
                  <FormattedMessage {...messages.subscribe} />
                </button>
            </form>
          )}
      />
      </>
    );
  }
}

FormNewsletter.contextTypes = {
  fetch: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  intl: IntlProvider.childContextTypes.intl,
};


export default FormNewsletter;
