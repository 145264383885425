import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Link from '../Link';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
	Pagination
} from 'swiper/core';
import { waitForFinalEvent } from '../../utils/helper';
import { LazyLoadImage } from "react-lazy-load-image-component";


SwiperCore.use([Pagination]);

const messages = defineMessages({
	title: {
		id: 'RecommandedMag.title',
		defaultMessage: 'Discover Worddy Magazine',
		description: 'title in RecommandedMag',
	},
	subTitle: {
		id: 'RecommandedMag.subTitle',
		defaultMessage: 'Find our student testimonials, travel tips, and tips for learning new languages',
		description: 'subTitle in RecommandedMag',
	},
	btnMore: {
		id: 'RecommandedMag.btnMore',
		defaultMessage: 'More Stories',
		description: 'subTitle in RecommandedMag',
	},
});


const MagazineHomeSlider = ({ context, items }) => {
	const ref = useRef(0);
	const [mobile, setMobile] = useState(false);
	const { locale, getLocaleLink, getUrlByLocale } = context;

	function handleResize() {
		waitForFinalEvent(function () {
			setMobile(window.innerWidth <= 768);
		}, 250, "window resize");
	}

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	});

	return (
		<div className="magazineSliderWrapper">
			<div className="container magazineSliderContainer">
				<div className="magazineSliderText">
					<h2><FormattedMessage {...messages.title} children={msg => <>{msg}</>} /></h2>
					<p><FormattedMessage {...messages.subTitle} children={msg => <>{msg}</>} /></p>
				</div>
				<div className="arrowsWrapper">
					<div className="arrowsPrevNext">
						<div className="prev" onClick={() => ref.current.querySelector(".swiper-container").swiper.slidePrev(250)}> <LazyLoadImage src="/img/destination/redArrow.svg" alt="prev arrow" /></div>
						<div className="next" onClick={() => ref.current.querySelector(".swiper-container").swiper.slideNext(250)}> <LazyLoadImage src="/img/destination/redArrow.svg" alt="next arrow" /></div>
					</div>
					<Link to={`/${getLocaleLink('magazine', locale)}`} className="moreStoriesButton">
						<p> <FormattedMessage {...messages.btnMore} /></p>
						 <LazyLoadImage src="/img/destination/redArrow.svg" alt="more stories arrow" />
					</Link>
				</div>
				<div className="elementsWrapper">
					<div className="elementsContainer" ref={ref}>
						{items &&
							<Swiper
								slidesPerView={'auto'}
								loop={false}
								pagination={{
									"clickable": true
								}}
								breakpoints={{
									0: {
										spaceBetween: 16,
									},
									768: {
										spaceBetween: 24,
									},
								}}>
								{items.map((item, index) => {
									var desc = '';
									var title = '';
									if (item[`description${getUrlByLocale(locale)}`]) {
										desc = item[`description${getUrlByLocale(locale)}`].replace(/(<([^>]+)>)/ig, '');
										desc = desc.slice(0, 100);
									}
									if (item[`title${getUrlByLocale(locale)}`]) {
										title = item[`title${getUrlByLocale(locale)}`].replace(/(<([^>]+)>)/ig, '');
									}
									return (
										<SwiperSlide
											key={`swiper-item-${index}`}>
											<div className="magazineElementWrapper">
												<Link to={`/${getLocaleLink('magazine', locale)}`} className="magazineElementContainer">
													<div className="magazineImgWrapper" style={{ 'backgroundImage': `url(${item.largePictureUrl})` }}></div>
													<div className="magazineElementText">
														<h3>{title}</h3>
														<p dangerouslySetInnerHTML={{ __html: desc }} />
													</div>
												</Link>
											</div>
										</SwiperSlide>
									)
								}
								)}
							</Swiper>
						}
					</div>
				</div>
			</div>
		</div>
	)
};


MagazineHomeSlider.propTypes = {
	items: PropTypes.array,
};

MagazineHomeSlider.contextTypes = {
	locale: PropTypes.string,
	getLocaleLink: PropTypes.func.isRequired,
	getUrlByLocale: PropTypes.func.isRequired
};


MagazineHomeSlider.defaultProps = {
	items: [],
}


export default MagazineHomeSlider;
