import React from 'react';
import PropTypes from 'prop-types';
import serialize from 'serialize-javascript';
import HomeSearch from './HomeSearch';
import HomeContent from './HomeContent';
import Destinations from '../../components/Destinations/Destinations';
import DetailsSection from '../../components/DetailsSection/DetailsSection';
import { redDetails } from '../../data/redDetails';
import { blueDetails } from '../../data/blueDetails';
import RegisterSection from '../../components/RegisterSection/RegisterSection';
import PromoSection from '../../components/PromoSection/PromoSection';
import EasyBookingSection from '../../components/EasyBookingSection/EasyBookingSection';
import DealSection from '../../components/DealSection/DealSection';
import PromoImages from '../../components/PromoImages/PromoImages';
import MagazineHomeSlider from '../../components/MagazineHomeSlider/MagazineHomeSlider';
import magazineSliderItems from '../../data/magazineSlider';
import CourseLearnSlider from '../../components/CourseLearnSlider/CourseLearnSlider';
import categories from '../../data/courses';
import HottestDeals from '../../components/HottestDeals/HottestDeals';
import hottestDealsItems from '../../data/hottestDealsItems';

class Home extends React.PureComponent {


	render() {
		const { magazineCategories } = this.props;

		const { locale, getUrlByLocale,
			getLocaleLink,
			displayPriceWithCurrency,
			currency,
			currencies, } = this.context;

		const worddyStructuredData = {
			'@context': 'http://schema.org',
			'@type': 'Organization',
			name: 'Worddy',
			legalName: 'Worddy Booking Solution LTD',
			url: 'https://www.worddy.co',
			logo: 'https://www.worddy.co/assets/997cebaf.png',
			founders: [
				{
					'@type': 'Person',
					name: 'Pierre Cordier',
				},
				{
					'@type': 'Person',
					name: 'Yann Candia',
				},
			],
			sameAs: [
				'https://www.facebook.com/worddy.co/',
				'https://twitter.com/worddyco',
				'https://www.linkedin.com/company/worddy/',
			],
			contactPoint: [
				{
					'@type': 'ContactPoint',
					telephone: '+33632399557',
					contactType: 'customer service',
					contactOption: 'TollFree',
					areaServed: 'FR',
				},
			],
			address: {
				'@type': 'PostalAddress',
				addressLocality: 'Sliema',
				addressRegion: '',
				streetAddress: '31 Sir Adrian Dingli Street',
				postalCode: 'SLM 1903',
				addressCountry: 'Malta',
			},
		};

		return (
			<>
				<HomeSearch />
				{/* We hide here . hide by Emircan */}
				<PromoImages context={this.context} />

				{/* {
					hottestDealsItems && hottestDealsItems.length > 2 &&
					<HottestDeals items={hottestDealsItems.slice(0, 8)} loadMore={hottestDealsItems.length > 8} context={this.context} />
				} */}

				<EasyBookingSection locale={locale} />
				<Destinations context={this.context} isHome={true} />
				<PromoSection />

				<CourseLearnSlider context={this.context} items={categories} />

				<MagazineHomeSlider
					items={magazineCategories}
					context={this.context}
				/>


				<DealSection />

				<DetailsSection
					isBlue={false}
					locale={locale}
				/>
				<div className="container">
					<hr className="grey-line" />
				</div>
				<DetailsSection
					isBlue
					locale={locale}
				/>


				{/* <HomeContent magazineCategories={magazineCategories} /> */}

				{/* <RegisterSection /> */}
				<script
					type="application/ld+json"
					// eslint-disable-next-line react/no-danger
					dangerouslySetInnerHTML={{
						__html: `${serialize(worddyStructuredData)}`,
					}}
				/>

			</>
		);
	}
}

Home.propTypes = {
	magazineCategories: PropTypes.array,
};

Home.contextTypes = {
	locale: PropTypes.string.isRequired,
	getLocaleLink: PropTypes.func.isRequired,
	getUrlByLocale: PropTypes.func.isRequired,
	countries: PropTypes.array.isRequired,
	displayPriceWithCurrency: PropTypes.func.isRequired,
	currencies: PropTypes.array.isRequired,
	currency: PropTypes.string.isRequired,
};

Home.defaultProps = {
	magazineCategories: [],
};

export default Home;
