import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import DisplayVideo from '../generic/DisplayVideo';

const messages = defineMessages({
  aboutText: {
    id: 'BannerVideo.text',
    defaultMessage: `Learning a language while traveling is an enriching experience in addition to being formative for both professional and personal purposes.
    With Worddy, compare many language travel offers easily with our powerful search algorithm.
    Booking your language school and accommodation has never been easier or more transparent.`,
    description: '',
  },
  subText: {
    id: 'BannerVideo.subText',
    defaultMessage: 'Alice guides you in the process, watch the video',
    description: '',
  },
});

const HomeVideo = memo(({ locale }) => {
  let videoUrl = 'https://www.youtube.com/watch?v=VVMNGM104k0';
  if (locale === 'fr') {
    videoUrl = 'https://www.youtube.com/watch?v=0-SY33jqjYA';
  }

  return (
  <div className="theme-page-section theme-page-section-xxl">
    <div className="container">
      <div className="row">
        <div className="col-md-5">
          <div className="theme-hero-text theme-hero-text-center">
            <div className="theme-page-section-header">
              <br/>
              <p className="theme-hero-text-subtitle _ta-l">
                <FormattedMessage {...messages.aboutText} />
              </p>
              <br/>
              <i className=" _ta-l">“ <FormattedMessage {...messages.subText} />”</i>
            </div>
          </div>
        </div>
        <div className="col-md-7">
          <DisplayVideo url={videoUrl} controls={false} />
        </div>
      </div>
    </div>
  </div>
  );
});

HomeVideo.propTypes = {
  locale: PropTypes.string.isRequired,
};

export default HomeVideo;
