import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { headerPromo } from '../shareTranslation';
import { useCountdown } from '../../utils/useCountdown';

const HeaderPromoTimer = ({ setPromotionActive, promoTimerData }) => {

  const deadline = new Date(promoTimerData.endDateBookingReduction);


  const [days, hours, minutes, seconds] = useCountdown(deadline);


  //zaman farkina bakip promosyonun zamani dolmussa gostermemek icin
  useEffect(() => {
    const now = new Date();
    const timeDiff = now.getTimezoneOffset();
    const utcNow = now.getTime() + (timeDiff * 60 * 1000);


    if (deadline.getTime() <= utcNow || deadline.getTime() === NaN) {
      setPromotionActive(false);
    }
  });


  return (
    <div className='timer'>
      {(days + hours + minutes + seconds > 0) &&
        <>
          <p className="days text">
            {days} <FormattedMessage {...headerPromo.day} />
          </p>
          <p className="hours text">
            {hours < 10 ? "0" + hours : hours}:{minutes < 10 ? "0" + minutes : minutes}:{seconds < 10 ? "0" + seconds : seconds}
          </p>
        </>
      }
    </div>
  );


};

export default HeaderPromoTimer; 