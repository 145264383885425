import React, { memo } from 'react';
import PropTypes from 'prop-types';

const BannerContentSEO = memo(({ locale }) => {
  if (locale === 'fr') {
    return (
      <div className="theme-page-section theme-page-section-sm">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
            <h2>En quoi consiste un séjour linguistique ?</h2>
            <p className="theme-hero-text-subtitle theme-hero-text-subtitle-sm _ta-j">
            Un <a href="/fr/sejour-linguistique">séjour linguistique</a> consiste à suivre un cours de langue à l'étranger dans un univers prospère à l'apprentissage. Le but est d'améliorer rapidement vos compétences dans la langue étrangère choisie tout en faisant connaissance avec d'autres cultures. C'est une expérience unique à l'étranger dont vous allez vous souvenir toute votre vie.
            </p>
            <h3>Faire des rencontres</h3>
            <p className="theme-hero-text-subtitle theme-hero-text-subtitle-sm _ta-j">
            Un séjour linguistique est un excellent moyen de se faire des amis étrangers, en effet vous serez en immersion totale avec d'autres étudiants qui ne parlent pas votre langue. Vous pourrez échanger avec eux pendant les cours d'anglais, pendant les activités proposées l'après-midi ou le week-end, mais aussi dans le logement si vous la partagez.
            </p>
            <h3>Apprentissage modulable à vos besoins</h3>
            <p className="theme-hero-text-subtitle theme-hero-text-subtitle-sm _ta-j">
            À la différence d'un cursus universitaire  vous pouvez commencer les cours à la date que vous souhaitez et en fonction de vos événements de vie. Les cours sont du lundi au vendredi et commencent chaque semaine. Un test en ligne vous sera demandé avant votre arrivée pour connaître votre niveau dans la langue. Vous pouvez aussi faire des cours business en rapport avec une profession ou des cours de préparation à un examen.
            </p>
            <h3>Résultats rapides</h3>
            <p className="theme-hero-text-subtitle theme-hero-text-subtitle-sm _ta-j">
            Ils existent de nombreux moyens d'apprendre une langue comme utiliser des applications mobiles ou faire un stage à l'étranger. Mais le séjour linguistique est de loin le moyen le plus rapide, c'est pour ça que cela existe depuis plus de 40 ans.
            </p>
            <h3>Une équipe pour vous conseiller</h3>
            <p className="theme-hero-text-subtitle theme-hero-text-subtitle-sm _ta-j">
            L'équipe de Worddy est basée à Malte, qui est historiquement la première destination que nous proposions. Notre équipe internationale a donc développé une expertise au fur et à mesure des années.
            Nous publions aussi des conseils et témoignage sur <a href="/fr/magazine">notre magazine en ligne</a> sur nos différentes destinations. Nos agents sont disponibles sur le chat du site en bas à droite ou par rendez-vous téléphonique.
            </p>
            <h3>Promotions sur les séjours linguistiques</h3>
            <p className="theme-hero-text-subtitle theme-hero-text-subtitle-sm _ta-j">
            Les prix des cours de langues et logement sont définis à l'avance sur les brochures de nos partenaires. Les brochures sont disponibles sur chacune de nos pages écoles.
            Il se peut que les écoles fassent des promotions pour les personnes qui réservent longtemps à l'avance ou alors en dernière minute.
            Nous avons créé une page afin de rassembler les <a href="/fr/sejour-linguistique-promotion">promotions sur les séjours linguistiques</a>.
            </p>
            <h3>Trouver un séjours linguistiques pas cher</h3>
            <p className="theme-hero-text-subtitle theme-hero-text-subtitle-sm _ta-j">
              Worddy est un comparateur qui permet de trouver un voyage accordé à votre budget. Si vous cherchez quelques choses de pas cher avec des bons services, il va falloir opter pour des destinations ou des périodes moins demandés. Plus votre voyage linguistique sera long et moins le prix à la semaine sera élevé.
            </p>
            </div>
            <div className="col-md-6">
            <h2>Les avantages du séjour linguistique avec Worddy</h2>
            <p className="theme-hero-text-subtitle theme-hero-text-subtitle-sm _ta-j">
            Worddy est un comparateur qui met en relation des écoles de langues indépendantes présentes dans 12 pays à travers le monde avec des étudiants de 12 à 99 ans qui veulent apprendre une langue.
            </p>
            <h3>Transparence des prix</h3>
            <p className="theme-hero-text-subtitle theme-hero-text-subtitle-sm _ta-j">
            Worddy est le seul site a proposé grâce à son algorithme les prix des séjours linguistiques en temps réel en fonction de dizaine de paramètres. Vous pouvez alors façonner votre package en fonction de la durée, de la date de départ, du type de cours et de logement. Vous pouvez aussi inclure des options comme le transfert aéroport et une assurance santé.
            Il existe d'autres organismes de séjour linguistique mais nous sommes les seuls à afficher les prix sans devoir demander de devis. Contrairement aux agences nous ne facturons pas de frais en plus puisque la relation et le paiement se fait directement avec l'école de langue.
            </p>
            <h3>Large choix de cours de langue</h3>
            <p className="theme-hero-text-subtitle theme-hero-text-subtitle-sm _ta-j">
            Worddy propose un large choix de <a href="/fr/cours-de-langue">cours de langue</a> allant de 10 cours par semaine à 40 cours par semaine. Vous pouvez choisir entre cours en groupe et cours particulier ou bien mixer les deux. Les cours en groupe permettent de pratiquer la langue étrangère avec des non-francophones. Ils sont donc indispensables pour revoir les bases de la langue étrangère.
            </p>
            <h3>Préparation au TOEFL OU IELTS</h3>
            <p className="theme-hero-text-subtitle theme-hero-text-subtitle-sm _ta-j">
            Si vous avez besoin de passer ces examens, un séjour linguistique est très bon moyen le préparer avec 20 ou 30 leçons par semaine.
            </p>
            <h3>Large choix de logement</h3>
            <p className="theme-hero-text-subtitle theme-hero-text-subtitle-sm _ta-j">
            Worddy propose sur son site environ 60 types de logements différents pour tous les budgets. En fonction de vos envies et de vos besoins vous pouvez choisir un logement allant de l'appartement partagé, la résidence étudiante ou l'hébergement en familles d'accueil.
            </p>
            <h3>Des partenaires certifiés</h3>
            <p className="theme-hero-text-subtitle theme-hero-text-subtitle-sm _ta-j">
            Worddy sélectionne en fonction des certifications et des avis des anciens étudiants les meilleures écoles de langue. À chaque fin de séjour, l'étudiant reçoit un questionnaire qui permet de vérifier la qualité des cours et des logements.
            </p>
            <h3>Prix d'un séjour linguistique</h3>
            <p className="theme-hero-text-subtitle theme-hero-text-subtitle-sm _ta-j">
              Le coût d'un séjour n'est pas négligeable, les prix varient de 300 euros à plus de 1000 euros la semaine. Il faut le voir comme un investissement sur votre avenir. Il faut compter le coût du voyage, de l'hébergement, des cours de langues, mais aussi des fraix annexes comme les transports sur place, les repas, les sorties... Le but est de passer un bon moment, il est donc important de se renseigner sur le coût de la vie dans la ville ou vous vous rendez afin d'être à l'aise par rapport à votre budget.
            </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (locale === 'es') {
    return (
      <div className="theme-page-section theme-page-section-sm">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
            <h2>¿Qué es una estancia lingüística?</h2>
            <p className="theme-hero-text-subtitle theme-hero-text-subtitle-sm _ta-j">
              Una <a href="/es/estancias-linguisticas">estancia lingüística</a> consiste en realizar un curso de idiomas en el extranjero en un mundo en el que el aprendizaje florece. El objetivo es mejorar rápidamente el dominio de la lengua extranjera elegida y conocer otras culturas. Es una experiencia única en el extranjero que recordará el resto de su vida.
            </p>
            <h3>Conocer gente nueva</h3>
            <p className="theme-hero-text-subtitle theme-hero-text-subtitle-sm _ta-j">
              Una estancia lingüística es una excelente manera de hacer amigos extranjeros, ya que estarás en total inmersión con otros estudiantes que no hablan tu idioma. Podrás hablar con ellos durante las clases de inglés, durante las actividades que se ofrecen por la tarde o el fin de semana, pero también en el alojamiento si lo compartes.            </p>
            <h3>Aprendizaje que se adapta a sus necesidades</h3>
            <p className="theme-hero-text-subtitle theme-hero-text-subtitle-sm _ta-j">
              A diferencia de un curso universitario, puedes empezar el curso en el momento que desees y según tus acontecimientos vitales. Las clases son de lunes a viernes y comienzan cada semana. Antes de llegar, se le pedirá que realice una prueba en línea para conocer su nivel de idioma. También puedes hacer cursos de negocios relacionados con una profesión o cursos de preparación de exámenes.
            </p>
            <h3>Resultados rápidos</h3>
            <p className="theme-hero-text-subtitle theme-hero-text-subtitle-sm _ta-j">
            Hay muchas formas de aprender un idioma, como el uso de aplicaciones móviles o la realización de prácticas en el extranjero. Pero la estancia lingüística es, con mucho, la forma más rápida, por eso existe desde hace más de 40 años.
            </p>
            <h3>Un equipo para asesorarle</h3>
            <p className="theme-hero-text-subtitle theme-hero-text-subtitle-sm _ta-j">
              El equipo de Worddy tiene su sede en Malta, que fue históricamente el primer destino que ofrecimos. Por ello, nuestro equipo internacional ha desarrollado una experiencia a lo largo de los años. También publicamos en nuestra revista online consejos y testimonios sobre nuestros diferentes destinos. Nuestros agentes están disponibles en la sala de chat de la parte inferior derecha del sitio web o mediante cita telefónica.
            </p>
            <h3>Promociones en cursos de idiomas</h3>
            <p className="theme-hero-text-subtitle theme-hero-text-subtitle-sm _ta-j">
            Los precios de los cursos de idiomas y del alojamiento están definidos de antemano en los folletos de nuestros socios. Los folletos están disponibles en cada una de las páginas de nuestras escuelas. Las escuelas pueden tener ofertas especiales para las personas que reservan con mucha antelación o en el último momento. Hemos creado una página para recoger todas las <a href="/es/promocion-estancia-idioma">promociones sobre estancias lingüísticas</a>.
           </p>
            <h3>Encuentre un curso de idiomas barato</h3>
            <p className="theme-hero-text-subtitle theme-hero-text-subtitle-sm _ta-j">
              Worddy es una herramienta de comparación que le permite encontrar un viaje que se ajuste a su presupuesto. Si busca algo barato con buenos servicios, tendrá que optar por destinos o periodos menos populares. Cuanto más largo sea su viaje lingüístico, más barato será el precio por semana.
            </p>
            </div>
            <div className="col-md-6">
            <h2>Las ventajas de viajar por idiomas con Worddy</h2>
            <p className="theme-hero-text-subtitle theme-hero-text-subtitle-sm _ta-j">
              Worddy es un servicio de comparación que pone en contacto a escuelas de idiomas independientes de 12 países de todo el mundo con estudiantes de 12 a 99 años que quieren aprender un idioma.
            </p>
            <h3>Transparencia de precios</h3>
            <p className="theme-hero-text-subtitle theme-hero-text-subtitle-sm _ta-j">
              Worddy es el único sitio web que ofrece precios en tiempo real para las escuelas de idiomas en función de diez parámetros diferentes. Así podrá adaptar su paquete en función de la duración, la fecha de salida, el tipo de curso y el alojamiento. También puede incluir opciones como el traslado al aeropuerto y el seguro médico. Hay otras agencias de viajes lingüísticos, pero somos la única que muestra los precios sin tener que pedir un presupuesto. A diferencia de las agencias, no cobramos tasas adicionales ya que la relación y el pago se realizan directamente con la escuela de idiomas.
            </p>
            <h3>Amplia oferta de cursos de idiomas</h3>
            <p className="theme-hero-text-subtitle theme-hero-text-subtitle-sm _ta-j">
              Worddy ofrece una amplia gama de <a href="/es/curso-de-idioma">cursos de idiomas</a>, desde 10 lecciones semanales hasta 40 lecciones semanales. Puede elegir entre clases en grupo o privadas o una combinación de ambas. Los cursos en grupo permiten practicar el idioma con personas que no hablan francés. Por ello, son imprescindibles para repasar los fundamentos de la lengua extranjera.
            </p>
            <h3>Preparación para el TOEFL o el IELTS</h3>
            <p className="theme-hero-text-subtitle theme-hero-text-subtitle-sm _ta-j">
            Si tienes que presentarte a estos exámenes, una estancia lingüística es una buena forma de prepararlos con 20 o 30 clases semanales.
            </p>
            <h3>Amplia gama de alojamientos</h3>
            <p className="theme-hero-text-subtitle theme-hero-text-subtitle-sm _ta-j">
            Worddy ofrece en su página web unos 60 tipos de alojamiento diferentes que se adaptan a todos los presupuestos. En función de sus necesidades, puede elegir entre apartamentos compartidos, residencias de estudiantes o familias de acogida.
            </p>
            <h3>Socios certificados</h3>
            <p className="theme-hero-text-subtitle theme-hero-text-subtitle-sm _ta-j">
            Worddy selecciona las mejores escuelas de idiomas según las certificaciones y opiniones de antiguos alumnos. Al final de cada estancia, el estudiante recibe un cuestionario que le permite comprobar la calidad de los cursos y del alojamiento.
            </p>
            <h3>Precio de una estancia lingüística</h3>
            <p className="theme-hero-text-subtitle theme-hero-text-subtitle-sm _ta-j">
              El coste de la estancia no es despreciable, los precios varían desde 300 euros hasta más de 1000 euros por semana. Debe considerarse como una inversión en su futuro. Hay que tener en cuenta el coste del viaje, el alojamiento, los cursos de idiomas, pero también otros gastos como el transporte, las comidas, las salidas... El objetivo es pasarlo bien, por lo que es importante informarse sobre el coste de la vida en la ciudad a la que se va a ir para estar cómodo con el presupuesto.
            </p>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="theme-page-section theme-page-section-sm">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
          <h2 className="_ta-c">What s a language stay?</h2>
          <p className="theme-hero-text-subtitle theme-hero-text-subtitle-sm _ta-j">
            A <a href="/en/language-stay">language stay</a> consists in taking a language course abroad in a dedicated learning environment, usually a language school. The goal is to quickly improve your skills in the chosen foreign language while getting to know other cultures and interacting with people in the chosen language.
          </p>
          <h3>Meet new people</h3>
          <p className="theme-hero-text-subtitle theme-hero-text-subtitle-sm _ta-j">
            A language trip is an excellent way to make new friends from all over the world, as you will be in total immersion with other students who do not speak your language. You will be able to exchange, interact with them during the English classes, during the activities offered in the afternoons or on weekends, but also in the accommodation if you live in a shared flat or in a host family.
          </p>
          <h3>Modular learning to your needs</h3>
          <p className="theme-hero-text-subtitle theme-hero-text-subtitle-sm _ta-j">
            Unlike a university course, you can decide when you want to start the courses. The lessons are scheduled from Monday to Friday and start every week. An online test will be required before your arrival to find out what your current level in the language you would like to learn or improve. You can also do business courses related to a profession or exam preparation courses. Language schools always offer different type of courses to fit your needs.
          </p>
          <h3>Get Fast Results</h3>
          <p className="theme-hero-text-subtitle theme-hero-text-subtitle-sm _ta-j">
            There are many ways to learn a language such as using mobile applications or doing an internship abroad. However, a language stay is by far the quickest and most effective way to reach your goal, that's why it has existed for over 40 years.
          </p>
          <h3>A team to advise you</h3>
          <p className="theme-hero-text-subtitle theme-hero-text-subtitle-sm _ta-j">
            Worddy's team is based in Malta, which is historically the first destination we offered.
          Our international team has therefore developed  a high level of expertise over the years and is able to guide to make sure you make the right choice.
          We also publish articles, tips, advice and testimonials on <a href="/en/magazine">our online magazine</a> and this on the different destinations we offer.
          </p>
          <h3>Promotions on language stays</h3>
          <p className="theme-hero-text-subtitle theme-hero-text-subtitle-sm _ta-j">
            The prices of the language courses and accommodation are defined in advance on the brochures of our partners. The brochures are available on each school page.
            It is possible that the schools make promotions for people who book long in advance or at the last minute.
            We have created a page to collect information about <a href="/en/language-stay-deal">special offers on language stay</a>.
          </p>
          <h3>Find a cheap language course</h3>
          <p className="theme-hero-text-subtitle theme-hero-text-subtitle-sm _ta-j">
            Worddy is a comparator that allows you to find a language trip that fits your budget. If you're looking for something cheap with good service, you'll have to go for less popular destinations or dates.
          </p>
          </div>
          <div className="col-md-6">
          <h2 className="_ta-c">The advantages with Worddy</h2>
          <p className="theme-hero-text-subtitle theme-hero-text-subtitle-sm _ta-j">
            Worddy is a comparator that connects independent language schools in 12 countries around the world with students between the ages of 12 and 99 who want to learn a language, improve their level, take exams or seek a professional career abroad.
          </p>
          <h3>Price transparency</h3>
          <p className="theme-hero-text-subtitle theme-hero-text-subtitle-sm _ta-j">
            Worddy is the only website to use its own algorithm to offer language travel prices in real time according to ten parameters. You can then customize your package according to the desired: duration, departure date, type of course and accommodation. You can also include options such as airport transfer and health insurance.
          </p>
          <h3>Wide choice of language courses</h3>
          <p className="theme-hero-text-subtitle theme-hero-text-subtitle-sm _ta-j">
            Worddy offers a wide choice of <a href="/en/language-course">language courses</a> ranging from 10 lessons per week to 40 lessons per week. You can choose between group and private lessons or even mix the two options. Group lessons allow you to practice the foreign language with foreign speakers. They are therefore essential to review the basics of the foreign language.
          </p>
          <h3>TOEFL OR IELTS exam preparation</h3>
          <p className="theme-hero-text-subtitle theme-hero-text-subtitle-sm _ta-j">
            If you need to take these exams, a language course is a good way to prepare for it with 20 or 30 lessons per week and guarantee that you will successfully pass.
          </p>
          <h3>Wide choice of accommodation</h3>
          <p className="theme-hero-text-subtitle theme-hero-text-subtitle-sm _ta-j">
            Worddy offers on its website around 60 different types of accommodation for all budgets. Depending on your preferences and needs, you can choose an accommodation from a shared apartment, a student residence or a host family accommodation.
          </p>
          <h3>Certified partners</h3>
          <p className="theme-hero-text-subtitle theme-hero-text-subtitle-sm _ta-j">
            Worddy selects the best language schools based on the certifications, reviews of former students but also the location of the schools and the school's facilities. At the end of their stay, the students receive a questionnaire which allows them to give feedback on the quality of the courses and accommodation they received. This allows Worddy to update the list of our partners and always make sure to guarantee a high level of quality.
          </p>
          <h3>Price of a language trip</h3>
          <p className="theme-hero-text-subtitle theme-hero-text-subtitle-sm _ta-j">
            The cost of a language stay is not negligible, prices vary from 300 euros to more than 1000 euros per week. It should be seen as an investment in your future. You have to take into account the cost of travel, accommodation, language courses, but also additional costs such as transportation, meals, outings... The goal is to have a good time, so it is important to find out about the cost of living in the city you are going to in order to be comfortable with your budget.
          </p>
          </div>
        </div>
      </div>
    </div>
  );
});

BannerContentSEO.propTypes = {
  locale: PropTypes.string.isRequired,
};

export default BannerContentSEO;
